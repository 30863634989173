import React, { useState } from "react";
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../global-components/navbar-v4';
import howtobanner from "../../images/banners/howtobanner.webp"
import howtomobile from "../../images/banners/howtomobile.webp"

import Multistep from "../Multistep";



const Howtoorder = () => {
const [currentStep, setCurrentStep] = useState(1);
const [isRadioButtonSelected, setIsRadioButtonSelected] = useState(false);

const handleNext = () => {
setCurrentStep((prevStep) => prevStep + 1);
};
const handlePrevious = () => {
setCurrentStep((prevStep) => prevStep - 1);
};
const calculateProgress = () => {
return (currentStep / totalSteps) * 100;
};

const totalSteps = 7; // Replace with the total number of steps
return (
<>

<NavbarV4 CustomClass="ltn__header-transparent gradient-color-2"  />
<Helmet>
   <title>How To Order - Bcurri Apparel Limited</title>
   <meta name="description" content="" />
</Helmet>

<picture>
   <source srcset={howtomobile} media="(max-width: 660px)" />
   <source srcset={howtobanner} />
   <img src={howtobanner} alt="" className='ban' />
</picture>
<div>


   <Multistep />
{/* <form action="#">
               <div class="page slide-page">
                  <div class="title">Select Design:</div>
                  <div className="form-card">
                     <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
                        <div className='radiogroup'>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="footbal" name="sport" value="footbal"  required onChange={() => setIsRadioButtonSelected(true)} />
                              <img src={football2} className="football2" alt="" />
                              </span> 
                              <p class="align">Footbal</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="cricket" name="sport" value="cricket" required onChange={() => setIsRadioButtonSelected(true)} />
                              <img src={cricket} className="football2" alt="" />
                              </span> 
                              <p class="align">Cricket</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="netball" name="sport" value="netball" required onChange={() => setIsRadioButtonSelected(true)} />
                              <img src={netball} className="football2" alt="" />
                              </span> 
                              <p class="align">Netball</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="hockey" name="sport" value="hockey" required onChange={() => setIsRadioButtonSelected(true)} />
                              <img src={hockey} className="football2" alt="" />
                              </span> 
                              <p class="align">Hockey</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="rubgy" name="sport" value="rubgy" required onChange={() => setIsRadioButtonSelected(true)} />
                              <img src={rubgy} className="football2" alt="" />
                              </span> 
                              <p class="align">Rugby</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="netball" name="sport" value="netball" required onChange={() => setIsRadioButtonSelected(true)} />
                              <img src={netball} className="football2" alt="" />
                              </span> 
                              <p class="align">Netball</p>
                              <br />
                           </label>
                        </div>
                     </div>
                  </div>
                  <div class="field">
                  <button className="next" onClick={handleNext} disabled={!isRadioButtonSelected}> Next </button>
                  </div>
               </div>
               <div class="page">
                  <div class="title">Select Color:</div>
                  <div className="form-card">
                     <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
                        <div className='radiogroup'>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={black} className="black" alt="" />
                              </span> 
                              <p class="align">Black</p>
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black" />
                              <img src={black} className="black" alt="" />
                              </span> 
                              <p class="align">White</p>
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black" />
                              <img src={black} className="black" alt="" />
                              </span> 
                              <p class="align">Royal Blue</p>
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black" />
                              <img src={black} className="black" alt="" />
                              </span> 
                              <p class="align">Sky Blue</p>
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black" />
                              <img src={black} className="black" alt="" />
                              </span> 
                              <p class="align">Navy</p>
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black" />
                              <img src={black} className="black" alt="" />
                              </span> 
                              <p class="align">Red</p>
                              <br />
                           </label>
                        </div>
                     </div>
                  </div>
                  <div class="field btns">
                     <button class="prev-1 prev">Previous</button>
                     <button class="next-1 next">Next</button>
                  </div>
               </div>
               <div class="page">
                  <div class="title">Select Collar:</div>
                  <div className="form-card">
                     <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
                        <div className='radiogroup'>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="footbal" name="collar" value="footbal"  />
                              <img src={football2} className="football2" alt="" />
                              </span> 
                              <p class="align">Footbal Collar</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="cricket" name="collar" value="cricket" />
                              <img src={cricket} className="football2" alt="" />
                              </span> 
                              <p class="align">Cricket Collar</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="netball" name="collar" value="netball" />
                              <img src={netball} className="football2" alt="" />
                              </span> 
                              <p class="align">Netball Collar</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="hockey" name="collar" value="hockey" />
                              <img src={hockey} className="football2" alt="" />
                              </span> 
                              <p class="align">Hockey Collar</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="rubgy" name="collar" value="rubgy" />
                              <img src={rubgy} className="football2" alt="" />
                              </span> 
                              <p class="align">Rugby Collar</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="netball" name="collar" value="netball" />
                              <img src={netball} className="football2" alt="" />
                              </span> 
                              <p class="align">Netball Collar</p>
                              <br />
                           </label>
                        </div>
                     </div>
                  </div>
                  <div class="field btns">
                     <button class="prev-2 prev">Previous</button>
                     <button class="next-2 next">Next</button>
                  </div>
               </div>
               <div class="page">
                  <div class="title">Select Sleeves:</div>
                  <div className="form-card">
                     <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
                           <div className='radiogroup'>
                              <label class="radio-inline">
                                 <span>
                                 <input  type="radio" id="footbal" name="sleeves" value="footbal"  />
                                 <img src={football2} className="football2" alt="" />
                                 </span> 
                                 <p class="align">Short Sleeves</p>
                                 <br />
                              </label>
                              <label class="radio-inline">
                                 <span>
                                 <input  type="radio" id="cricket" name="sleeves" value="cricket" />
                                 <img src={cricket} className="football2" alt="" />
                                 </span> 
                                 <p class="align">Long Sleeves</p>
                                 <br />
                              </label>
                              <label class="radio-inline">
                                 <span>
                                 <input  type="radio" id="netball" name="sleeves" value="netball" />
                                 <img src={netball} className="football2" alt="" />
                                 </span> 
                                 <p class="align">Capped Sleeves</p>
                                 <br />
                              </label>
                           </div>
                     </div>
                  </div>
                  <div class="field btns">
                     <button class="prev-3 prev">Previous</button>
                     <button class="next-3 next">Next</button>
                  </div>
               </div>
               <div class="page">
                  <div class="title">Select Fit:</div>
                  <div className="form-card">
                     <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
                        <div className='radiogroup'>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="footbal" name="fitting" value="footbal"  />
                              <img src={football2} className="football2" alt="" />
                              </span> 
                              <p class="align">Ladies Fit</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="cricket" name="fitting" value="cricket" />
                              <img src={cricket} className="football2" alt="" />
                              </span> 
                              <p class="align">Gents Fit</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="netball" name="fitting" value="netball" />
                              <img src={netball} className="football2" alt="" />
                              </span> 
                              <p class="align">Tight Fit</p>
                              <br />
                           </label>
                        </div>
                     </div>
                  </div>
                  <div class="field btns">
                     <button class="prev-4 prev">Previous</button>
                     <button class="next-4 next">Next</button>
                  </div>
               </div>
               <div class="page">
                  <div class="title">Select Size:</div>
                  <div className="form-card">
                     <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
                        <div className='radiogroup'>
                           <label class="radio-inline">
                              <span>
                              <input type="radio" id="footbal" name="size " value="footbal"  />
                              <img src={football2} className="football2" alt="" />
                              </span> 
                              <p class="align">XS</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="cricket" name="size" value="cricket" />
                              <img src={cricket} className="football2" alt="" />
                              </span> 
                              <p class="align">S</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="netball" name="size" value="netball" />
                              <img src={netball} className="football2" alt="" />
                              </span> 
                              <p class="align">M</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="netball" name="size" value="netball" />
                              <img src={netball} className="football2" alt="" />
                              </span> 
                              <p class="align">L</p>
                              <br />
                           </label>
                        </div>
                     </div>
                  </div>
                  <div class="field btns">
                     <button class="prev-4 prev">Previous</button>
                     <button class="next-4 next">Next</button>
                  </div>
               </div>
               <div class="page">
                  <div class="title">Email Us:</div>
                  <div className="form-card">
                     <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                           <input type="email" name="email" placeholder="Your Email" className="reg-form" required />
                        </div>
                     </div>
                  </div>
                  <div class="field btns">
                     <button class="prev-5 prev">Previous</button>
                     <button class="submit">Submit</button>
                  </div>
               </div>
            </form> */}
</div>
<Footer_v1 />
</>
)
}
export default Howtoorder