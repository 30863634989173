import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../../global-components/navbar-v4';
import Footer_v1 from '../../global-components/footer';
import Formmodal from '../../section-components/formmodal';
import menshorts22front from '../../../images/sports/products/mens-hockey-shorts/22/menshorts22front.png';
import menshorts22back from '../../../images/sports/products/mens-hockey-shorts/22/menshorts22back.png';
import Pocketshortsform from '../../section-components/Forms/pocketshortsform';



const MensHockeyshorts22 = () => {
  const smallImages = [menshorts22front, menshorts22back, menshorts22front, menshorts22back];

  const [productImage, setProductImage] = useState(smallImages[0]);

  const handleSmallImageClick = (src) => {
    setProductImage(src);
  };

  return (
    <>
      <NavbarV4 />

      <Helmet>
        <title>Men's Hockey Shorts</title>
        <meta name="description" content="" />
      </Helmet>

      <section className='bnnr-product-pg'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
              <h2>Men's Hockey Shorts</h2>
            </div>
          </div>
        </div>
      </section>

      <section className='rugby-first-p1'>
        <div className='small-container single-product'>
          <div className='row'>
            <div className='col-2 text-center'>
              <img src={productImage} alt='' width='100%' id='ProductImg' />

              <div className='small-img-row'>
                {smallImages.map((image, index) => (
                  <div key={index} className='small-img-col'>
                    <img
                      src={image}
                      alt=''
                      width='100%'
                      className='small-img'
                      onClick={() => handleSmallImageClick(image)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='col-2'>
            <h5>Bcurri Apparel</h5>
              <h2>Men's Hockey Shorts 22</h2>
              {/* <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias,
                accusamus soluta quasi illum, deserunt ut iste rem obcaecati
                inventore, est harum repellendus fuga velit odio sint officia
                corrupti eum perspiciatis.
              </p> */}
     
     <Pocketshortsform />
            </div>
          </div>
        </div>
      </section>

      <Footer_v1 />
    </>
  );
};

export default MensHockeyshorts22;
