import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../../global-components/navbar-v4';
import Footer_v1 from '../../global-components/footer';
import Formmodal from '../../section-components/formmodal';
import item1Image from '../../../images/sports/products/item1.jpg';
import item2Image from '../../../images/sports/products/item2.jpg';
import item3Image from '../../../images/sports/products/item3.jpg';
import item4Image from '../../../images/sports/products/item4.jpg';

const Hoodie  = () => {
  const smallImages = [item1Image, item2Image, item3Image, item4Image];

  const [productImage, setProductImage] = useState(smallImages[0]);

  const handleSmallImageClick = (src) => {
    setProductImage(src);
  };

  return (
    <>
      <NavbarV4 />

      <Helmet>
        <title>Bcurri Sports</title>
        <meta name="description" content="" />
      </Helmet>

      <section className='bnnr-product-pg'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
              <h2>Bcurri Sports</h2>
            </div>
          </div>
        </div>
      </section>

      <section className='rugby-first-p1'>
        <div className='small-container single-product'>
          <div className='row'>
            <div className='col-2'>
              <img src={productImage} alt='' width='100%' id='ProductImg' />

              <div className='small-img-row'>
                {smallImages.map((image, index) => (
                  <div key={index} className='small-img-col'>
                    <img
                      src={image}
                      alt=''
                      width='100%'
                      className='small-img'
                      onClick={() => handleSmallImageClick(image)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='col-2'>
              <p>Rugby Socks</p>
              <h2>Hoodie</h2>
              <h3>Product Details</h3>
              <br />
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias,
                accusamus soluta quasi illum, deserunt ut iste rem obcaecati
                inventore, est harum repellendus fuga velit odio sint officia
                corrupti eum perspiciatis.
              </p>
              <select>
                <option>Available size</option>
                <option>XXL</option>
                <option>XL</option>
                <option>L</option>
                <option>M</option>
                <option>S</option>
              </select>
              <Formmodal />
            </div>
          </div>
        </div>
      </section>

      <Footer_v1 />
    </>
  );
};

export default Hoodie;
