import React from 'react';
import { Helmet } from 'react-helmet';
import blouse1front from "../../../images/sports/products/netball-blouse/1/blouse1front.png";
import blouse1back from "../../../images/sports/products/netball-blouse/1/blouse1back.png";
import blouse2front from "../../../images/sports/products/netball-blouse/2/blouse2front.png";
import blouse2back from "../../../images/sports/products/netball-blouse/2/blouse2back.png";
import blouse3front from "../../../images/sports/products/netball-blouse/3/blouse3front.png";
import blouse3back from "../../../images/sports/products/netball-blouse/3/blouse3back.png";
import blouse4front from "../../../images/sports/products/netball-blouse/4/blouse4front.png";
import blouse4back from "../../../images/sports/products/netball-blouse/4/blouse4back.png";
import blouse5front from "../../../images/sports/products/netball-blouse/5/blouse5front.png";
import blouse5back from "../../../images/sports/products/netball-blouse/5/blouse5back.png";
import blouse6front from "../../../images/sports/products/netball-blouse/6/blouse6front.png";
import blouse6back from "../../../images/sports/products/netball-blouse/6/blouse6back.png";
import blouse7front from "../../../images/sports/products/netball-blouse/7/blouse7front.png";
import blouse7back from "../../../images/sports/products/netball-blouse/7/blouse7back.png";
import blouse8front from "../../../images/sports/products/netball-blouse/8/blouse8front.png";
import blouse8back from "../../../images/sports/products/netball-blouse/8/blouse8back.png";
import blouse9front from "../../../images/sports/products/netball-blouse/9/blouse9front.png";
import blouse9back from "../../../images/sports/products/netball-blouse/9/blouse9back.png";
import blouse10front from "../../../images/sports/products/netball-blouse/10/blouse10front.png";
import blouse10back from "../../../images/sports/products/netball-blouse/10/blouse10back.png";
import blouse11front from "../../../images/sports/products/netball-blouse/11/blouse11front.png";
import blouse11back from "../../../images/sports/products/netball-blouse/11/blouse11back.png";
import blouse12front from "../../../images/sports/products/netball-blouse/12/blouse12front.png";
import blouse12back from "../../../images/sports/products/netball-blouse/12/blouse12back.png";
import blouse13front from "../../../images/sports/products/netball-blouse/13/blouse13front.png";
import blouse13back from "../../../images/sports/products/netball-blouse/13/blouse13back.png";
import blouse14front from "../../../images/sports/products/netball-blouse/14/blouse14front.png";
import blouse14back from "../../../images/sports/products/netball-blouse/14/blouse14back.png";
import blouse15front from "../../../images/sports/products/netball-blouse/15/blouse15front.png";
import blouse15back from "../../../images/sports/products/netball-blouse/15/blouse15back.png";
import blouse16front from "../../../images/sports/products/netball-blouse/16/blouse16front.png";
import blouse16back from "../../../images/sports/products/netball-blouse/16/blouse16back.png";


const Netballblousesport = () => {
    return (
<>

<Helmet>
<title>Netball Blouses - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

{/* Rugby blouse */}

<section className='cloth-rugby4' id='Netball-blouse'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Netball Blouses</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse1" className="image">
        <img src={blouse1front} className="pic-1" alt='' />
        <img src={blouse1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse1">Netball Blouse 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse2" className="image">
        <img src={blouse2front} className="pic-1" alt='' />
        <img src={blouse2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse2">Netball Blouse 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse3" className="image">
        <img src={blouse3front} className="pic-1" alt='' />
        <img src={blouse3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse3">Netball Blouse 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse4" className="image">
        <img src={blouse4front} className="pic-1" alt='' />
        <img src={blouse4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse4">Netball Blouse 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse5" className="image">
        <img src={blouse5front} className="pic-1" alt='' />
        <img src={blouse5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse5">Netball Blouse 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse6" className="image">
        <img src={blouse6front} className="pic-1" alt='' />
        <img src={blouse6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse6">Netball Blouse 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse7" className="image">
        <img src={blouse7front} className="pic-1" alt='' />
        <img src={blouse7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse7">Netball Blouse 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse8" className="image">
        <img src={blouse8front} className="pic-1" alt='' />
        <img src={blouse8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse8">Netball Blouse 8</a>
        </h3>
  </div>
 
   </div>
   <br/>

       {/* Row 3 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse9" className="image">
        <img src={blouse9front} className="pic-1" alt='' />
        <img src={blouse9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse9">Netball Blouse 9</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse10" className="image">
        <img src={blouse10front} className="pic-1" alt='' />
        <img src={blouse10back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse10">Netball Blouse 10</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse11" className="image">
        <img src={blouse11front} className="pic-1" alt='' />
        <img src={blouse11back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse11">Netball Blouse 11</a>
        </h3>
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse12" className="image">
        <img src={blouse12front} className="pic-1" alt='' />
        <img src={blouse12back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse12">Netball Blouse 12</a>
        </h3>
  </div>


   </div>
   <br/>

       {/* Row 4 */}
       <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse13" className="image">
        <img src={blouse13front} className="pic-1" alt='' />
        <img src={blouse13back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse13">Netball Blouse 13</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse14" className="image">
        <img src={blouse14front} className="pic-1" alt='' />
        <img src={blouse14back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse14">Netball Blouse 14</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse15" className="image">
        <img src={blouse15front} className="pic-1" alt='' />
        <img src={blouse15back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse15">Netball Blouse 15</a>
        </h3>
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-blouse16" className="image">
        <img src={blouse16front} className="pic-1" alt='' />
        <img src={blouse16back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-blouse16">Netball Blouse 16</a>
        </h3>
  </div>


   </div>
   <br/>
   
    

    </div>
</section>



</>
)
}

export default Netballblousesport

