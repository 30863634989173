import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class BlogGrid extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    return (
	
		<div className="ltn__blog-area ltn__blog-item-3-normal  go-top">
			<div className="container">
			<div className="row">
				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/TheCrucialRoleofLifeInsuranceSafeguardingYourLovedOnesFuture"><img src={publicUrl+"assets/img/blog/1.webp"} alt="#" /></Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<p><i className="far fa-user" />by: Admin</p>
						</li>
						<li className="ltn__blog-tags go-top">
						<p><i className="fas fa-tags" />Marketing</p>
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title"><Link to="/TheCrucialRoleofLifeInsuranceSafeguardingYourLovedOnesFuture">The Crucial Role of Life Insurance: Safeguarding Your Loved Ones' Future</Link></h3>
					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-date"><i className="far fa-calendar-alt" />July 24, 2023</li>
						</ul>
						</div>
						<div className="ltn__blog-btn">
						<Link to="/TheCrucialRoleofLifeInsuranceSafeguardingYourLovedOnesFuture">Read more</Link>
						</div>
					</div>
					</div>
				</div>
				</div>
				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/UnlockingFinancialFreedomThePowerofFinancialPlanning"><img src={publicUrl+"assets/img/blog/2.webp"} alt="#" /></Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
						<p><i className="far fa-user" />by: Admin</p>
						</li>
						<li className="ltn__blog-tags go-top">
						<p><i className="fas fa-tags" />Financial</p>
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title"><Link to="/UnlockingFinancialFreedomThePowerofFinancialPlanning">Unlocking Financial Freedom: The Power of Financial Planning</Link></h3>
					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-date"><i className="far fa-calendar-alt" />July 23, 2022</li>
						</ul>
						</div>
						<div className="ltn__blog-btn">
						<Link to="/UnlockingFinancialFreedomThePowerofFinancialPlanning">Read more</Link>
						</div>
					</div>
					</div>
				</div>
				</div>
				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/KeyTermsWhenComparingTermLifeInsurancePlans"><img src={publicUrl+"assets/img/blog/5.webp"} alt="#" /></Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
						<p><i className="far fa-user" />by: Admin</p>
						</li>
						<li className="ltn__blog-tags go-top">
						<p><i className="fas fa-tags" />Insurance</p>
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title"><Link to="/KeyTermsWhenComparingTermLifeInsurancePlans">Key Terms When Comparing Term Life Insurance Plans</Link></h3>
					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-date"><i className="far fa-calendar-alt" />july 20, 2023</li>
						</ul>
						</div>
						<div className="ltn__blog-btn">
						<Link to="/KeyTermsWhenComparingTermLifeInsurancePlans">Read more</Link>
						</div>
					</div>
					</div>
				</div>
				</div>
				
			
			</div>
			{/* <div className="row">
				<div className="col-lg-12">
				<div className="ltn__pagination-area text-center">
					<div className="ltn__pagination">
					<ul>
						<li><Link to="#"><i className="fas fa-angle-double-left" /></Link></li>
						<li><Link to="#">1</Link></li>
						<li className="active"><Link to="#">2</Link></li>
						<li><Link to="#">3</Link></li>
						<li><Link to="#">...</Link></li>
						<li><Link to="#">10</Link></li>
						<li><Link to="#"><i className="fas fa-angle-double-right" /></Link></li>
					</ul>
					</div>
				</div>
				</div>
			</div> */}
			</div>
		</div>
		
	  
    )
  }
}

export default BlogGrid;
