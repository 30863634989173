import React from 'react';
import { Helmet } from 'react-helmet';

import pant1front from "../../../images/sports/products/cricket-pants/1/pant1front.png";
import pant1back from "../../../images/sports/products/cricket-pants/1/pant1back.png";
import pant2front from "../../../images/sports/products/cricket-pants/2/pant2front.png";
import pant2back from "../../../images/sports/products/cricket-pants/2/pant2back.png";
import pant3front from "../../../images/sports/products/cricket-pants/3/pant3front.png";
import pant3back from "../../../images/sports/products/cricket-pants/3/pant3back.png";
import pant4front from "../../../images/sports/products/cricket-pants/4/pant4front.png";
import pant4back from "../../../images/sports/products/cricket-pants/4/pant4back.png";
import pant5front from "../../../images/sports/products/cricket-pants/5/pant5front.png";
import pant5back from "../../../images/sports/products/cricket-pants/5/pant5back.png";



const Cricketpantsport = () => {
    return (
<>

<Helmet>
<title>Cricket Pants - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>


{/* Rugby pant */}

<section className='cloth-rugby4' id='Cricket-pant'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Cricket Pants</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-pant1" className="image">
        <img src={pant1front} className="pic-1" alt='' />
        <img src={pant1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-pant1">Cricket Pant 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-pant2" className="image">
        <img src={pant2front} className="pic-1" alt='' />
        <img src={pant2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-pant2">Cricket Pant 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-pant3" className="image">
        <img src={pant3front} className="pic-1" alt='' />
        <img src={pant3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-pant3">Cricket Pant 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-pant4" className="image">
        <img src={pant4front} className="pic-1" alt='' />
        <img src={pant4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-pant4">Cricket Pant 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-pant5" className="image">
        <img src={pant5front} className="pic-1" alt='' />
        <img src={pant5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-pant5">Cricket Pant 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
 
   </div>
   <br/>



   
    

    </div>
</section>



</>
)
}

export default Cricketpantsport

