import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Selectsize from './section-components/mensize';
import Ladiessize from './section-components/ladiessize';
import Sockssize from './section-components/sockssize';
import Kidssize from './section-components/kidssize';
import Mensize from './section-components/mensize';

const Subtab = () => {
 
  return (
    <>
<section className="subtabs-section">
     <div className="container">
     <Tabs>
     <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <TabList>
      <Tab>Men's</Tab>
      <Tab>Ladies</Tab>
      <Tab>Kids</Tab>
      <Tab>Socks</Tab>
     
    </TabList>
            
        <TabPanel>
        <div className="service iconbox  icon-box-style8">
   <div className="icon-wrap">
    <i className="pe-7s-helm" />
  </div>
  <div className="title-wrap">
    <div className="content">
      {/* Mens size */}
       <Mensize />
    </div>
  </div>
</div>
    </TabPanel>

    <TabPanel>
    <div className="service iconbox  icon-box-style8">
   <div className="icon-wrap">
    <i className="pe-7s-helm" />
  </div>
  <div className="title-wrap">
    <div className="content">
    {/* Ladies size */}
        <Ladiessize />
    </div>
  </div>
</div>
    </TabPanel>


    <TabPanel>
    <div className="service iconbox  icon-box-style8">
   <div className="icon-wrap">
    <i className="pe-7s-helm" />
  </div>
  <div className="title-wrap">

    <div className="content">
      {/* Kids size */}
<Kidssize />

    </div>
  </div>
</div>
    </TabPanel>


    <TabPanel>
    <div className="service iconbox  icon-box-style8">
   <div className="icon-wrap">
    <i className="pe-7s-helm" />
  </div>
  <div className="title-wrap">
    <div className="content">
{/* Socks */}
     <Sockssize />
    </div>
  </div>
</div>
    </TabPanel>
        </div>
     </div>
</Tabs>
</div>
</section>
    </>
  );
};

export default Subtab;