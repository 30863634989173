import React from 'react';
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../global-components/navbar-v4';

import Sportcatagries from '../section-components/sportcatagries';
import Slidersec from '../section-components/Slidersec';
import choose6 from "../../images/sports/choose6.png";
import choose7 from "../../images/sports/choose7.png";
import choose8 from "../../images/sports/choose8.png";
import choose9 from "../../images/sports/choose9.png";
import collabimg2 from"../../images/sports/collabimg2.webp";
import Mapcountries from '../section-components/clients/mapcountries';

import homebanner from "../../images/banners/homebanner.jpg";
import homemobile from "../../images/banners/homemobile.jpg";
import Homeslider from '../section-components/homeslider';

import product1 from "../../images/banners/product1.png";
import comingsoon from "../../images/banners/comingsoon.jpg";


const Home = () => {
    return (
<>

<NavbarV4 CustomClass="ltn__header-transparent gradient-color-2"  />
<Helmet>
<title>Home - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>



<section className='frontbanner'>
    <div className='containernew'>
        <div className='row'>
        <Homeslider />
        
           {/* <div class="slideshow" id="slideshow">
  <div class="myCarousel">
    <div class="items">
    <div class="entry">
      <img src={product1} alt="Smiling person" />
    </div>
    <div class="entry">
    <img src={product4} alt="Smiling person" />
    </div>
    <div class="entry">
    <img src={product5} alt="Smiling person" />
    </div>
    <div class="entry">
    <img src={product1} alt="Smiling person" />
    </div>
    <div class="entry">
    <img src={product4} alt="Smiling person" />
    </div>
    <div class="entry">
    <img src={product5} alt="Smiling person" />
    </div>
    <div class="entry">
    <img src={product1} alt="Smiling person" />
    </div>
  </div>
  
    <div class="slideshow-controls">
  <div id="btn_prev" class="prev-next-button btn_prev">
      <i class="fas fa-caret-square-left fa-2x"></i>
  </div>
  <div id="btn_next" class="prev-next-button btn_next">
      <i class="fas fa-caret-square-right fa-2x"></i>
  </div>
</div>
</div>
            </div>  */}

            {/* <div className='bnr-detail'>
                    <h1 className='homebann'>Multisports Kit Partner</h1>
                </div> */}
        </div>
    </div>
</section>


{/* <picture>
  <source srcset={homemobile} media="(max-width: 660px)" />
  <source srcset={homebanner} />
  <img src={homebanner} alt="" className='ban' />
</picture> */}


 {/* Start Slider area  */}
{/* <section className='homebanner'>
    <div className='container'>
        <div className='row text-center'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <div className='bnr-detail'>
                    <h1 className='Rugby'>Customized</h1>
                    <div className="rotatingText">
                     <div className="rotatingText-content">
                      <h2 class="Specialist">Sportswear</h2>
                    </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section> */}


<section className='offerssec desktop'>
  <div class="scroll-parent">
    <div class="scroll-element primary">
      <span>☞ 15% discount on first order</span>
      <span>☞ Special offers for Schools & University</span>
      <span>☞ To become our Partner Club</span>
    </div>
    <div class="scroll-element secondary">
      <span>☞ 15% discount on first order </span>
      <span>☞ Special offers for Schools & University</span>
      <span>☞ To become our Partner Club</span>
    </div>
  </div>
</section>


<section className='offerssec mobile'>
    <div className='container'>
        <div className='row'>
			<div className='col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-12'>
            <h3>☞ First Order 15% Discount</h3>
          </div>
          <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-12'>
            <h3>☞ To Become Partner Club</h3>
          </div>
          <div className='col-xxl-6 col-xl-4 col-lg-4 col-md-4 col-12'>
            <h3>☞ Special Offers for Schools and University</h3>
          </div>
        </div>
    </div>
</section>


{/* <Discountsection /> */}
<Sportcatagries />


<section className='collabsec'>
    <div className='container'>
        <div className='row collb'>

      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
          <img src={collabimg2} className='collab' alt='' />
       </div>

      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
         <h2>Explore Our Collaboration Opportunities </h2>
         <p> At Bcurri Apparel, we always look forward to collaborating with partner clubs and trusted suppliers, pooling strengths to deliver excellence in sports jersey designing and printing services. Join hands with us to enjoy a multitude of benefits that will open doors to increased opportunities.
 </p>
          {/* <h3><a href="mailto:team@bcurriapparel.com">team@bcurriapparel.com</a></h3> */}
          {/* <Formmodal /> */}
          <div className="btn-cont-expl">
          <a className="btn" href="/about"> Get more information <span className="line-1" /><span className="line-2" /><span className="line-3" /><span className="line-4" /></a>
        </div>  

      </div>
        </div>
    </div>
</section>




<section className='choosesec'>
    <div className='container'>
        <div className='row'>

      <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'>
        <h2> Why Bcurri Apparel Stands Out </h2>
      </div>
      <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
        <div className="btn-cont1">
          <a className="btn" href="/about"> Get more information <span className="line-1" /><span className="line-2" /><span className="line-3" /><span className="line-4" /></a>
        </div>      
      </div>

        </div>

    </div>
</section>



<section className='chooseint'>
    <div className='container'>
    <div className='row'>
        <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
            <div class="choosecont">
                <img src={choose6} className='chimg' alt='' />
                <div className='chbox'>
                <h2 class="choosehe">Quick Turnaround </h2>
                <p class="choosepara">Count on us to ensure timely production and speedy delivery to match your team’s schedule. </p>
                </div>
           </div>
        </div>
        <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
            <div class="choosecont">
                <img src={choose7} className='chimg' alt='' />
                <div className='chbox'>
                <h2 class="choosehe">Quality Assurance</h2>
                <p class="choosepara"> We conduct rigorous quality checks to ensure that the jersey meets the top-notch standards. </p>
                </div>
           </div>
        </div>
        <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
            <div class="choosecont">
                <img src={choose8} className='chimg' alt='' />
                <div className='chbox'>
                <h2 class="choosehe">Customer-Centric </h2>
                <p class="choosepara">Our designers collaborate with you to understand your requirements and preferences.</p>
                </div>
           </div>
        </div>
        <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
            <div class="choosecont">
                <img src={choose9} className='chimg' alt='' />
                <div className='chbox'>
                <h2 class="choosehe">High-Quality Fabric </h2>
                <p class="choosepara">We use the finest high-quality fabrics known for ultimate comfort and performance. </p>
                </div>
           </div>
        </div>
       </div>
    </div>
</section>


<Slidersec />


<section className='mapsec'>
    <div className='container'>
        <div className='row'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center'>
         <h2>Our Service Locations </h2>
         <p className="provides">With our presence in multiple countries, we strive to provide tailored assistance and easy access to our services. <br/>Visit us to explore an extensive range of customization options tailored to your jersey requirements. </p>
        {/* <Map/> */}
        <Mapcountries />
      </div>
        </div>
    </div>
</section>




<Footer_v1 />
</>
)
}

export default Home

