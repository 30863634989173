import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Social extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__social-media">
			<ul>
				<li><a href="https://www.facebook.com/people/My-Policy-Planner/100094368204625/" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
				<li><a href="https://twitter.com/mypolicyplanner" title="Twitter"><i className="fab fa-twitter" /></a></li>
				<li><a href="https://www.instagram.com/my.policyplanner/" title="Instagram"><i className="fab fa-instagram" /></a></li>
				<li><a href="https://www.tiktok.com/@my_policyplanner" title="Tiktok"><i class="fa-brands fa-tiktok"></i></a></li>
				<li><a href="https://www.linkedin.com/in/my-policy-planner-a54440282/" title="Linkedin"><i class="fa-brands fa-linkedin-in"></i></a></li>
				<li><a href="https://www.pinterest.com/mypolicyplanner/" title="Pintrest"><i class="fa-brands fa-pinterest-p"></i></a></li>
				
			</ul>
		</div>
        }
}

export default Social