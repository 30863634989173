import React from 'react';
import { Helmet } from 'react-helmet';
import jacket1front from "../../../images/sports/products/stadium-jacket/1/jacket1front.png";
import jacket1back from "../../../images/sports/products/stadium-jacket/1/jacket1back.png";
import jacket2front from "../../../images/sports/products/stadium-jacket/2/jacket2front.png";
import jacket2back from "../../../images/sports/products/stadium-jacket/2/jacket2back.png";
import jacket3front from "../../../images/sports/products/stadium-jacket/3/jacket3front.png";
import jacket3back from "../../../images/sports/products/stadium-jacket/3/jacket3back.png";
import jacket4front from "../../../images/sports/products/stadium-jacket/4/jacket4front.png";
import jacket4back from "../../../images/sports/products/stadium-jacket/4/jacket4back.png";
import jacket5front from "../../../images/sports/products/stadium-jacket/5/jacket5front.png";
import jacket5back from "../../../images/sports/products/stadium-jacket/5/jacket5back.png";


const Stadiumjacketsport = () => {
    return (
<>

<Helmet>
<title>Stadium Jacket - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>


{/* Rugby jacket */}

<section className='cloth-rugby4' id='Stadium-jacket'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Stadium Jacket</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Stadium-jacket1" className="image">
        <img src={jacket1front} className="pic-1" alt='' />
        <img src={jacket1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Stadium-jacket1">Stadium Jacket 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Stadium-jacket2" className="image">
        <img src={jacket2front} className="pic-1" alt='' />
        <img src={jacket2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Stadium-jacket2">Stadium Jacket 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Stadium-jacket3" className="image">
        <img src={jacket3front} className="pic-1" alt='' />
        <img src={jacket3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Stadium-jacket3">Stadium Jacket 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Stadium-jacket4" className="image">
        <img src={jacket4front} className="pic-1" alt='' />
        <img src={jacket4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Stadium-jacket4">Stadium Jacket 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Stadium-jacket5" className="image">
        <img src={jacket5front} className="pic-1" alt='' />
        <img src={jacket5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Stadium-jacket5">Stadium Jacket 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
 
   </div>
    <br/>


    

    </div>
</section>



</>
)
}

export default Stadiumjacketsport

