import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import product1 from "../../images/banners/product1.png";
import product4 from "../../images/banners/product4.png";
import homemobile from "../../images/banners/homemobile.jpg";
import homebanner from "../../images/banners/homebanner.webp";
import tourkitbanner from "../../images/banners/tourkitbanner.webp";
import comingsoon from "../../images/banners/comingsoon.jpg";

import contactbanner from "../../images/banners/contactbanner.webp";


const Homeslider = () => {
  const sliderSettings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,       // Enable autoplay
    autoplaySpeed: 4500,  // Set autoplay speed in milliseconds

    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    
    <div className="slider-container">
      <Slider {...sliderSettings}>
        <div className="slider-item">
        <img src={homebanner} className="" alt="" />
           <div className='bnr-detail'>
              <h1 className='homebann'>Multisports Kit Partner</h1>
            </div>
        </div>
        <div className="slider-item">
        <img src={tourkitbanner} className="" alt="" />
            <div className='bnr-detail'>
              <h1 className='homebann'>Time For Tour Kit</h1>
            </div>
        </div>
      </Slider>
    </div>
  );
};

export default Homeslider;
