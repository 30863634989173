import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../../global-components/navbar-v4';
import Footer_v1 from '../../global-components/footer';
import Formmodal from '../../section-components/formmodal';
import item1Image from '../../../images/sports/products/item1.jpg';
import item2Image from '../../../images/sports/products/item2.jpg';
import item3Image from '../../../images/sports/products/item3.jpg';
import item4Image from '../../../images/sports/products/item4.jpg';
import Hockeycloth from '../../section-components/Hockey-cloth/Hockey-cloth';
import RugbySockssport from '../../section-components/Rugby-cloth/Rugby-Socks-sport';
import Basketballshortssport from '../../section-components/Basketball-cloth/Basketball-shorts-sport';
import AcheryShirtssport from '../../section-components/Achery-cloth/Achery-shirts-sport';

import BowlingShirtssport from '../../section-components/Bowling-cloth/Bowling-shirts-sport';
import Netballblousesport from '../../section-components/Netball-cloth/Netball-blouse-sport';
import Tracktopsport from '../../section-components/Training-cloth/Tracktop-sport';
import Trainingtopsport from '../../section-components/Training-cloth/Training-top-sport';

import trainingtopbanner from "../../../images/banners/trainingtopbanner.jpg"
import trainingtopmobile from "../../../images/banners/trainingtopmobile.jpg"


const TrackTop  = () => {
  const smallImages = [item1Image, item2Image, item3Image, item4Image];

  const [productImage, setProductImage] = useState(smallImages[0]);

  const handleSmallImageClick = (src) => {
    setProductImage(src);
  };

  return (
    <>
      <NavbarV4 />

      <Helmet>
        <title>Bcurri Sports</title>
        <meta name="description" content="" />
      </Helmet>


<picture>
  <source srcset={trainingtopmobile} media="(max-width: 660px)" />
  <source srcset={trainingtopbanner} />
  <img src={trainingtopbanner} alt="" className='ban' />
</picture>



 {/* Start Slider area  */}
 {/* <section className='rugby1 overlay'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-md-12'>
                 <div className='rugby1-bcurri'>
                <h1>Netball Blouse</h1>
             </div>
         
            </div>
          </div>
    </div>
</section> */}

<Trainingtopsport />

      <Footer_v1 />
    </>
  );
};

export default TrackTop;
