import React, { Component } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/social";
import Copyright from "./copyright";

import map from "../../images/sports/map.png";
import whatsapp from "../../images/sports/whatsapp.png";
import Language from "./Language";

class Footer_v1 extends Component {
  componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "Footer logo";

    return (
      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-2 plr--5">
          <div className="container">
            <div className="row footr">
              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-6">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      <Link to="/">
                        <img
                          src={publicUrl + "assets/img/entrylogo.png"}
                          className="footerlogo"
                          alt="Logo"
                        />
                      </Link>
                    </div>
                  </div>
                  {/* <p>Discover Boundless Protection and Prosperity at My Policy Planner - Your Trusted Insurance and Financial Guides!</p> */}

                  {/* <div className="ltn__social-media mt-20">
						    	<Social />
				            </div> */}
                </div>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-6">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Important Links</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <a href="/about-us">About Us</a>
                      </li>
                      <li>
                        <a href="/how-to-order">How to order</a>
                      </li>
                      <li>
                        <a href="/promotions">Promotions</a>
                      </li>
                      <li>
                        <a href="/gallery">Gallery</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-6">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Useful Links</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <a href="/collaborations">Collaborations</a>
                      </li>
                      <li>
                        <a href="/career">Careers</a>
                      </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/faqs">FAQ's</a>
                      </li>
                    </ul>
                  </div>
                  {/* <h4 className="footer-title">Get in Touch</h4>
							<div className="footer-address">
				              <ul>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-placeholder" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p>507 Denali Pass, Ste 601 Cedar Park TX 78613</p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-call" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="tel:+1 737 333 1133">+1 737 333 1133</a></p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-mail" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="mailto:info@mypolicyplanner.com">info@mypolicyplanner.com</a></p>
				                  </div>
				                </li>
				              </ul>
				            </div> */}
                </div>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-6">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Top Categories</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <a href="/Rugby-Jerseys">Rugby Jerseys</a>
                      </li>
                      <li>
                        <a href="/Mens-Hockey-Shirts">Mens Hockey Shirts</a>
                      </li>
                      <li>
                        <a href="/Rugby-Socks">Rugby Socks</a>
                      </li>
                      <li>
                        <a href="/Basketball-Vest">Basketball Vests</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12 followalign">
                <div className="footer-widget footer-newsletter-widget">
                  <h4 className="footer-title">Follow Us</h4>
                  {/* <p>We are located in Hong Kong, United Kingdom, China and Canada.</p> */}
                  <div className="footer-newsletter">
                    {/* <img src={map} className='mapimg' alt='' /> */}

                    <section className="socialicons">
                      <div className="container">
                        <div className="row">
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 ssicons">
                            <ul class="wrapper-social">
                              <a href="https://www.facebook.com/BcurriSports/">
                                <li class="icon facebook">
                                  <span class="tooltip">Facebook</span>
                                  <span>
                                    <i class="fab fa-facebook-f"></i>
                                  </span>
                                </li>
                              </a>
                              {/* <li class="icon twitter">
    <span class="tooltip">Twitter</span>
    <span><i class="fab fa-twitter"></i></span>
  </li> */}
                              <a href="https://www.instagram.com/bcurri_apparel_ltd/?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr">
                                <li class="icon instagram">
                                  <span class="tooltip">Instagram</span>
                                  <span>
                                    <i class="fab fa-instagram"></i>
                                  </span>
                                </li>
                              </a>
                              {/* <li class="icon youtube">
    <span class="tooltip">Youtube</span>
    <span><i class="fab fa-youtube"></i></span>
  </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </section>

                    <form
                      action="newsletter.php"
                      method="post"
                      encType="multipart/form-data"
                      id="myForm"
                    >
                      <input
                        className="bnnrfrm"
                        name="email"
                        type="email"
                        placeholder="Enter Email Id"
                        required
                      />
                      <div className="btn-wrapper">
                        <button className="theme-btn-1 btn" type="submit">
                          <i className="fas fa-location-arrow" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Copyright />

        <div class="whatsapp">
          <a href="https://api.whatsapp.com/send?phone=+447487509965&amp;text= Hello! Welcome to Bcurri Apparel Limited">
            <img src={whatsapp} className="whatsappimg" alt="" />
            <i class="fa fa-whatsapp" aria-hidden="true"></i>
          </a>
        </div>

        {/* <div className="lang ">
          <Language />
        </div> */}
      </footer>
    );
  }
}

export default Footer_v1;
