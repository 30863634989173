import React from 'react';
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../global-components/navbar-v4';

import careerbanner from "../../images/banners/careerbanner.webp"
import careermobile from "../../images/banners/careermobile.webp"



const Career = () => {
    return (
<>

<NavbarV4 CustomClass="ltn__header-transparent gradient-color-2"  />
<Helmet>
<title>Career - Bcurri Apparel</title>
<meta name="description" content="" />
</Helmet>

 {/* Start Slider area  */}
{/* <section className='careerbanner'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='Sports-bcurri'>
                <h1 className='bannhead'>Careers </h1>
                <h2 className='Specialist'>Bcurri Apparel</h2>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={careerimg} className='abhtbnrpick' alt='' />
            </div>
        </div>
    </div>
</section> */}


{/* <img src={careerbanner} className='ban' alt='' /> */}

<picture>
  <source srcset={careermobile} media="(max-width: 660px)" />
  <source srcset={careerbanner} />
  <img src={careerbanner} alt="" className='ban' />
</picture>


<section className='abtusbcurri'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>      
            <div className='first-p'>

            <div class="container w3-animate-zoom">
    <div class="intro-text text-center">
      <div class="intro-lead-in">Join Our Team </div>
      <hr class="horiline"/>
        {/* <div class="intro-heading text-uppercase">Explore our career opportunities </div> */}
        <h2>Explore Our Career Opportunities </h2>
    </div>
  </div>
            <p>Passionate about sportswear? Enthusiastic about innovation? We may be a perfect fit for you. </p>
             <p>At Bcurri Apparel, we offer unlimited opportunities to individuals passionate about innovation and creativity in sportswear. We recognize that our employees are our most important asset that drives our success. Therefore, we are committed to providing a supportive and collaborative work environment like no other organization. We always empower our employees to think out-of-the-box solutions and be innovative. </p>
             <p>When you join Bcurri Apparel, you’ll enjoy enormous benefits that will accelerate your career growth and improve job satisfaction. Here are a few advantages that you’ll experience while working with us:</p>

              <h4 className='compe'> 1. Performance-based Incentives </h4>
              <p>Customer satisfaction is our top priority. Our employees, who constantly deliver exceptional work and exceed our client’s expectations are eligible for incentives. </p>
              <h4 className='compe'> 2. Career Growth </h4>
              <p>We provide countless opportunities that will help you hone your skills and grow your career.  </p>
              <h4 className='compe'> 3. Freedom to Innovate </h4>
              <p>We foster a culture of innovation. If you have new ideas, you’re free to bring them to the table. </p>

              <h4 className='compe'> 4. Collaborative Environment </h4>
              <p>We believe the best ideas come when people with diverse skills collaborate and work towards a common goal. So, when you join us, you’ll join a team that values your contribution. </p>
              <h4 className='compe'> 5. Employee Recognition </h4>
              <p>At Bcurri Apparel, your hard work will get recognition. We have appropriate programs to acknowledge your invaluable contributions. </p>

             </div>
            </div>
        </div>

    </div>
</section>


<section className='oppor'> 
<div className='container'>
        <div className='row'>
      
        <div className='row'>
            <div className='col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-12'>
            <div className='work'>
            <h2 class='ico work'>If you’re passionate about creating premium-quality custom sportswear, we’d love to have you on our team. Send us an email at <a className="collabmail" href="mailto:team@bcurriapparel.com">team@bcurriapparel.com</a> to join our innovative team committed to shaping the future of sportswear.</h2> 
                </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
            <div className="btn-cont1 careerbtn">
               <a className="btn" href="/Contact"> Join Now <span className="line-1" /><span className="line-2" /><span className="line-3" /><span className="line-4" /></a>
            </div>  
            {/* <div className='work-btn'>
                <a class="btn btn-primary btn-xl text-uppercase js-scroll-trigger button--secondary"  href="/Contact">Apply Now</a>
            </div> */}
            </div>
            <br /> <hr />
        </div>

        </div>
    </div>
</section>


{/* <section className='oppor'> 
<div className='container'>
        <div className='row'>
       
	      <h2><span class='ico'></span> View Our Current Openings</h2>

        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='work'>
	          <h2 class='ico work'>Production Specialist</h2>
	          <p><strong> Job Type: Full Time </strong></p>
	          <p>
            Job type: Full time 
	            <sup>(Jun 2013)</sup>
	          </p>
   	          <p class='info'>Do you have experience in fabric cutting, printing techniques and quality control? </p> 
                </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='work-btn'>
                <a class="btn btn-primary btn-xl text-uppercase js-scroll-trigger button--secondary"  href="/Contact">Apply Now</a>
            </div>
            </div>
            <hr />

            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='work'>
	          <h2 class='ico work'>Graphic Designer</h2>
	          <p><strong>Job Type: Full Time</strong></p>
	          <p>
	            Interactive Developer
	            <sup>(Jun 2013)</sup>
	          </p>
   	          <p class='info'>We’re looking for individuals skilled in digital illustration and graphic design. Join our team today. </p> 
                </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='work-btn'>
                <a class="btn btn-primary btn-xl text-uppercase js-scroll-trigger button--secondary"  href="/Contact">Apply Now</a>
            </div>
            </div>
            <hr />
        </div>

        </div>
    </div>
</section> */}

   {/* <section className='socialicons'> 
        <div className='container'>
        <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center'>
        <ul class="wrapper">
  <li class="icon facebook">
    <span class="tooltip">Facebook</span>
    <span><i class="fab fa-facebook-f"></i></span>
  </li>
  <li class="icon twitter">
    <span class="tooltip">Twitter</span>
    <span><i class="fab fa-twitter"></i></span>
  </li>
  <li class="icon instagram">
    <span class="tooltip">Instagram</span>
    <span><i class="fab fa-instagram"></i></span>
  </li>
  <li class="icon youtube">
    <span class="tooltip">Youtube</span>
    <span><i class="fab fa-youtube"></i></span>
  </li>
</ul>
</div>
        </div>
        </div>
    </section> */}


<Footer_v1 />
</>
)
}

export default Career

