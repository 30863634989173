import React from "react";
import football2 from  "../../images/sports/football2.png";
import cricket from  "../../images/sports/cricket.png";
import rubgy from "../../images/sports/rugby.jpg";
import hockey from "../../images/sports/hockey.png";
import netball from  "../../images/sports/netball.png";
import football from "../../images/sports/football.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules

const Sportcatagries = () => {
  return (
    <>
      <div className="Sportcatagries">
            <div className="container">
            <div className='row text-center'>
        <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
            <h2>Jerseys For Every Game </h2>
            <p className="provides">At Bcurri Apparel, we tailor your sportswear with unique designs, colors and logos specific to your sport. <br/> Whether it’s basketball, cricket, football, soccer or any other sport, we’ve got you covered for all your sports jersey needs. </p>
   
        </div>
        </div>
            <div className="Sportcatagries-wrapper homepg align-items-center">
         <div className="Sportcatagries-slider">
          <div className="swiper-Sportcatagries">
          <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                      
                      Pagination={true}
                    navigation={true}
                       loop={true}
                     autoplay={{
                      delay: 1500,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                                
                     breakpoints={{
                        320: {
                       slidesPerView: 1,
                       spaceBetween: 20,
                         },
                         580: {
                       slidesPerView: 2,
                       spaceBetween: 20,
                         },
                         700: {
                       slidesPerView: 3,
                       spaceBetween: 10,
                         },
                         768: {
                        slidesPerView: 3,
                       spaceBetween: 10,
                          },
                          1024: {
                    slidesPerView: 3,
                        spaceBetween: 10,
                           },
                           1200: {
                    slidesPerView: 3,
                        spaceBetween: 20,
                           },
                           1241: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1400: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1600: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1920: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                              }}
              
                    className="mySwiper"
                               >

 <SwiperSlide>
  <img src={cricket} className="football2" alt="" />
  <h3>Cricket</h3>
 </SwiperSlide>
 <SwiperSlide>
  <img src={netball} className="football2" alt="" />
  <h3>Netball</h3>
 </SwiperSlide>
 <SwiperSlide>
  <img src={football} className="football2" alt="" />
  <h3>Football</h3>
 </SwiperSlide>
 <SwiperSlide>
  <img src={hockey} className="football2" alt="" />
  <h3>Hockey</h3>
 </SwiperSlide>
 <SwiperSlide>
  <img src={rubgy} className="football2" alt="" />
  <h3>Rugby</h3>
 </SwiperSlide>
 <SwiperSlide>
  <img src={football2} className="football2" alt="" />
  <h3>Basketball</h3>
 </SwiperSlide>
       
                                   
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Sportcatagries;