import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import ukflag from "../../images/sports/ukflag.png";
import canadaflag from "../../images/sports/canadaflag.png";
import hongkongflag from "../../images/sports/hongkongflag.png";
import chinaflag from "../../images/sports/chinaflag.png";

class CopyRight extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
			<div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
			<div className="container-fluid ltn__border-top-2">
				<div className="row text-center">
				<div className=" col-xxl-12 col-lg-12 col-lg-12 col-md-12 col-12">

				   <div class="loc">
                                <img src={ukflag} className='flag' alt='' />
                                <img src={canadaflag} className='flag' alt='' />
                                <img src={hongkongflag} className='flag' alt='' />
                                <img src={chinaflag} className='flag' alt='' />
                    </div>

					<div className="ltn__copyright-design clearfix">
					<p>Copyright 2023 <a href="/">Bcurri Apparel Limited. </a>  All Rights Reserved.</p>
					</div>
				</div>
			
				</div>
			</div>
			</div>
        )
    }
}


export default CopyRight