import React from 'react';
import { Helmet } from 'react-helmet';
import shirt1front from "../../../images/sports/products/gillet-shirts/1/shirt1front.png";
import shirt1back from "../../../images/sports/products/gillet-shirts/1/shirt1back.png";
import shirt2front from "../../../images/sports/products/gillet-shirts/2/shirt2front.png";
import shirt2back from "../../../images/sports/products/gillet-shirts/2/shirt2back.png";
import shirt3front from "../../../images/sports/products/gillet-shirts/3/shirt3front.png";
import shirt3back from "../../../images/sports/products/gillet-shirts/3/shirt3back.png";
import shirt4front from "../../../images/sports/products/gillet-shirts/4/shirt4front.png";
import shirt4back from "../../../images/sports/products/gillet-shirts/4/shirt4back.png";
import shirt5front from "../../../images/sports/products/gillet-shirts/5/shirt5front.png";
import shirt5back from "../../../images/sports/products/gillet-shirts/5/shirt5back.png";



const GilletShirtssport = () => {
    return (
<>

<Helmet>
<title>Gillets - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

{/* Rugby shirt */}

<section className='cloth-rugby4' id='Gillet-Shirt'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Gillets</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Gillet1" className="image">
        <img src={shirt1front} className="pic-1" alt='' />
        <img src={shirt1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Gillet1">Gillet 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Gillet2" className="image">
        <img src={shirt2front} className="pic-1" alt='' />
        <img src={shirt2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Gillet2">Gillet 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Gillet3" className="image">
        <img src={shirt3front} className="pic-1" alt='' />
        <img src={shirt3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Gillet3">Gillet 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Gillet4" className="image">
        <img src={shirt4front} className="pic-1" alt='' />
        <img src={shirt4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Gillet4">Gillet 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Gillet5" className="image">
        <img src={shirt5front} className="pic-1" alt='' />
        <img src={shirt5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Gillet5">Gillet 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
 
   </div>
   <br/>

   
    

    </div>
</section>



</>
)
}

export default GilletShirtssport

