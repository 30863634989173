
import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Socksform = () => {

const [validationErrors, setValidationErrors] = useState({});

  const handleSubmit = (event) => {
      event.preventDefault();
      // Add validation logic here
      const selectsize = event.target.selectsize.value;

  
      // Validation logic
      const errors = {};
      if (selectsize === 'Select Size' ) {
        errors.size = 'Please select size';
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return; // Prevent form submission if validation fails
      }

      // Reset validation errors
      setValidationErrors({});

      const formData = new FormData(event.target);

      event.target.submit();

    };

            // Get the current page slug
  const currentSlug = window.location.pathname.split('/').pop();

  return (
 <>
    
        <form action="socks.php"
                      method="post"
                      encType="multipart/form-data"
                      id="jobdetil"
                      onSubmit={handleSubmit}>
                        <div class="row">
                            <div class="col-sm-6">
                                {/* <label class="label" for="name">Name</label> */}
                                <input type="text" name="fname" class="form-control1 prod" placeholder='Full Name'required />  
                            </div>
                            <div class="col-sm-6">
                            <input type="tel" name="phone" class="form-control1 prod" placeholder='Phone No.' required />
                            </div>
                            <div class="col-sm-6">
                                <input type="email" name="email" class="form-control1 prod" placeholder='Email Id' required />
                            </div>

                            <div class="col-sm-6">
                            <select name="selectsize" class="form-control1 prod" id="Country" required>
                                <option>Select Size</option>
                                <option value="S" >S</option>
                                <option value="M" >M</option>
                                <option value="L" >L</option>
                                <option value="XL" >XL</option>
                              </select>
                              {validationErrors.size && <p className="error">{validationErrors.size}</p>}
                            </div>      

                            <div class="col-sm-12">
                            <label class="fieldlabels1">Upload Logo</label>
                            <input type="file" name="file" required/>
                            </div>            
                          
                        </div>
                        <input type="hidden" name="pageSlug" value={currentSlug} />
                        <button class="btn btn-block product" type="submit">Submit Now</button>
        </form>

 </>
    );
}
 
export default Socksform;