import React from 'react';
import { Helmet } from 'react-helmet';
import skorts1front from "../../../images/sports/products/netball-skorts/1/skorts1front.png";
import skorts1back from "../../../images/sports/products/netball-skorts/1/skorts1back.png";
import skorts2front from "../../../images/sports/products/netball-skorts/2/skorts2front.png";
import skorts2back from "../../../images/sports/products/netball-skorts/2/skorts2back.png";
import skorts3front from "../../../images/sports/products/netball-skorts/3/skorts3front.png";
import skorts3back from "../../../images/sports/products/netball-skorts/3/skorts3back.png";
import skorts4front from "../../../images/sports/products/netball-skorts/4/skorts4front.png";
import skorts4back from "../../../images/sports/products/netball-skorts/4/skorts4back.png";
import skorts5front from "../../../images/sports/products/netball-skorts/5/skorts5front.png";
import skorts5back from "../../../images/sports/products/netball-skorts/5/skorts5back.png";
import skorts6front from "../../../images/sports/products/netball-skorts/6/skorts6front.png";
import skorts6back from "../../../images/sports/products/netball-skorts/6/skorts6back.png";
import skorts7front from "../../../images/sports/products/netball-skorts/7/skorts7front.png";
import skorts7back from "../../../images/sports/products/netball-skorts/7/skorts7back.png";
import skorts8front from "../../../images/sports/products/netball-skorts/8/skorts8front.png";
import skorts8back from "../../../images/sports/products/netball-skorts/8/skorts8back.png";
import skorts9front from "../../../images/sports/products/netball-skorts/9/skorts9front.png";
import skorts9back from "../../../images/sports/products/netball-skorts/9/skorts9back.png";
import skorts10front from "../../../images/sports/products/netball-skorts/10/skorts10front.png";
import skorts10back from "../../../images/sports/products/netball-skorts/10/skorts10back.png";


const Netballskortssport = () => {
    return (
<>

<Helmet>
<title>Netball Skorts - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

{/* Rugby skorts */}

<section className='cloth-rugby4' id='Netball-skort'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Netball Skorts</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-skort1" className="image">
        <img src={skorts1front} className="pic-1" alt='' />
        <img src={skorts1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-skort1">RNetball Skort 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-skort2" className="image">
        <img src={skorts2front} className="pic-1" alt='' />
        <img src={skorts2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-skort2">Netball Skort 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-skort3" className="image">
        <img src={skorts3front} className="pic-1" alt='' />
        <img src={skorts3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-skort3">Netball Skort 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-skort4" className="image">
        <img src={skorts4front} className="pic-1" alt='' />
        <img src={skorts4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-skort4">Netball Skort 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-skort5" className="image">
        <img src={skorts5front} className="pic-1" alt='' />
        <img src={skorts5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-skort5">Netball Skort 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-skort6" className="image">
        <img src={skorts6front} className="pic-1" alt='' />
        <img src={skorts6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-skort6">Netball Skort 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-skort7" className="image">
        <img src={skorts7front} className="pic-1" alt='' />
        <img src={skorts7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-skort7">Netball Skort 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-skort8" className="image">
        <img src={skorts8front} className="pic-1" alt='' />
        <img src={skorts8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-skort8">Netball Skort 8</a>
        </h3>
  </div>
 
   </div>
   <br/>

       {/* Row 3 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-skort9" className="image">
        <img src={skorts9front} className="pic-1" alt='' />
        <img src={skorts9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-skort9">Netball Skort 9</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Netball-skort10" className="image">
        <img src={skorts10front} className="pic-1" alt='' />
        <img src={skorts10back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Netball-skort10">Netball Skort 10</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>


   </div>
   <br/>

   
    

    </div>
</section>



</>
)
}

export default Netballskortssport

