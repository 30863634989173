import React from 'react';
import { Helmet } from 'react-helmet';
import shirt1front from "../../../images/sports/products/Polo-tshirts/1/shirt1front.png";
import shirt1back from "../../../images/sports/products/Polo-tshirts/1/shirt1back.png";
import shirt2front from "../../../images/sports/products/Polo-tshirts/2/shirt2front.png";
import shirt2back from "../../../images/sports/products/Polo-tshirts/2/shirt2back.png";
import shirt3front from "../../../images/sports/products/Polo-tshirts/3/shirt3front.png";
import shirt3back from "../../../images/sports/products/Polo-tshirts/3/shirt3back.png";
import shirt4front from "../../../images/sports/products/Polo-tshirts/4/shirt4front.png";
import shirt4back from "../../../images/sports/products/Polo-tshirts/4/shirt4back.png";
import shirt5front from "../../../images/sports/products/Polo-tshirts/5/shirt5front.png";
import shirt5back from "../../../images/sports/products/Polo-tshirts/5/shirt5back.png";
import shirt6front from "../../../images/sports/products/Polo-tshirts/6/shirt6front.png";
import shirt6back from "../../../images/sports/products/Polo-tshirts/6/shirt6back.png";
import shirt7front from "../../../images/sports/products/Polo-tshirts/7/shirt7front.png";
import shirt7back from "../../../images/sports/products/Polo-tshirts/7/shirt7back.png";
import shirt8front from "../../../images/sports/products/Polo-tshirts/8/shirt8front.png";
import shirt8back from "../../../images/sports/products/Polo-tshirts/8/shirt8back.png";
import shirt9front from "../../../images/sports/products/Polo-tshirts/9/shirt9front.png";
import shirt9back from "../../../images/sports/products/Polo-tshirts/9/shirt9back.png";
import shirt10front from "../../../images/sports/products/Polo-tshirts/10/shirt10front.png";
import shirt10back from "../../../images/sports/products/Polo-tshirts/10/shirt10back.png";
import shirt11front from "../../../images/sports/products/Polo-tshirts/11/shirt11front.png";
import shirt11back from "../../../images/sports/products/Polo-tshirts/11/shirt11back.png";
import shirt12front from "../../../images/sports/products/Polo-tshirts/12/shirt12front.png";
import shirt12back from "../../../images/sports/products/Polo-tshirts/12/shirt12back.png";
import shirt13front from "../../../images/sports/products/Polo-tshirts/13/shirt13front.png";
import shirt13back from "../../../images/sports/products/Polo-tshirts/13/shirt13back.png";
import shirt14front from "../../../images/sports/products/Polo-tshirts/14/shirt14front.png";
import shirt14back from "../../../images/sports/products/Polo-tshirts/14/shirt14back.png";
import shirt15front from "../../../images/sports/products/Polo-tshirts/15/shirt15front.png";
import shirt15back from "../../../images/sports/products/Polo-tshirts/15/shirt15back.png";
import shirt16front from "../../../images/sports/products/Polo-tshirts/16/shirt16front.png";
import shirt16back from "../../../images/sports/products/Polo-tshirts/16/shirt16back.png";
import shirt17front from "../../../images/sports/products/Polo-tshirts/17/shirt17front.png";
import shirt17back from "../../../images/sports/products/Polo-tshirts/17/shirt17back.png";
import shirt18front from "../../../images/sports/products/Polo-tshirts/18/shirt18front.png";
import shirt18back from "../../../images/sports/products/Polo-tshirts/18/shirt18back.png";
import shirt19front from "../../../images/sports/products/Polo-tshirts/19/shirt19front.png";
import shirt19back from "../../../images/sports/products/Polo-tshirts/19/shirt19back.png";

const Polotshirtsport = () => {
    return (
<>

<Helmet>
<title>Polo Shirts - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>


{/* Rugby shirt */}

<section className='cloth-rugby4' id='Polo-tshirt'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Polo Shirts</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt1" className="image">
        <img src={shirt1front} className="pic-1" alt='' />
        <img src={shirt1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt1">Polo Shirt 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt2" className="image">
        <img src={shirt2front} className="pic-1" alt='' />
        <img src={shirt2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt2">Polo Shirt 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirtt3" className="image">
        <img src={shirt3front} className="pic-1" alt='' />
        <img src={shirt3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt3">Polo Shirt 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt4" className="image">
        <img src={shirt4front} className="pic-1" alt='' />
        <img src={shirt4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt4">Polo Shirt 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt5" className="image">
        <img src={shirt5front} className="pic-1" alt='' />
        <img src={shirt5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt5">Polo Shirt 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt6" className="image">
        <img src={shirt6front} className="pic-1" alt='' />
        <img src={shirt6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt6">Polo Shirt 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt7" className="image">
        <img src={shirt7front} className="pic-1" alt='' />
        <img src={shirt7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt7">Polo Shirt 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt8" className="image">
        <img src={shirt8front} className="pic-1" alt='' />
        <img src={shirt8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt8">Polo Shirt 8</a>
        </h3>
  </div>
 
   </div>
    <br/>

      {/* Row 3 */}
      <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt9" className="image">
        <img src={shirt9front} className="pic-1" alt='' />
        <img src={shirt9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt9">Polo Shirt 9</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-tshirt10" className="image">
        <img src={shirt10front} className="pic-1" alt='' />
        <img src={shirt10back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt10">Polo Shirt 10</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt11" className="image">
        <img src={shirt11front} className="pic-1" alt='' />
        <img src={shirt11back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt11">Polo Shirt 11</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt12" className="image">
        <img src={shirt12front} className="pic-1" alt='' />
        <img src={shirt12back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt12">Polo Shirt 12</a>
        </h3>
  </div>
 
     </div>

    <br/>
         {/* Row 4 */}
         <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt13" className="image">
        <img src={shirt13front} className="pic-1" alt='' />
        <img src={shirt13back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt13">Polo Shirt 13</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt14" className="image">
        <img src={shirt14front} className="pic-1" alt='' />
        <img src={shirt14back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt14">Polo Shirt 14</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt15" className="image">
        <img src={shirt15front} className="pic-1" alt='' />
        <img src={shirt15back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt15">Polo Shirt 15</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt16" className="image">
        <img src={shirt16front} className="pic-1" alt='' />
        <img src={shirt16back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt16">Polo Shirt 16</a>
        </h3>
  </div>
 
     </div>


     <br/>
         {/* Row 5 */}
         <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt17" className="image">
        <img src={shirt17front} className="pic-1" alt='' />
        <img src={shirt17back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt17">Polo Shirt 17</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt18" className="image">
        <img src={shirt18front} className="pic-1" alt='' />
        <img src={shirt18back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt18">Polo Shirt 18</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Polo-Shirt19" className="image">
        <img src={shirt19front} className="pic-1" alt='' />
        <img src={shirt19back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Polo-Shirt19">Polo Shirt 19</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
 
     </div>

    </div>
</section>



</>
)
}

export default Polotshirtsport

