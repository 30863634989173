import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Language from './Language';

class NavbarV4 extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
 
       
        return (
		<div>
			<section className='topbar'>
		<div className='container'>
		<div className='row' id='top-bar-row'>
		<div className='col-xxl-10 col-xl-10 col-md-8 col-sm-6' id='meets'>
		<p>The one-stop shop for custom sportswear needs</p>
		</div>
		<div className='col-xxl-2 col-xl-2 col-md-4 col-sm-6'>
		<div className='language-top'>
		<Language />
		</div>
		</div>
		</div>
		</div>
		</section>
            <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-2">
			
			  <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
			    <div className="container">
			      <div className="row">
			        <div className="col-1 " id='single-col'>
			          <div className="site-logo-wrap">
			            <div className="site-logo go-top">
						<Link to="/"><img src={publicUrl+"assets/img/entrylogo.png"} className='bcurrilogo' alt="Logo" /></Link>
			            </div>
			            <div className="get-support clearfix d-none">
			              <div className="get-support-icon">
			                <i className="icon-call" />
			              </div>
			              <div className="get-support-info">
			                <h6>Get Support</h6>
			                <h4><a href="tel:+123456789">123-456-789-10</a></h4>
			              </div>
			            </div>
			          </div>
			        </div>
			        <div className="col-11  header-menu-column menu-color-white" id='hding'>
			          <div className="header-menu d-none d-xl-block go-top">
					  <nav>
							<div className="ltn__main-menu">
								<ul>
								<li><Link to="/home">Home</Link></li>
								<li ><Link to="/about-us">About Us</Link></li>
								<li class="menu-icon"><Link to="#">Sports</Link>
								<ul class="mega-menu">

									<li class="megaa "><Link className="ctitle-highlight">Rugby</Link>
										<ul className='mega-list'>
										<li><a href="/rugby-jerseys">Rugby Jerseys</a></li>
										<li><a href="/rugby-shorts">Rugby Shorts</a></li>
										{/* <li><a href="/Sublimation-Rugby-Shorts">Sublimation Rugby Shorts</a></li> */}
										<li><a href="/rugby-socks">Rugby Socks</a></li>
										</ul>
										<Link className="ctitle-highlight" >Basketball</Link>
										<ul className='mega-list'>
										<li><Link to="/basketball-vest">Basketball Vests</Link></li>
										<li><Link to="/basketball-shorts">Basketball Shorts</Link></li>
										</ul>
										<Link className="ctitle-highlight" >Cricket</Link>
										<ul className='mega-list'>
										<li><a href="/cricket-shirts">Cricket Shirts</a></li>	
										<li><a href="/cricket-pants">Cricket Pants</a></li>	
										</ul>
									</li>
									
									<li class="megaa"><Link className="ctitle-highlight" >Netball</Link>
									<ul className='mega-list'>
										<li><a href="/netball-blouse">Netball Blouse</a></li>
										<li><a href="/netball-top">Netball Top</a></li>	
										<li><a href="/netball-skorts">Netball Skorts</a></li>	
										</ul>
										<Link className="ctitle-highlight" >Training</Link>
										<ul className='mega-list'>
										<li><Link to="/corelayer-fullsleeves">Corelayer - Full Sleeves</Link></li>
										<li><Link to="/corelayer-halfsleeves">Corelayer - Half Sleeves</Link></li>
										<li><Link to="/corelayer-sleeveless">Corelayer - Sleeveless</Link></li>
										<li><Link to="/training-top">Training Top</Link></li>
										<li><Link to="/track-top">Track Tops</Link></li>
										<li><Link to="/track-pants">Track Pants</Link></li>
										<li><Link to="/t-shirts">T-shirts</Link></li>
										<li><Link to="/vests">Vests</Link></li>
										</ul>
										{/* <Link className="ctitle-highlight" >Ice-Hockey</Link>
										<ul className='mega-list'>
										<li><a href="/Ice-Hockey-Shirts">Ice Hockey Shirts</a></li>	
										</ul> */}
									</li>
										

									<li  class="megaa">
									<Link className="ctitle-highlight" >Hockey</Link>
									<ul className='mega-list'>
									<li><a href="/mens-hockey-shirts">Men's Hockey Shirts</a></li>	
									<li><a href="/ladies-hockey-shirts">Ladies Hockey Shirts</a></li>	
									<li><a href="/ladies-vest">Ladies Vests </a></li>
									<li><a href="/gk-smock">Gk Smock</a></li>
									<li><a href="/hockey-shorts">Hockey Shorts</a></li>	
									<li><a href="/hockey-skorts">Hockey Skorts</a></li>	
									<li><a href="/hockey-socks">Hockey Socks</a></li>
									</ul>
									<Link className="ctitle-highlight" >Soccer</Link>
									<ul className='mega-list'>
									<li><a href="/soccer-shirts">Soccer Shirts</a></li>	
									<li><a href="/soccer-shorts">Soccer Shorts</a></li>	
									<li><a href="/soccer-socks">Soccer Socks</a></li>
									</ul>
									</li>
									
									<li class="megaa">
									<Link className="ctitle-highlight" >Bowling</Link>
										<ul className='mega-list'>
										<li><a href="/bowling-shirts">Bowling Shirts</a></li>
										</ul>
										<Link className="ctitle-highlight" >Achery</Link>
										<ul className='mega-list'>
										<li><a href="/achery-shirts">Achery Shirts</a></li>	
										</ul>

										<Link className="ctitle-highlight">Casual</Link>
										<ul className='mega-list'>
										<li><Link to="/polo-shirts">Polo Shirts</Link></li>
										<li><Link to="/hooded-sweatshirts">Hooded Sweatshirts</Link></li>
										<li><Link to="/quarter-zip-sweatshirts">Quarter Zip Sweatshirts</Link></li>
										<li><Link to="/round-neck-sweatshirts">Round Neck Sweatshirts</Link></li>
										<li><Link to="/fleece-jacket">Fleece Jackets</Link></li>
										<li><Link to="/manager-jacket">Manager Jackets</Link></li>
										<li><Link to="/stadium-jacket">Stadium Jackets</Link></li>
										<li><Link to="/gillets">Gillets</Link></li>
										</ul>

										{/* <img src={menuimg} className='menuimg' alt='' /> */}
									</li>
																		
								</ul>
								</li>
							
								<li><Link to="/how-to-order">How to Order</Link></li>
								{/* <li><Link to="/Promotions">Promotions</Link></li>							 */}
							{/* <li><Link to="/faqs">FAQ</Link></li> */}
							<li><Link to="/collaborations">Collaborations</Link></li>
							<li><Link to="/gallery">Gallery</Link></li>
							<li><Link to="/career">Career</Link></li>
							<li><Link to="/workwear">Workwear</Link></li>
							<li><Link to="/contact-us">Contact</Link></li>
								
								
								</ul>
							</div>
							</nav>
			          </div>
			        </div>
			        <div className="col--- ltn__header-options ltn__header-options-2 ">


{/* Mobile Menu Button */}
			          <div className="mobile-menu-toggle d-xl-none">
			            <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
			              <svg viewBox="0 0 800 600">
			                <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
			                <path d="M300,320 L540,320" id="middle" />
			                <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
			              </svg>
			            </a>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</header>
			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
					<div className="site-logo">
					   <Link to="/"><img src={publicUrl+"assets/img/entrylogo.png"} className='bcurrilogomob' alt="Logo" /></Link>
					</div>
					<button className="ltn__utilize-close">×</button>
					</div>
				<div className="ltn__utilize-menu">
					<ul>
						<li><a href="/">Home</a></li>
						<li><Link to="/about-us">About</Link></li>

						<li><Link to="">Rugby</Link>
						<ul className="sub-menu">
					       <li><a href="/rugby-jerseys">Rugby Jerseys</a></li>
						   <li><a href="/rugby-shorts">Rugby Shorts</a></li>
						   <li><a href="/rugby-socks">Rugby Socks</a></li>
						</ul>
						</li>


						<li><Link to="">Basketball</Link>
						<ul className="sub-menu">
						    <li><Link to="/basketball-vest">Basketball Vests</Link></li>
							<li><Link to="/basketball-shorts">Basketball Shorts</Link></li>
						</ul>
						</li>

						<li><Link to="">Cricket</Link>
						<ul className="sub-menu">
					     	<li><a href="/cricket-shirts">Cricket Shirts</a></li>	
							<li><a href="/cricket-pants">Cricket Pants</a></li>	
						</ul>
						</li>


						<li><Link to="">Netball</Link>
						<ul className="sub-menu">
			            	<li><a href="/netball-blouse">Netball Blouse</a></li>
							<li><a href="/netball-top">Netball Top</a></li>	
							<li><a href="/netball-skorts">Netball Skorts</a></li>	
						</ul>
						</li>


						<li><Link to="">Soccer</Link>
						<ul className="sub-menu">
					     	<li><a href="/soccer-shirts">Soccer Shirts</a></li>	
					   		<li><a href="/soccer-shorts">Soccer Shorts</a></li>	
							<li><a href="/soccer-socks">Soccer Socks</a></li>
						</ul>
						</li>


						<li><Link to="">Hockey</Link>
						<ul className="sub-menu">
					     	<li><a href="/mens-hockey-shirts">Men's Hockey Shirts</a></li>	
							<li><a href="/ladies-hockey-shirts">Ladies Hockey Shirts</a></li>	
							<li><a href="/ladies-vest">Ladies Vests </a></li>
							<li><a href="/gk-smock">Gk Smock</a></li>
							<li><a href="/hockey-shorts">Hockey Shorts</a></li>	
							<li><a href="/hockey-skorts">Hockey Skorts</a></li>	
							<li><a href="/hockey-socks">Hockey Socks</a></li>
						</ul>
						</li>

						<li><Link to="">Bowling</Link>
						<ul className="sub-menu">
						      <li><a href="/bowling-shirts">Bowling Shirts</a></li>
						</ul>
						</li>

						<li><Link to="">Achery</Link>
						<ul className="sub-menu">
					     	<li><a href="/achery-shirts">Achery Shirts</a></li>	
						</ul>
						</li>


						<li><Link to="">Training</Link>
						<ul className="sub-menu">
						    <li><Link to="/corelayer-fullsleeves">Corelayer - Full Sleeves</Link></li>
							<li><Link to="/corelayer-halfsleeves">Corelayer - Half Sleeves</Link></li>
							<li><Link to="/corelayer-sleeveless">Corelayer - Sleeveless</Link></li>
							<li><Link to="/training-top">Training Top</Link></li>
							<li><Link to="/track-top">Track Tops</Link></li>
							<li><Link to="/track-pants">Track Pants</Link></li>
							<li><Link to="/t-shirts">T-shirts</Link></li>
						    <li><Link to="/vests">Vests</Link></li>
						</ul>
						</li>

						<li><Link to="">Casual</Link>
						<ul className="sub-menu">
					     	<li><Link to="/polo-shirts">Polo Shirts</Link></li>
							<li><Link to="/hooded-sweatshirts">Hooded Sweatshirts</Link></li>
							<li><Link to="/quarter-zip-sweatshirts">Quarter Zip Sweatshirts</Link></li>
							<li><Link to="/round-neck-sweatshirts">Round Neck Sweatshirts</Link></li>
							<li><Link to="/fleece-jacket">Fleece Jackets</Link></li>
							<li><Link to="/manager-jacket">Manager Jackets</Link></li>
							<li><Link to="/stadium-jacket">Stadium Jackets</Link></li>
							<li><Link to="/gillets">Gillets</Link></li>
						</ul>
						</li>

												
						<li><Link to="/how-to-order">How to Order</Link></li>
						<li><Link to="/collaborations">Collaborations</Link></li>
						<li><Link to="/gallery">Gallery</Link></li>
						<li><Link to="/career">Career</Link></li>
						<li><Link to="/workwear">Workwear</Link></li>
						<li><Link to="/contact-us">Contact Us</Link></li>

					</ul>
					</div>


					
					<div className="ltn__social-media-2">
					<ul>
						<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
						<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
						<li><a href="#" title="Youtube"><i className="fab fa-youtube" /></a></li>
						<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
					</ul>
					</div>



				
				</div>
				</div>
		</div>
        )
    }
}


export default NavbarV4