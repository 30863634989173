import React from 'react';
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import gal11 from '../../images/sports/gallery/gal11.jpg';
import NavbarV2 from '../global-components/navbar-v2';
import BlogGrid from '../blog-components/blog-grid';

const Blog = () => {
    return (
<>
<NavbarV2 />
<Helmet>
<title>Blogs - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>


 {/* Start Slider area  */}

 {/* <section className='contactpge'>
    <div className='container'>
        <div className='row text-center'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <h2>Our Blog</h2>
                <p>Unlock Your Financial Potential: Expert Insights, Tips, and News. Your Trusted Partner for <br />Insurance Solutions and Financial Success!</p>
            </div>
        </div>

    </div>
</section>

<section className='contct-below'>
    <div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='ctabnr'>
         <span className='ctabnrimg'>
         <img src={gal11} alt="" className="support1" />
        </span>
       <span className='ctabnrone'>Chat with an agent? Call now: </span>
        <span className='ctabnrtwo'><a href='tel:+1 737 333 1133'>+1 737 333 1133</a></span>
        <img src={gal11} className='newagent' alt='' />
        </div>
      
    </div>
   
    </div>
    </div>

</section>

<section className='myblogs'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <BlogGrid />
      </div>
        </div>
        </div>
</section>

 <section className='last-cta' id='contactus'>
      <div className='container'>
        <div className='row'>
          <div className='col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-12'>
            <div className='cts-a-box'>
            <div className='custsupport'>
              <img src={gal11} className='customersupport' alt='' />
            </div>
            <div className='insurance-agents'>
              <h2>Still need help?</h2>
              <p>Get your life insurance quote online or call one of our agents at <strong><a href='tel:1-800-123-4567'>1-800-123-4567</a></strong></p>
              </div>
            </div>
          </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12'>
          <div class="btn-wrapper animated go-top"><a class="theme-btn-1 btn btn-effect-1" href="#/service">OUR SERVICES</a></div>
        </div>
        </div>
      </div>
    </section>   */}

<Footer_v1 />
</>
)
}

export default Blog

