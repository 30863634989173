import React from 'react';
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../global-components/navbar-v4';

import faqbanner from "../../images/banners/faqbanner.jpg"
import faqmobile from "../../images/banners/faqmobile.jpg"


const Faq = () => {
    return (
<>
<NavbarV4 CustomClass="ltn__header-transparent gradient-color-2"  />
<Helmet>
<title>FAQ'S - Bcurri Apparel</title>
<meta name="description" content="" />
</Helmet>


 {/* Start Slider area  */}
{/* <section className='aboutbnr'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='Sports-bcurri'>
                <h1>Bcurri</h1>
                <h2 className='Specialist'> FAQ</h2>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={womenteam} className='abhtbnrpick' alt='' />
            </div>
        </div>
    </div>
</section> */}


<picture>
  <source srcset={faqmobile} media="(max-width: 660px)" />
  <source srcset={faqbanner} />
  <img src={faqbanner} alt="" className='ban' />
</picture>



<section className='bcurri-faqs' id='faqs'>
        <div className='container'>
<div className='row text-center'>
    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <h2 className='increasingly'>Frequently Asked Questions?</h2>
        
    </div>
    
</div>
   <div className='row' id='forfaq'>
   <div className='col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-12'>
   <div className="accordion" id="myAccordion">
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
        >
      How can I place an order for custom sportswear?
        </button>
      </h2>
      <div
        id="collapseOne"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          You can easily place an order for a customized sports jersey by visiting our website. Select your jersey style, color, and size. Once you’re done, send us an email. For more information, you can visit the <a href="/How-to-order">“how to order” </a> section. 
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwo">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
        >
 What materials do you use for your sports apparel?
        </button>
      </h2>
      <div
        id="collapseTwo"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>For our sports jerseys, we use high-quality, breathable and durable materials to ensure great comfort and durability. By utilizing premium quality fabrics, we make sure that our jerseys look great as well as allow full range of motion to players while performing sports activities. </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThree">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
        >
 What is the minimum and maximum number of jerseys we can order?
        </button>
      </h2>
      <div
        id="collapseThree"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          The minimum order quantity for jerseys is 10 pieces. If your jersey order falls below this quantity, surcharges will apply. There is no maximum limit. You can order as many jerseys as you want. 
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFour">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFour"
        >
Can I customize the jersey design according to my preferences?
        </button>
      </h2>
      <div
        id="collapseFour"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>Yes, absolutely. You can provide us with your team’s logo or choose from a wide palette of colors to match your team’s unique identity. </p>
        </div>
      </div>
    </div>
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingFive">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFive"
        >
 How long does it take to receive the order?
        </button>
      </h2>
      <div
        id="collapseFive"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
     <p>The standard processing time for orders is 4-6 weeks after confirmation. If you have an urgent order, please <b><a href="contact-us">contact us </a></b>to discuss. </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSix">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSix"
        >
Do you offer discounts on your services?
        </button>
      </h2>
      <div
        id="collapseSix"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Yes, we do offer a discount of 15% on first orders. Also, we have special offers for schools and universities.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSeven">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSeven"
        >
 What types of sportswear do you offer designing & printing services?
        </button>
      </h2>
      <div
        id="collapseSeven"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>We offer a wide range of sportswear services, including custom jersey decoration, embroidery, heat transfer, screen printing and sublimation. </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingEight">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseEight"
        >
 How does your company prioritize sustainability and eco-friendliness in manufacturing sportswear?
        </button>
      </h2>
      <div
        id="collapseEight"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Our company is dedicated to a sustainable and eco-friendly approach to manufacturing sportswear. We prioritize the use of environmentally friendly materials that are not only better for the environment but also provide high-quality performance for athletes.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingNine">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseNine"
        >
Can I reorder sportswear with the same design in the future?
        </button>
      </h2>
      <div
        id="collapseNine"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>Yes, of course. You can easily reorder sportswear with the same design whenever you need to. </p>
        </div>
      </div>
    </div>
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingTen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTen"
        >
What is your return policy if I am not satisfied with the custom-designed jerseys? 
        </button>
      </h2>
      <div
        id="collapseTen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
     <p>Customer satisfaction is our topmost priority. If you are not happy with the quality or customization of your jerseys, please contact our customer support team within 14 days of receiving the order. We will work to resolve the issue, which may include a refund or re-production of the jerseys.</p>
        </div>
      </div>
    </div>
    
  </div>
  </div>


  {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
   <div className="accordion" id="myAccordion">
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSix">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSix"
        >
Do you offer discounts on your services?
        </button>
      </h2>
      <div
        id="collapseSix"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Yes, we do offer a discount of 15% on first orders. Also, we have special offers for schools and universities.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSeven">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSeven"
        >
 What types of sportswear do you offer designing & printing services?
        </button>
      </h2>
      <div
        id="collapseSeven"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>We offer customization for a wide variety of sportswear apparel, including jerseys, shorts, jackets, t-shirts, caps and more. Whether it's basketball, football, soccer, rugby or any other sport, we have got you covered. </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingEight">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseEight"
        >
 How does your company prioritize sustainability and eco-friendliness in manufacturing sportswear?
        </button>
      </h2>
      <div
        id="collapseEight"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Our company is dedicated to a sustainable and eco-friendly approach to manufacturing sportswear. We prioritize the use of environmentally friendly materials that are not only better for the environment but also provide high-quality performance for athletes.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingNine">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseNine"
        >
Can I reorder sportswear with the same design in the future?
        </button>
      </h2>
      <div
        id="collapseNine"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>Yes, of course. You can easily reorder sportswear with the same design whenever you need to. </p>
        </div>
      </div>
    </div>
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingTen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTen"
        >
What is your return policy if I am not satisfied with the custom-designed jerseys? 
        </button>
      </h2>
      <div
        id="collapseTen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
     <p>Customer satisfaction is our topmost priority. If you are not happy with the quality or customization of your jerseys, please contact our customer support team within 14 days of receiving the order. We will work to resolve the issue, which may include a refund or re-production of the jerseys.</p>
        </div>
      </div>
    </div>
    
  </div>
  </div> */}
            </div>
        </div>
    </section>  


       
<Footer_v1 />
</>
)
}

export default Faq

