import { useState, useEffect } from "react";
import $ from 'jquery';

import polo1 from "../images/sports/polo1.png";
import polo2 from "../images/sports/polo2.png";
import polo3 from "../images/sports/polo3.png";

import tee1 from "../images/sports/tee1.png";
import tee2 from "../images/sports/tee2.png";
import tee3 from "../images/sports/tee3.png";
import tee4 from "../images/sports/tee4.png";

import hooded1 from "../images/sports/hooded1.png";
import hooded2 from "../images/sports/hooded2.png";
import hooded3 from "../images/sports/hooded3.png";

import rjersey1 from "../images/sports/rjersey1.png";
import rjersey2 from "../images/sports/rjersey2.png";
import rjersey3 from "../images/sports/rjersey3.png";
import rjersey4 from "../images/sports/rjersey4.png";
import rjersey5 from "../images/sports/rjersey5.png";
import rjersey6 from "../images/sports/rjersey6.png";
import rjersey7 from "../images/sports/rjersey7.png";
import rjersey8 from "../images/sports/rjersey8.png";

import design1 from "../images/sports/design1.png";
import design2 from "../images/sports/design2.png";
import design3 from "../images/sports/design3.png";
import design4 from "../images/sports/design4.png";
import design5 from "../images/sports/design5.png";
import design6 from "../images/sports/design6.png";
import design7 from "../images/sports/design7.png";


import fit1 from "../images/sports/fit1.png";
import fit2 from "../images/sports/fit2.png";
import fit3 from "../images/sports/fit3.png";

import sleeve1 from "../images/sports/sleeve1.png";
import sleeve2 from "../images/sports/sleeve2.png";
import sleeve3 from "../images/sports/sleeve3.png";
import sleeve4 from "../images/sports/sleeve4.png";


import ladieshock1 from "../images/sports/ladieshock1.png";
import ladieshock2 from "../images/sports/ladieshock2.png";
import ladieshock3 from "../images/sports/ladieshock3.png";
import ladieshock4 from "../images/sports/ladieshock4.png";


import menhock1 from "../images/sports/menhock1.png";
import menhock2 from "../images/sports/menhock2.png";
import menhock3 from "../images/sports/menhock3.png";

import color1 from "../images/sports/color1.png";
import color2 from "../images/sports/color2.png";
import color3 from "../images/sports/color3.png";
import color4 from "../images/sports/color4.png";
import color5 from "../images/sports/color5.png";
import color6 from "../images/sports/color6.png";
import color7 from "../images/sports/color7.png";
import color8 from "../images/sports/color8.png";
import color9 from "../images/sports/color9.png";
import color10 from "../images/sports/color10.png";
import color11 from "../images/sports/color11.png";
import color12 from "../images/sports/color12.png";
import color13 from "../images/sports/color13.png";
import color14 from "../images/sports/color14.png";
import color15 from "../images/sports/color15.png";
import color16 from "../images/sports/color16.png";
import color17 from "../images/sports/color17.png";
import color18 from "../images/sports/color18.png";


import subcolor1 from "../images/sports/subcolor1.png";
import subcolor2 from "../images/sports/subcolor2.png";
import subcolor3 from "../images/sports/subcolor3.png";
import subcolor4 from "../images/sports/subcolor4.png";
import subcolor5 from "../images/sports/subcolor5.png";
import subcolor6 from "../images/sports/subcolor6.png";
import subcolor7 from "../images/sports/subcolor7.png";
import subcolor8 from "../images/sports/subcolor8.png";
import subcolor9 from "../images/sports/subcolor9.png";


import decoration from "../images/sports/decoration.png";

import Selectsize from './section-components/mensize';

import Subtab from "./subtab";
import ColorPicker from "./ColorPicker";


const Multistep = () => {
   
const [currentStep, setCurrentStep] = useState(1);
// const [isRadioButtonSelected, setIsRadioButtonSelected] = useState(false);
// const [isRadioButtonSelected1, setIsRadioButtonSelected1] = useState(false);
// const [isRadioButtonSelected2, setIsRadioButtonSelected2] = useState(false);
// const [isRadioButtonSelected3, setIsRadioButtonSelected3] = useState(false);
// const [isRadioButtonSelected4, setIsRadioButtonSelected4] = useState(false);
// const [isRadioButtonSelected5, setIsRadioButtonSelected5] = useState(false);
const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    };
const handlePrevious = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    };
const [email, setEmail] = useState("");
const [isEmailValid, setIsEmailValid] = useState(false);

// Validation function for email
const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
};

    useEffect(() => {
        const tabJs = (listContainer, listItems, listTabs, contentTab, contains) => {
            let tabs = Array.prototype.slice.apply(document.querySelectorAll(listTabs));
            let items = Array.prototype.slice.apply(document.querySelectorAll(listItems));
            let panels = Array.prototype.slice.apply(document.querySelectorAll(contentTab));
    
            document.getElementById(listContainer).addEventListener('click', e => {
                if (e.target.classList.contains(contains)) {
                    let i = tabs.indexOf(e.target)
                    tabs.map(tab => tab.classList.remove('active'))
                    tabs[i].classList.add('active')
                    items.map(item => item.classList.remove('gds-list-tabs__list-item--active'))
                    items[i].classList.add('gds-list-tabs__list-item--active')
                    panels.map(tab => tab.classList.remove('active'))
                    panels[i].classList.add('active')
                }
            })
        }
        tabJs('tabContainer', '.gds-list-tabs__list-item ', '.itm', '.tabs', 'itm');
        
        }, []);
    return <div>



<section class="newhowto"> 
      <div className="container-fluid">
        <div className="row justify-content-center">
            <div className="col-xxl-11 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
                <h2 className='howto'>How To Order</h2>
                <form
                id="msform"
                action="sportsform.php"
                method="post"
                encType="multipart/form-data"
                >
                {/* progressbar */}
                <list class="">
                    <ul id="tabContainer" class="gds-list-tabs gds-list-tabs--justified" data-gds-list="2">
                        <li class="gds-list-tabs__list-item gds-list-tabs__list-item--active" data-gds-list-item="">
                        <a class="itm active gds-list-tabs__link" data-gds-list-button="1" data-gds-list-type="tab">Step-1</a><br/>
                        <span class="selector">  Design</span>
                        </li>
                        <li class="gds-list-tabs__list-item">
                        <a class="itm gds-list-tabs__link">Step-2</a><br/>
                        <span class="selector">  Color</span>
                        </li>
                        <li class="gds-list-tabs__list-item">
                        <a class="itm gds-list-tabs__link">Step-3</a><br/>
                        <span class="selector"> Collar</span>
                        </li>
                        <li class="gds-list-tabs__list-item">
                        <a class="itm gds-list-tabs__link">Step-4</a><br/>
                        <span class="selector">  Sleeves</span>
                        </li>

                        <li class="gds-list-tabs__list-item">
                        <a class="itm gds-list-tabs__link">Step-5</a><br/>
                        <span class="selector"> Fit</span>
                        </li>
                        <li class="gds-list-tabs__list-item">
                        <a class="itm gds-list-tabs__link">Step-6</a><br/>
                        <span class="selector"> Decorations</span>
                        </li>
                        <li class="gds-list-tabs__list-item">
                        <a class="itm gds-list-tabs__link">Step-7</a><br/>
                        <span class="selector"> Size</span>
                        </li>
                        {/* <li class="gds-list-tabs__list-item">
                        <a class="itm gds-list-tabs__link">Step-7</a><br/>
                        <span class="selector">Email Us</span>
                        </li> */}
                    </ul>
                    </list>

                 
                {/* <ul class="tab-area">
                    <li class="howli active">Step 1  <h4 class="stepp">Select Design</h4></li>
                    <li class="howli">Step 2  <h4 class="stepp">Select Color </h4></li>
                    <li class="howli">Step 3  <h4 class="stepp">Select Collar</h4></li>
                    <li class="howli">Step 4  <h4 class="stepp">Select Sleeves</h4></li>
                    <li class="howli">Step 5  <h4 class="stepp">Select Fit</h4></li>
                    <li class="howli">Step 6  <h4 class="stepp">Select Size</h4></li>
                    <li class="howli">Step 7  <h4 class="stepp">Email Us</h4></li>
                </ul> */}

                {/* <div className="progress">
                    <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuemin={0}
                    aria-valuemax={100}
                    />
                </div>{" "} */}
                {/* fieldsets */}

                <section class="container_tabs gds-card">
{/* Design */}                  
                    <div class="tabs active">
                <fieldset>
                    <div className="form-card">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
                        <h3 class="seldesign animated fadeIn"> Select Design</h3>
                        <p class="designpara mx-auto">At Bcurri Apparel, we believe every athlete is unique, and his jersey design should reflect his individuality. Therefore, we offer a wide range of pre-designed jersey templates to cater to the different preferences of our customers. Our collection boasts a diverse range of designs from classic to modern and contemporary patterns. Whether you're into football, basketball, rugby or any sport, we have your every need covered. So what are you waiting for? Browse our curated selection of ready-made design templates and pick the design that inspires you or your team. </p>
                        </div>
                        {/* <div className="col-5">
                        <h2 className="steps">Step 1 - 8</h2>
                        </div>
                        <div className='radiogroup'>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="footbal" name="sport" value="footbal" />
                              <img src={design1} className="" alt="" />
                              </span> 
                              <p class="align">Design 1</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="footbal" name="sport" value="cricket" />
                              <img src={design2} className="" alt="" />
                              </span> 
                              <p class="align">Design 2</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="footbal" name="sport" value="netball" />
                              <img src={design3} className="" alt="" />
                              </span> 
                              <p class="align">Design 3</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="footbal" name="sport" value="netball" />
                              <img src={design4} className="" alt="" />
                              </span> 
                              <p class="align">Design 4</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                            <span>
                            <input  type="radio" id="footbal" name="sport" value="hockey" />
                            <img src={design5} className="" alt="" />
                            </span> 
                            <p class="align">Design 5</p>
                            <br />
                            </label>
                            <label class="radio-inline">
                            <span>
                            <input  type="radio" id="footbal" name="sport" value="hockey" />
                            <img src={design6} className="" alt="" />
                            </span> 
                            <p class="align">Design 6</p>
                            <br />
                            </label>
                        </div> */}
                    </div>
                    </div>
                </fieldset>
                </div>

{/* Color */}
                <div class="tabs">
                <fieldset>
                    <div className="form-card">
                    <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
                        <h3 class="seldesign animated fadeIn"> Standard Colors</h3>
                        <p class="designpara mx-auto">Done with selecting your jersey design? Now, choose your desired colors. From classic team colors to bold, eye-catching choices, we have endless possibilities for you.</p>
                        </div>
                        <div className="col-5">
                        <h2 className="steps">Step 2 - 8</h2>
                        </div>
                        <div className='radiogroup'>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color1} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Black</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color2} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Black</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color3} className="black" alt="" />
                              </span> 
                              {/* <p class="align">White</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color4} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Emerald Green</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color5} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Purple</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color6} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Pink</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color7} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Hot Pink</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color8} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Sky Blue</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color9} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Orange</p> */}
                              <br />
                           </label>
                        </div>

                        <div className='radiogroup'>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color10} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Brown</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color11} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Bottle Green</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color12} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Grey</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color13} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Canary Yellow</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color14} className="black" alt="" />
                              </span> 
                              {/* <p class="align">White</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color15} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Red</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color16} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Royal Blue</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color17} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Amber Yellow</p> */}
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color18} className="black" alt="" />
                              </span> 
                              {/* <p class="align">Amber Yellow</p> */}
                              <br />
                           </label>
                        </div>

                    </div>
<br/>
                    <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
                        <h3 class="seldesign animated fadeIn"> Sublimation Colors</h3>
                        <p class="designpara mx-auto">The colors may vary slightly due to fabric material and temperature fluctuations during the sublimation process. Choose your favourite color</p>
                        </div>
                        <div className="col-12">
                        <h2 className="steps">Step 2 - 8</h2>
                        </div>

    <div className="App">
      <ColorPicker />
    </div>

                        {/* <div className='radiogroup'>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color10} className="black" alt="" />
                              </span> 
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color11} className="black" alt="" />
                              </span> 
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color12} className="black" alt="" />
                              </span> 
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color13} className="black" alt="" />
                              </span> 
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color14} className="black" alt="" />
                              </span> 
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color15} className="black" alt="" />
                              </span> 
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color16} className="black" alt="" />
                              </span> 
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color17} className="black" alt="" />
                              </span> 
                              <br />
                           </label>
                           <label class="radio-inline2">
                              <span>
                              <input  type="radio" id="black" name="colour" value="black"  />
                              <img src={color18} className="black" alt="" />
                              </span> 
                              <br />
                           </label>
                        </div> */}

                    </div>
                    </div>
                </fieldset>
                </div>


{/* Collar */}
                <div class="tabs">
                <fieldset>
                    <div className="form-card">
                    <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
                        {/* <h3 class="seldesign animated fadeIn">Select Collar</h3>
                        <p class="designpara mx-auto">From V-neck collar to crew neck, henley or polo collar, you can pick a collar style that is ideal for you. </p> */}
                        </div>
                        <div className="col-5">
                        <h2 className="steps">Step 3 - 8</h2>
                        </div>

                        <h4 className="subtitle-how ">Rugby Jersey Collar</h4>
                            <br/><br/>
                            <div className='radiogroup'>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="hockey" name="collar" value="hockey" /> */}
                                <img src={tee1} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Round Neck Collar</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={tee2} className="" alt="" />
                                </span> 
                                <p class="aligncollar">V-Neck Collar</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={tee3} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Cross Collar</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={rjersey4} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Pro Collar</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={rjersey5} className="" alt="" />
                                </span> 
                                <p class="aligncollar">South African Collar</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={rjersey6} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Granded Collar</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={rjersey7} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Standard Collar</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={rjersey8} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Y-Collar</p>
                                <br />
                            </label>
                            </div>

                        <h4 className="subtitle-how ">Ladies Hockey/Netball Collar</h4>
                            <div className='radiogroup'>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="hockey" name="collar" value="hockey" /> */}
                                <img src={ladieshock1} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Self Collar with Stripe Placket</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={ladieshock2} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Collar with V- Placket</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="netball" name="collar" value="netball" /> */}
                                <img src={ladieshock3} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Collar with 3 Button Placket</p>
                                <br />
                            </label>
                            {/* <label class="radio-inline">
                                <span>
                                <input  type="radio" id="netball" name="collar" value="netball" />
                                <img src={ladieshock4} className="" alt="" />
                                </span> 
                                <p class="aligncollar">T-shirt Collar</p>
                                <br />
                            </label> */}
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="hockey" name="collar" value="hockey" /> */}
                                <img src={tee1} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Round Neck Collar</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={tee2} className="" alt="" />
                                </span> 
                                <p class="aligncollar">V-Neck Collar</p>
                                <br />
                            </label>
                            </div>

                        <h4 className="subtitle-how ">Men’s Hockey Shirts Collar</h4>
                            <div className='radiogroup'>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="hockey" name="collar" value="hockey" /> */}
                                <img src={menhock1} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Collar with V- Placket</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={menhock2} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Collar with 3 Button Placket</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={menhock3} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Pro Collar</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="hockey" name="collar" value="hockey" /> */}
                                <img src={tee1} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Round Neck Collar</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={tee2} className="" alt="" />
                                </span> 
                                <p class="aligncollar">V-Neck Collar</p>
                                <br />
                            </label>
                            </div>
                            <h4 className="subtitle-how ">Hooded Sweatshirts Neck</h4>
                            <div className='radiogroup'>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="hockey" name="collar" value="hockey" /> */}
                                <img src={hooded1} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Pullover No Zip</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={hooded2} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Pullover Full Zip</p>
                                <br />
                            </label>
                            </div>
                            <h4 class="subtitle-how ">Polo Shirts Collar</h4>
                            <div className='radiogroup'>
                                <label class="radio-inline">
                                    <span>
                                    {/* <input  type="radio" id="footbal" name="collar" value="footbal"  /> */}
                                    <img src={polo1} className="" alt="" />
                                    </span> 
                                    <p class="aligncollar">Polo Collar</p>
                                    <br />
                                </label>
                                {/* <label class="radio-inline">
                                    <span>
                                    <input  type="radio" id="cricket" name="collar" value="cricket" />
                                    <img src={polo2} className="" alt="" />
                                    </span> 
                                    <p class="aligncollar">RV Collar</p>
                                    <br />
                                </label> */}
                            </div>
                        <h4 className="subtitle-how ">T-Shirts Collar</h4>
                            <div className='radiogroup'>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="hockey" name="collar" value="hockey" /> */}
                                <img src={tee1} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Round Neck Collar</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="rubgy" name="collar" value="rubgy" /> */}
                                <img src={tee2} className="" alt="" />
                                </span> 
                                <p class="aligncollar">V-Neck Collar</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                {/* <input  type="radio" id="netball" name="collar" value="netball" /> */}
                                <img src={tee3} className="" alt="" />
                                </span> 
                                <p class="aligncollar">Cross Collar</p>
                                <br />
                            </label>
                            </div>


                        </div>

                     {/* <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
                        <h3 class="seldesign animated fadeIn">Select Collar</h3>
                        <p class="designpara mx-auto"> From V-neck collar to crew neck, henley or polo collar, you can pick a collar style that is ideal for you. </p>
                        </div>
                        <div className="col-5">
                        <h2 className="steps">Step 3 - 8</h2>
                        </div>
                        <div className='radiogroup'>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="footbal" name="sport" value="footbal" />
                              <img src={artboard1} className="" alt="" />
                              </span> 
                              <p class="align">Rugby Jersey Collar</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="footbal" name="sport" value="cricket" />
                              <img src={Artboard5} className="" alt="" />
                              </span> 
                              <p class="align">Polo Shirts Collar</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="footbal" name="sport" value="netball" />
                              <img src={Artboard43} className="" alt="" />
                              </span> 
                              <p class="align">T-Shirts Collar</p>
                              <br />
                           </label>
                           <label class="radio-inline">
                              <span>
                              <input  type="radio" id="footbal" name="sport" value="netball" />
                              <img src={Artboard44} className="" alt="" />
                              </span> 
                              <p class="align">Hooded Sweatshirt Neck</p>
                              <br />
                           </label>

                        </div>
                        
                    </div> */}

                    </div>
                </fieldset>
                </div>


{/* Sleeves */}
                <div class="tabs">
                <fieldset>
                    <div className="form-card">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
                        <h3 class="seldesign animated fadeIn">Select Sleeves</h3>
                        <p class="designpara mx-auto">Whether you prefer classic short sleeves for easy movement or sporty long sleeves for better coverage, we have got you covered.</p>
                        </div>
                        <div className="col-5">
                        <h2 className="steps">Step 4 - 8</h2>
                        </div>
                        <div classname="row">
                        <div classname="col-6">
                            <div className='radiogroup'>
                            <label class="radio-inline">
                                 <span>
                                 {/* <input  type="radio" id="footbal" name="sleeves" value="footbal"  /> */}
                                 <img src={sleeve1} className="" alt="" />
                                 </span> 
                                 <p class="align">Long Sleeves</p>
                                 <br />
                              </label>
                              <label class="radio-inline">
                                 <span>
                                 {/* <input  type="radio" id="footbal" name="sleeves" value="footbal"  /> */}
                                 <img src={sleeve2} className="" alt="" />
                                 </span> 
                                 <p class="align">Short Sleeves</p>
                                 <br />
                              </label>
                              <label class="radio-inline">
                                 <span>
                                 {/* <input  type="radio" id="cricket" name="sleeves" value="cricket" /> */}
                                 <img src={sleeve3} className="" alt="" />
                                 </span> 
                                 <p class="align">Sleeveless</p>
                                 <br />
                              </label>
                              <label class="radio-inline">
                                 <span>
                                 {/* <input  type="radio" id="netball" name="sleeves" value="netball" /> */}
                                 <img src={sleeve4} className="" alt="" />
                                 </span> 
                                 <p class="align">Capp Sleeves</p>
                                 <br />
                              </label>
                           </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </fieldset>
                </div>

{/* Fit */}
                <div class="tabs">
                <fieldset>
                    <div className="form-card">
                    <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
                        <h3 class="seldesign animated fadeIn">Select Fit</h3>
                        <p class="designpara mx-auto">Our collection offers a variety of fits to suit every body type and style preference. Choose your ideal jersey fit that meets your needs. 
 </p>
                        </div>
                        <div className="col-5">
                        <h2 className="steps">Step 5 - 8</h2>
                        </div>
                        <div classname="row">
                        <div classname="col-6">
                            <div className='radiogroup'>
                            <label class="radio-inline">
                                <span>
                                <input  type="radio" id="footbal" name="fitting" value="footbal"  />
                                <img src={fit3} className="" alt="" />
                                </span> 
                                <p class="align">Ladies Fit</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                <input  type="radio" id="footbal" name="fitting" value="cricket" />
                                <img src={fit2} className="" alt="" />
                                </span> 
                                <p class="align">Men’s Fit</p>
                                <br />
                            </label>
                            <label class="radio-inline">
                                <span>
                                <input  type="radio" id="footbal" name="fitting" value="netball"  />
                                <img src={fit1} className="" alt="" />
                                </span> 
                                <p class="align">Tight Fit</p>
                                <br />
                            </label>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </fieldset>
                </div>

{/* Decorations */}
                <div class="tabs">
                <fieldset>
                    <div className="form-card">
                    <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
                        <h3 class="seldesign animated fadeIn"> Select Decorations</h3>
                        <p class="designpara mx-auto">Personalize your jersey in your own unique style. </p>
                    </div>

                    <label class="radio-inline1">
                            <span>
                            <input  type="radio" id="footbal" name="fitting" value="footbal"  />
                            <img src={decoration} className="decoimg" alt="" />
                            </span> <br />
                    <ol className="deco">
                        <li>Our thoughtful logo placement ensures that your <b>sponsor’s logo</b> stands out boldly. </li>
                        <li>You can choose your preferred font for <b>number</b> and <b>name</b>.</li>
                        <li>Our playing kits are created using the sublimation technique for better comfort and performance. You can add as many <b>colors</b> and logos as you want.</li>
                        <li>We offer a wide range of <b>customization services</b>, including <b>embroidery</b>, <b>screen printing</b> and <b>heat transfer</b>. </li>
                    </ol>
                                <br />
                    </label>

                    <div className="row">
                    {/* <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
                            <h2 className="steps">Step 6 - 8</h2>
                            <img src={decoration} className='workwr' alt='' />     
                    </div>
                    <ul className="deco">
                        <li>Our thoughtful logo placement ensures that your sponsor’s logo stands out boldly. </li>
                        <li>You can choose your preferred font for number and name.</li>
                        <li>Our playing kits are created using the sublimation technique for better comfort and performance. You can add as many colors and logos as you want.</li>
                        <li>We offer a wide range of customization services, including embroidery, screen printing and heat transfer. </li>
                    </ul> */}

                    </div>
                    
                    </div>
                    </div>
                </fieldset>
                </div>

{/* Size */}
                <div class="tabs">
                <fieldset>
                    <div className="form-card">
                    <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
                        <h3 class="seldesign animated fadeIn"> Select Size Guide</h3>
                        <p class="designpara mx-auto">Pick up the right size for your comfort and performance. We offer extensive options to cater to your specific needs. </p>
                        </div>
                        <div className="col-5">
                        <h2 className="steps">Step 7 - 8</h2>
                        </div>

                      <Subtab />
                            
                    </div>
                    </div>
                </fieldset>
                </div>

{/* Email */}
                <div class="tabs">
                <fieldset>
                    <div className="form-card">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
                        <h3 class="seldesign animated fadeIn"> Email Us</h3>
                        <p class="designpara mx-auto">Kindly send us an email specifying your requirements. This will help us to tailor our offerings to match your specific requirements. </p>
                        </div>
                        <div className="col-5">
                        <h2 className="steps">Step 7 - 8</h2>
                        </div>
                    </div>
                    <label className="fieldlabels">Email: *</label>
                    <input type="email" name="email" class="reg-input" value={email} onChange={(e) => { const newEmail = e.target.value;setEmail(newEmail); setIsEmailValid(validateEmail(newEmail));}} required />
                        {!isEmailValid && (
                        <p className="error-text">Please enter a valid email address.</p>
                        )}
                    </div>
                    <input type="submit" name="next" className="next action-button" defaultValue="Submit" disabled={!isEmailValid} />
                    <input
                    type="button"
                    name="previous"
                    className="previous action-button-previous"
                    defaultValue="Previous"
                    />
                </fieldset>
                </div>


                <div class="tabs">
                <fieldset>
                    <div className="form-card">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
                        <h3 class="seldesign animated fadeIn">Finish:</h3>
                        </div>
                        <div className="col-5">
                        <h2 className="steps">Step 8 - 8</h2>
                        </div>
                    </div>{" "}
                    <br />
                    <br />
                    <h2 className="purple-text text-center">
                        <strong>SUCCESS !</strong>
                    </h2>{" "}
                    <br />
                    <div className="row justify-content-center">
                        <div className="col-3">
                        {" "}
                        <img
                            src="https://i.imgur.com/GwStPmg.png"
                            className="fit-image"
                        />{" "}
                        </div>
                    </div>{" "}
                    <br />
                    <br />
                    <div className="row justify-content-center">
                        <div className="col-7 text-center">
                        <h5 className="purple-text text-center">
                            Your request has been submitted
                        </h5>
                        </div>
                    </div>
                    </div>
                </fieldset>
                </div>
                </section>
                </form>
            </div>
            </div>
        </div>
      </div>
</section>

    </div>
}

export default Multistep

