import React from 'react';
import { Helmet } from 'react-helmet';

import menshorts1front from "../../../images/sports/products/mens-hockey-shorts/1/menshorts1front.png";
import menshorts1back from "../../../images/sports/products/mens-hockey-shorts/1/menshorts1back.png";
import menshorts2front from "../../../images/sports/products/mens-hockey-shorts/2/menshorts2front.png";
import menshorts2back from "../../../images/sports/products/mens-hockey-shorts/2/menshorts2back.png";
import menshorts3front from "../../../images/sports/products/mens-hockey-shorts/3/menshorts3front.png";
import menshorts3back from "../../../images/sports/products/mens-hockey-shorts/3/menshorts3back.png";
import menshorts4front from "../../../images/sports/products/mens-hockey-shorts/4/menshorts4front.png";
import menshorts4back from "../../../images/sports/products/mens-hockey-shorts/4/menshorts4back.png";
import menshorts5front from "../../../images/sports/products/mens-hockey-shorts/5/menshorts5front.png";
import menshorts5back from "../../../images/sports/products/mens-hockey-shorts/5/menshorts5back.png";
import menshorts6front from "../../../images/sports/products/mens-hockey-shorts/6/menshorts6front.png";
import menshorts6back from "../../../images/sports/products/mens-hockey-shorts/6/menshorts6back.png";
import menshorts7front from "../../../images/sports/products/mens-hockey-shorts/7/menshorts7front.png";
import menshorts7back from "../../../images/sports/products/mens-hockey-shorts/7/menshorts7back.png";
import menshorts8front from "../../../images/sports/products/mens-hockey-shorts/8/menshorts8front.png";
import menshorts8back from "../../../images/sports/products/mens-hockey-shorts/8/menshorts8back.png";
import menshorts9front from "../../../images/sports/products/mens-hockey-shorts/9/menshorts9front.png";
import menshorts9back from "../../../images/sports/products/mens-hockey-shorts/9/menshorts9back.png";
import menshorts10front from "../../../images/sports/products/mens-hockey-shorts/10/menshorts10front.png";
import menshorts10back from "../../../images/sports/products/mens-hockey-shorts/10/menshorts10back.png";
import menshorts11front from "../../../images/sports/products/mens-hockey-shorts/11/menshorts11front.png";
import menshorts11back from "../../../images/sports/products/mens-hockey-shorts/11/menshorts11back.png";
import menshorts12front from "../../../images/sports/products/mens-hockey-shorts/12/menshorts12front.png";
import menshorts12back from "../../../images/sports/products/mens-hockey-shorts/12/menshorts12back.png";
import menshorts13front from "../../../images/sports/products/mens-hockey-shorts/13/menshorts13front.png";
import menshorts13back from "../../../images/sports/products/mens-hockey-shorts/13/menshorts13back.png";
import menshorts14front from "../../../images/sports/products/mens-hockey-shorts/14/menshorts14front.png";
import menshorts14back from "../../../images/sports/products/mens-hockey-shorts/14/menshorts14back.png";
import menshorts15front from "../../../images/sports/products/mens-hockey-shorts/15/menshorts15front.png";
import menshorts15back from "../../../images/sports/products/mens-hockey-shorts/15/menshorts15back.png";
import menshorts16front from "../../../images/sports/products/mens-hockey-shorts/16/menshorts16front.png";
import menshorts16back from "../../../images/sports/products/mens-hockey-shorts/16/menshorts16back.png";
import menshorts17front from "../../../images/sports/products/mens-hockey-shorts/17/menshorts17front.png";
import menshorts17back from "../../../images/sports/products/mens-hockey-shorts/17/menshorts17back.png";
import menshorts18front from "../../../images/sports/products/mens-hockey-shorts/18/menshorts18front.png";
import menshorts18back from "../../../images/sports/products/mens-hockey-shorts/18/menshorts18back.png";
import menshorts19front from "../../../images/sports/products/mens-hockey-shorts/19/menshorts19front.png";
import menshorts19back from "../../../images/sports/products/mens-hockey-shorts/19/menshorts19back.png";
import menshorts20front from "../../../images/sports/products/mens-hockey-shorts/20/menshorts20front.png";
import menshorts20back from "../../../images/sports/products/mens-hockey-shorts/20/menshorts20back.png";
import menshorts21front from "../../../images/sports/products/mens-hockey-shorts/21/menshorts21front.png";
import menshorts21back from "../../../images/sports/products/mens-hockey-shorts/21/menshorts21back.png";
import menshorts22front from "../../../images/sports/products/mens-hockey-shorts/22/menshorts22front.png";
import menshorts22back from "../../../images/sports/products/mens-hockey-shorts/22/menshorts22back.png";
import menshorts23front from "../../../images/sports/products/mens-hockey-shorts/23/menshorts23front.png";
import menshorts23back from "../../../images/sports/products/mens-hockey-shorts/23/menshorts23back.png";
import menshorts24front from "../../../images/sports/products/mens-hockey-shorts/24/menshorts24front.png";
import menshorts24back from "../../../images/sports/products/mens-hockey-shorts/24/menshorts24back.png";


const HockeyShortssport = () => {
    return (
<>

<Helmet>
<title>Men's Hockey Shorts - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

 {/* Start Slider area  */}
<section className='cloth-rugby' id='Hockey-Shirts'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Men's Hockey Shorts</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>


     {/* Row 1 */}
     <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts1" className="image">
        <img src={menshorts1front} className="pic-1" alt='' />
        <img src={menshorts1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Mens-Hockey-Shorts1">Men's Hockey Shorts 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts2" className="image">     
          <img src={menshorts2front} className="pic-1" alt='' />
          <img src={menshorts2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts1">Men's Hockey Shorts 2</a>
     </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
      <a href="/Mens-Hockey-Shorts3" className="image">     
          <img src={menshorts3front} className="pic-1" alt='' />
          <img src={menshorts3back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts3">Men's Hockey Shorts 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
      <a href="/Mens-Hockey-Shorts4" className="image">     
          <img src={menshorts4front} className="pic-1" alt='' />
          <img src={menshorts4back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts4">Men's Hockey Shorts 4</a>
        </h3>
  </div>
 
   </div>

  <br/>
     {/* Row 2 */}
     <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts5" className="image">
        <img src={menshorts5front} className="pic-1" alt='' />
        <img src={menshorts5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Mens-Hockey-Shorts5">Men's Hockey Shorts 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts6" className="image">     
          <img src={menshorts6front} className="pic-1" alt='' />
          <img src={menshorts6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts6">Men's Hockey Shorts 6</a>
     </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
      <a href="/Mens-Hockey-Shorts7" className="image">     
          <img src={menshorts7front} className="pic-1" alt='' />
          <img src={menshorts7back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts7">Men's Hockey Shorts 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
      <a href="/Mens-Hockey-Shorts8" className="image">     
          <img src={menshorts8front} className="pic-1" alt='' />
          <img src={menshorts8back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts8">Men's Hockey Shorts 8</a>
        </h3>
  </div>
 
   </div>

   <br/>
     {/* Row 3 */}
     <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts9" className="image">
        <img src={menshorts9front} className="pic-1" alt='' />
        <img src={menshorts9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Mens-Hockey-Shorts9">Men's Hockey Shorts 9</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts10" className="image">     
          <img src={menshorts10front} className="pic-1" alt='' />
          <img src={menshorts10back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts10">Men's Hockey Shorts 10</a>
     </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts11" className="image">     
          <img src={menshorts11front} className="pic-1" alt='' />
          <img src={menshorts11back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts11">Men's Hockey Shorts 11</a>
     </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts12" className="image">     
          <img src={menshorts12front} className="pic-1" alt='' />
          <img src={menshorts12back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts12">Men's Hockey Shorts 12</a>
     </h3>
  </div>
 
   </div>


   <br/>
     {/* Row 4 */}
     <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts13" className="image">
        <img src={menshorts13front} className="pic-1" alt='' />
        <img src={menshorts13back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Mens-Hockey-Shorts13">Men's Hockey Shorts 13</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts14" className="image">     
          <img src={menshorts14front} className="pic-1" alt='' />
          <img src={menshorts14back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts14">Men's Hockey Shorts 14</a>
     </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts15" className="image">     
          <img src={menshorts15front} className="pic-1" alt='' />
          <img src={menshorts15back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts15">Men's Hockey Shorts 15</a>
     </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts16" className="image">     
          <img src={menshorts16front} className="pic-1" alt='' />
          <img src={menshorts16back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts16">Men's Hockey Shorts 16</a>
     </h3>
  </div>
 
   </div>


   <br/>
     {/* Row 5 */}
     <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts17" className="image">
        <img src={menshorts17front} className="pic-1" alt='' />
        <img src={menshorts17back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Mens-Hockey-Shorts17">Men's Hockey Shorts 17</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts18" className="image">     
          <img src={menshorts18front} className="pic-1" alt='' />
          <img src={menshorts18back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts18">Men's Hockey Shorts 18</a>
     </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts19" className="image">     
          <img src={menshorts19front} className="pic-1" alt='' />
          <img src={menshorts19back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts19">Men's Hockey Shorts 19</a>
     </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts20" className="image">     
          <img src={menshorts20front} className="pic-1" alt='' />
          <img src={menshorts20back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts20">Men's Hockey Shorts 20</a>
     </h3>
  </div>
 
   </div>

   <br/>
     {/* Row 6 */}
     <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts21" className="image">
        <img src={menshorts21front} className="pic-1" alt='' />
        <img src={menshorts21back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Mens-Hockey-Shorts21">Men's Hockey Shorts 21</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts22" className="image">     
          <img src={menshorts22front} className="pic-1" alt='' />
          <img src={menshorts22back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts22">Men's Hockey Shorts 22</a>
     </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts23" className="image">     
          <img src={menshorts23front} className="pic-1" alt='' />
          <img src={menshorts23back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts23">Men's Hockey Shorts 23</a>
     </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Mens-Hockey-Shorts24" className="image">     
          <img src={menshorts24front} className="pic-1" alt='' />
          <img src={menshorts24back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/Mens-Hockey-Shorts24">Men's Hockey Shorts 24</a>
     </h3>
  </div>
 
   </div>

    </div>
</section>


</>
)
}

export default HockeyShortssport

