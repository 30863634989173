import React from 'react';
import { Helmet } from 'react-helmet';
import hoddie1front from "../../../images/sports/products/quarter-zip-sweatshirts/1/hoddie1front.png";
import hoddie1back from "../../../images/sports/products/quarter-zip-sweatshirts/1/hoddie1back.png";
import hoddie2front from "../../../images/sports/products/quarter-zip-sweatshirts/2/hoddie2front.png";
import hoddie2back from "../../../images/sports/products/quarter-zip-sweatshirts/2/hoddie2back.png";
import hoddie3front from "../../../images/sports/products/quarter-zip-sweatshirts/3/hoddie3front.png";
import hoddie3back from "../../../images/sports/products/quarter-zip-sweatshirts/3/hoddie3back.png";
import hoddie4front from "../../../images/sports/products/quarter-zip-sweatshirts/4/hoddie4front.png";
import hoddie4back from "../../../images/sports/products/quarter-zip-sweatshirts/4/hoddie4back.png";
import hoddie5front from "../../../images/sports/products/quarter-zip-sweatshirts/5/hoddie5front.png";
import hoddie5back from "../../../images/sports/products/quarter-zip-sweatshirts/5/hoddie5back.png";
import hoddie6front from "../../../images/sports/products/quarter-zip-sweatshirts/6/hoodie6front.png";
import hoddie6back from "../../../images/sports/products/quarter-zip-sweatshirts/6/hoodie6back.png";
import hoddie7front from "../../../images/sports/products/quarter-zip-sweatshirts/7/hoodie7front.png";
import hoddie7back from "../../../images/sports/products/quarter-zip-sweatshirts/7/hoodie7back.png";
import hoddie8front from "../../../images/sports/products/quarter-zip-sweatshirts/8/hoodie8front.png";
import hoddie8back from "../../../images/sports/products/quarter-zip-sweatshirts/8/hoodie8back.png";
import hoddie9front from "../../../images/sports/products/quarter-zip-sweatshirts/9/hoodie9front.png";
import hoddie9back from "../../../images/sports/products/quarter-zip-sweatshirts/9/hoodie9back.png";
import hoddie10front from "../../../images/sports/products/quarter-zip-sweatshirts/10/hoodie10front.png";
import hoddie10back from "../../../images/sports/products/quarter-zip-sweatshirts/10/hoodie10back.png";
import hoddie11front from "../../../images/sports/products/quarter-zip-sweatshirts/11/hoodie11front.png";
import hoddie11back from "../../../images/sports/products/quarter-zip-sweatshirts/11/hoodie11back.png";


const Quarterzipsweatshirtsport = () => {
    return (
<>

<Helmet>
<title>Quarter Zip Sweatshirts - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

{/* Rugby hoddie */}

<section className='cloth-rugby4' id='Quarter-Zip-Sweatshirt'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Quarter Zip Sweatshirts</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Quarter-Zip-Sweatshirt1" className="image">
        <img src={hoddie1front} className="pic-1" alt='' />
        <img src={hoddie1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Quarter-Zip-Sweatshirt1">Quarter Zip Sweatshirt 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Quarter-Zip-Sweatshirt2" className="image">
        <img src={hoddie2front} className="pic-1" alt='' />
        <img src={hoddie2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Quarter-Zip-Sweatshirt2">Quarter Zip Sweatshirt 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Quarter-Zip-Sweatshirt3" className="image">
        <img src={hoddie3front} className="pic-1" alt='' />
        <img src={hoddie3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Quarter-Zip-Sweatshirt3">Quarter Zip Sweatshirt 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Quarter-Zip-Sweatshirt4" className="image">
        <img src={hoddie4front} className="pic-1" alt='' />
        <img src={hoddie4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Quarter-Zip-Sweatshirt4">Quarter Zip Sweatshirt 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Quarter-Zip-Sweatshirt5" className="image">
        <img src={hoddie5front} className="pic-1" alt='' />
        <img src={hoddie5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Quarter-Zip-Sweatshirt5">Quarter Zip Sweatshirt 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Quarter-Zip-Sweatshirt6" className="image">
        <img src={hoddie6front} className="pic-1" alt='' />
        <img src={hoddie6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Quarter-Zip-Sweatshirt6">Quarter Zip Sweatshirt 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Quarter-Zip-Sweatshirt7" className="image">
        <img src={hoddie7front} className="pic-1" alt='' />
        <img src={hoddie7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Quarter-Zip-Sweatshirt7">Quarter Zip Sweatshirt 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Quarter-Zip-Sweatshirt8" className="image">
        <img src={hoddie8front} className="pic-1" alt='' />
        <img src={hoddie8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Quarter-Zip-Sweatshirt8">Quarter Zip Sweatshirt 8</a>
        </h3>
  </div>
 
   </div>
   <br/>

       {/* Row 3 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Quarter-Zip-Sweatshirt9" className="image">
        <img src={hoddie9front} className="pic-1" alt='' />
        <img src={hoddie9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Quarter-Zip-Sweatshirt9">Quarter Zip Sweatshirt 9</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Quarter-Zip-Sweatshirt10" className="image">
        <img src={hoddie10front} className="pic-1" alt='' />
        <img src={hoddie10back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Quarter-Zip-Sweatshirt10">Quarter Zip Sweatshirt 10</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Quarter-Zip-Sweatshirt11" className="image">
        <img src={hoddie11front} className="pic-1" alt='' />
        <img src={hoddie11back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Quarter-Zip-Sweatshirt11">Quarter Zip Sweatshirt 11</a>
        </h3>
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>


   </div>
   <br/>

   
    

    </div>
</section>



</>
)
}

export default Quarterzipsweatshirtsport

