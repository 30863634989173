import React from 'react';
import { Helmet } from 'react-helmet';
import shirt1front from "../../../images/sports/products/soccer-tshirts/1/shirt1front.png";
import shirt1back from "../../../images/sports/products/soccer-tshirts/1/shirt1back.png";
import shirt2front from "../../../images/sports/products/soccer-tshirts/2/shirt2front.png";
import shirt2back from "../../../images/sports/products/soccer-tshirts/2/shirt2back.png";
import shirt3front from "../../../images/sports/products/soccer-tshirts/3/shirt3front.png";
import shirt3back from "../../../images/sports/products/soccer-tshirts/3/shirt3back.png";
import shirt4front from "../../../images/sports/products/soccer-tshirts/4/shirt4front.png";
import shirt4back from "../../../images/sports/products/soccer-tshirts/4/shirt4back.png";
import shirt5front from "../../../images/sports/products/soccer-tshirts/5/shirt5front.png";
import shirt5back from "../../../images/sports/products/soccer-tshirts/5/shirt5back.png";
import shirt6front from "../../../images/sports/products/soccer-tshirts/6/shirt6front.png";
import shirt6back from "../../../images/sports/products/soccer-tshirts/6/shirt6back.png";
import shirt7front from "../../../images/sports/products/soccer-tshirts/7/shirt7front.png";
import shirt7back from "../../../images/sports/products/soccer-tshirts/7/shirt7back.png";
import shirt8front from "../../../images/sports/products/soccer-tshirts/8/shirt8front.png";
import shirt8back from "../../../images/sports/products/soccer-tshirts/8/shirt8back.png";
import shirt9front from "../../../images/sports/products/soccer-tshirts/9/shirt9front.png";
import shirt9back from "../../../images/sports/products/soccer-tshirts/9/shirt9back.png";
import shirt10front from "../../../images/sports/products/soccer-tshirts/10/shirt10front.png";
import shirt10back from "../../../images/sports/products/soccer-tshirts/10/shirt10back.png";


const Soccertshirtssport = () => {
    return (
<>

<Helmet>
<title>Soccer Shirts - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

{/* Soccer shirt */}

<section className='cloth-rugby4' id='Soccer-Tshirt'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Soccer Shirts</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Soccer-Shirt1" className="image">
        <img src={shirt1front} className="pic-1" alt='' />
        <img src={shirt1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Soccer-Shirtt1">Soccer Shirt 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Soccer-Shirt2" className="image">
        <img src={shirt2front} className="pic-1" alt='' />
        <img src={shirt2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Soccer-Shirt2">Soccer Shirt 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Soccer-Shirt3" className="image">
        <img src={shirt3front} className="pic-1" alt='' />
        <img src={shirt3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Soccer-Shirt3">Soccer Shirt 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Soccer-Shirt4" className="image">
        <img src={shirt4front} className="pic-1" alt='' />
        <img src={shirt4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Soccer-Shirt4">Soccer Shirt 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Soccer-Shirt5" className="image">
        <img src={shirt5front} className="pic-1" alt='' />
        <img src={shirt5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Soccer-Shirt5">Soccer Shirt 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Soccer-Shirt6" className="image">
        <img src={shirt6front} className="pic-1" alt='' />
        <img src={shirt6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Soccer-Shirt6">Soccer Shirt 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Soccer-Shirt7" className="image">
        <img src={shirt7front} className="pic-1" alt='' />
        <img src={shirt7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Soccer-Shirt7">Soccer Shirt 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Soccer-Shirt8" className="image">
        <img src={shirt8front} className="pic-1" alt='' />
        <img src={shirt8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Soccer-Shirt8">Soccer Shirt 8</a>
        </h3>
  </div>
 
   </div>
   <br/>

       {/* Row 3 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Soccer-Shirt9" className="image">
        <img src={shirt9front} className="pic-1" alt='' />
        <img src={shirt9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Soccer-Shirt9">Soccer Shirt 9</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Soccer-Shirt10" className="image">
        <img src={shirt10front} className="pic-1" alt='' />
        <img src={shirt10back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Soccer-Shirt10">Soccer Shirt 10</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>


   </div>
   <br/>

   
    

    </div>
</section>



</>
)
}

export default Soccertshirtssport

