import React from 'react';
import { Helmet } from 'react-helmet';

import gk1front from "../../../images/sports/products/gk-smock/1/gk1front.png";
import gk1back from "../../../images/sports/products/gk-smock/1/gk1back.png";
import gk2front from "../../../images/sports/products/gk-smock/2/gk2front.png";
import gk2back from "../../../images/sports/products/gk-smock/2/gk2back.png";
import gk3front from "../../../images/sports/products/gk-smock/3/gk3front.png";
import gk3back from "../../../images/sports/products/gk-smock/3/gk3back.png";
import gk4front from "../../../images/sports/products/gk-smock/4/gk4front.png";
import gk4back from "../../../images/sports/products/gk-smock/4/gk4back.png";
import gk5front from "../../../images/sports/products/gk-smock/5/gk5front.png";
import gk5back from "../../../images/sports/products/gk-smock/5/gk5back.png";
import gk6front from "../../../images/sports/products/gk-smock/6/gk6front.png";
import gk6back from "../../../images/sports/products/gk-smock/6/gk6back.png";
import gk7front from "../../../images/sports/products/gk-smock/7/gk7front.png";
import gk7back from "../../../images/sports/products/gk-smock/7/gk7back.png";
import gk8front from "../../../images/sports/products/gk-smock/8/gk8front.png";
import gk8back from "../../../images/sports/products/gk-smock/8/gk8back.png";
import gk9front from "../../../images/sports/products/gk-smock/9/gk9front.png";
import gk9back from "../../../images/sports/products/gk-smock/9/gk9back.png";
import gk10front from "../../../images/sports/products/gk-smock/10/gk10front.png";
import gk10back from "../../../images/sports/products/gk-smock/10/gk10back.png";
import gk11front from "../../../images/sports/products/gk-smock/11/gk11front.png";
import gk11back from "../../../images/sports/products/gk-smock/11/gk11back.png";


const Gksmocksport = () => {
    return (
<>

<Helmet>
<title>Gk Smock - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

 {/* Start Slider area  */}
<section className='cloth-rugby' id='Hockey-Shirts'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Gk Smock</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>


     {/* Row 1 */}
     <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/gk-smock1" className="image">
        <img src={gk1front} className="pic-1" alt='' />
        <img src={gk1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/gk-smock1">Gk Smock 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/gk-smock2" className="image">     
          <img src={gk2front} className="pic-1" alt='' />
          <img src={gk2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/gk-smock2">Gk Smock 2</a>
     </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
      <a href="/gk-smock3" className="image">     
          <img src={gk3front} className="pic-1" alt='' />
          <img src={gk3back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/gk-smock3">Gk Smock 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
      <a href="/gk-smock4" className="image">     
          <img src={gk4front} className="pic-1" alt='' />
          <img src={gk4back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/gk-smock4">Gk Smock 4</a>
        </h3>
  </div>
 
   </div>

  <br/>
     {/* Row 2 */}
     <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/gk-smock5" className="image">
        <img src={gk5front} className="pic-1" alt='' />
        <img src={gk5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/gk-smock5">Gk Smock 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/gk-smock6" className="image">     
          <img src={gk6front} className="pic-1" alt='' />
          <img src={gk6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/gk-smock6">Gk Smock 6</a>
     </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
      <a href="/gk-smock7" className="image">     
          <img src={gk7front} className="pic-1" alt='' />
          <img src={gk7back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/gk-smock7">Gk Smock 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
      <a href="/gk-smock8" className="image">     
          <img src={gk8front} className="pic-1" alt='' />
          <img src={gk8back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/gk-smock8">Gk Smock 8</a>
        </h3>
  </div>
 
   </div>

   <br/>
     {/* Row 3 */}
     <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/gk-smock9" className="image">
        <img src={gk9front} className="pic-1" alt='' />
        <img src={gk9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/gk-smock9">Gk Smock 9</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/gk-smock10" className="image">     
          <img src={gk10front} className="pic-1" alt='' />
          <img src={gk10back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/gk-smock10">Gk Smock 10</a>
     </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/gk-smock11" className="image">     
          <img src={gk11front} className="pic-1" alt='' />
          <img src={gk11back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/gk-smock11">Gk Smock 11</a>
     </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
 
   </div>






    </div>
</section>


</>
)
}

export default Gksmocksport

