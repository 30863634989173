import React from 'react';
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../global-components/navbar-v4';
import NavbarV3 from '../global-components/navbar-v3';
import PanelGroup from '../newsec';

import front1 from "../../images/sports/front1.webp";
import front2 from "../../images/sports/front2.webp";

import a2 from "../../images/sports/a2.jpg";
import collabbanner from "../../images/banners/collabbanner.webp"
import collabmobile from "../../images/banners/collabmobile.webp"

const Entryhome = () => {
    return (
<>
{/* <NavbarV4 CustomClass="ltn__header-transparent gradient-color-2"  /> */}
{/* <NavbarV3 /> */}

<Helmet>
<title>Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>


 {/* <div className="App">
      <PanelGroup />
    </div>  */}


<section className='entryhome'>
          <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 entry'>
            <a href="/home"><img src={front1} className="homec" alt='' /></a>
          </div>
          <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 entry'>
            <img src={front2} className="homec" alt='' />
          </div>

</section>


{/* <picture>
  <source srcset={collabmobile} media="(max-width: 660px)" />
  <source srcset={collabbanner} />
  <img src={collabbanner} alt="" className='ban' />
</picture> */}



{/* <section className='entryhome'>
          <div className='col-xxl-12 col-xl-12 col-md-12 col-12 entry'>
            <a href="/home"><img src={a2} className="homec" alt='' /></a>
          </div>
</section> */}



</>
)
}

export default Entryhome

