import React from 'react';
import { Helmet } from 'react-helmet';

import shorts1front from "../../../images/sports/products/rugby-shorts/1/shorts1front.png";
import shorts1back from "../../../images/sports/products/rugby-shorts/1/shorts1back.png";
import shorts2front from "../../../images/sports/products/rugby-shorts/2/shorts2front.png";
import shorts2back from "../../../images/sports/products/rugby-shorts/2/shorts2back.png";
import shorts3front from "../../../images/sports/products/rugby-shorts/3/shorts3front.png";
import shorts3back from "../../../images/sports/products/rugby-shorts/3/shorts3back.png";
import shorts4front from "../../../images/sports/products/rugby-shorts/4/shorts4front.png";
import shorts4back from "../../../images/sports/products/rugby-shorts/4/shorts4back.png";
import shorts5front from "../../../images/sports/products/rugby-shorts/5/shorts5front.png";
import shorts5back from "../../../images/sports/products/rugby-shorts/5/shorts5back.png";
import shorts6front from "../../../images/sports/products/rugby-shorts/6/shorts6front.png";
import shorts6back from "../../../images/sports/products/rugby-shorts/6/shorts6back.png";
import shorts7front from "../../../images/sports/products/rugby-shorts/7/shorts7front.png";
import shorts7back from "../../../images/sports/products/rugby-shorts/7/shorts7back.png";
import shorts8front from "../../../images/sports/products/rugby-shorts/8/shorts8front.png";
import shorts8back from "../../../images/sports/products/rugby-shorts/8/shorts8back.png";
import shorts9front from "../../../images/sports/products/rugby-shorts/9/shorts9front.png";
import shorts9back from "../../../images/sports/products/rugby-shorts/9/shorts9back.png";
import shorts10front from "../../../images/sports/products/rugby-shorts/10/shorts10front.png";
import shorts10back from "../../../images/sports/products/rugby-shorts/10/shorts10back.png";


const SublimationRugbyShortssport = () => {
    return (
<>
 

<Helmet>
<title>Rugby Shorts - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>



{/* Sublimation Rugby Shorts */}

<section className='cloth-rugby2' id='Cut-Sew-Rugby-Shorts'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Sublimation Rugby Shorts</h2>
        <p>Here are some of our most popular products that you can choose from.</p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts1" className="image">
        <img src={shorts1front} className="pic-1" alt='' />
        <img src={shorts1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="Rugby-Shorts1">Rugby Shorts 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts2" className="image">
        <img src={shorts2front} className="pic-1" alt='' />
        <img src={shorts2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
       <h3 className="title-product">
          <a href="/Rugby-Shorts2">Rugby Shorts 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts3" className="image">
        <img src={shorts3front} className="pic-1" alt='' />
        <img src={shorts3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
        <h3 className="title-product">
           <a href="/Rugby-Shorts3">Rugby Shorts 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts4" className="image">
        <img src={shorts4front} className="pic-1" alt='' />
        <img src={shorts4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
        <h3 className="title-product">
            <a href="/Rugby-Shorts4">Rugby Shorts 4</a>
        </h3>
  </div>
 
   </div>

  <br/>

      {/* Row 2 */}
      <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts5" className="image">
        <img src={shorts5front} className="pic-1" alt='' />
        <img src={shorts5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts5">Rugby Shorts 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts6" className="image">
        <img src={shorts6front} className="pic-1" alt='' />
        <img src={shorts6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
       <h3 className="title-product">
          <a href="/Rugby-Shorts6">Rugby Shorts 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts7" className="image">
        <img src={shorts7front} className="pic-1" alt='' />
        <img src={shorts7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
        <h3 className="title-product">
           <a href="/Rugby-Shorts7">Rugby Shorts 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts8" className="image">
        <img src={shorts8front} className="pic-1" alt='' />
        <img src={shorts8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
        <h3 className="title-product">
            <a href="/Rugby-Shorts8">Rugby Shorts 8</a>
        </h3>
  </div>
 
      </div>

  <br/>

      {/* Row 3 */}
      <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts9" className="image">
        <img src={shorts9front} className="pic-1" alt='' />
        <img src={shorts9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts9">Rugby Shorts 9</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts10" className="image">
        <img src={shorts10front} className="pic-1" alt='' />
        <img src={shorts10back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
       <h3 className="title-product">
          <a href="/Rugby-Shorts10">Rugby Shorts 10</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>
 
      </div>




    </div>
</section>



</>
)
}

export default SublimationRugbyShortssport

