import React from 'react';
import { Helmet } from 'react-helmet';
import hoddie1front from "../../../images/sports/products/hooded-sweatshirts/1/hoddie1front.png";
import hoddie1back from "../../../images/sports/products/hooded-sweatshirts/1/hoddie1back.png";
import hoddie2front from "../../../images/sports/products/hooded-sweatshirts/2/hoddie2front.png";
import hoddie2back from "../../../images/sports/products/hooded-sweatshirts/2/hoddie2back.png";
import hoddie3front from "../../../images/sports/products/hooded-sweatshirts/3/hoddie3front.png";
import hoddie3back from "../../../images/sports/products/hooded-sweatshirts/3/hoddie3back.png";
import hoddie4front from "../../../images/sports/products/hooded-sweatshirts/4/hoddie4front.png";
import hoddie4back from "../../../images/sports/products/hooded-sweatshirts/4/hoddie4back.png";
import hoddie5front from "../../../images/sports/products/hooded-sweatshirts/5/hoddie5front.png";
import hoddie5back from "../../../images/sports/products/hooded-sweatshirts/5/hoddie5back.png";
import hoddie6front from "../../../images/sports/products/hooded-sweatshirts/6/hoddie6front.png";
import hoddie6back from "../../../images/sports/products/hooded-sweatshirts/6/hoddie6back.png";
import hoddie7front from "../../../images/sports/products/hooded-sweatshirts/7/hoddie7front.png";
import hoddie7back from "../../../images/sports/products/hooded-sweatshirts/7/hoddie7back.png";
import hoddie8front from "../../../images/sports/products/hooded-sweatshirts/8/hoddie8front.png";
import hoddie8back from "../../../images/sports/products/hooded-sweatshirts/8/hoddie8back.png";
import hoddie9front from "../../../images/sports/products/hooded-sweatshirts/9/hoddie9front.png";
import hoddie9back from "../../../images/sports/products/hooded-sweatshirts/9/hoddie9back.png";
import hoddie10front from "../../../images/sports/products/hooded-sweatshirts/10/hoddie10front.png";
import hoddie10back from "../../../images/sports/products/hooded-sweatshirts/10/hoddie10back.png";
import hoddie11back from "../../../images/sports/products/hooded-sweatshirts/11/hoddie11back.png";
import hoddie11front from "../../../images/sports/products/hooded-sweatshirts/11/hoddie11front.png";
import hoddie12back from "../../../images/sports/products/hooded-sweatshirts/12/hoddie12back.png";
import hoddie12front from "../../../images/sports/products/hooded-sweatshirts/12/hoddie12front.png";


const Hoddedsweatshirtsport = () => {
    return (
<>

<Helmet>
<title>Hooded Sweatshirts - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

{/* Rugby hoddie */}

<section className='cloth-rugby4' id='Hooded-Sweatshirt'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Hooded Sweatshirts</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Hooded-Sweatshirt1" className="image">
        <img src={hoddie1front} className="pic-1" alt='' />
        <img src={hoddie1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Hooded-Sweatshirt1">Hooded Sweatshirt 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Hooded-Sweatshirt2" className="image">
        <img src={hoddie2front} className="pic-1" alt='' />
        <img src={hoddie2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Hooded-Sweatshirt2">Hooded Sweatshirt 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Hooded-Sweatshirt3" className="image">
        <img src={hoddie3front} className="pic-1" alt='' />
        <img src={hoddie3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Hooded-Sweatshirt3">Hooded Sweatshirt 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Hooded-Sweatshirt4" className="image">
        <img src={hoddie4front} className="pic-1" alt='' />
        <img src={hoddie4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Hooded-Sweatshirt4">Hooded Sweatshirt 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Hooded-Sweatshirt5" className="image">
        <img src={hoddie5front} className="pic-1" alt='' />
        <img src={hoddie5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Hooded-Sweatshirt5">Hooded Sweatshirt 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Hooded-Sweatshirt6" className="image">
        <img src={hoddie6front} className="pic-1" alt='' />
        <img src={hoddie6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Hooded-Sweatshirt6">Hooded Sweatshirt 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Hooded-Sweatshirt7" className="image">
        <img src={hoddie7front} className="pic-1" alt='' />
        <img src={hoddie7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Hooded-Sweatshirt7">Hooded Sweatshirt 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Hooded-Sweatshirt8" className="image">
        <img src={hoddie8front} className="pic-1" alt='' />
        <img src={hoddie8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Hooded-Sweatshirt8">Hooded Sweatshirt 8</a>
        </h3>
  </div>
 
   </div>
   <br/>

       {/* Row 3 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Hooded-Sweatshirt9" className="image">
        <img src={hoddie9front} className="pic-1" alt='' />
        <img src={hoddie9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Hooded-Sweatshirt9">Hooded Sweatshirt 9</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Hooded-Sweatshirt10" className="image">
        <img src={hoddie10front} className="pic-1" alt='' />
        <img src={hoddie10back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Hooded-Sweatshirt10">Hooded Sweatshirt 10</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Hooded-Sweatshirt11" className="image">
        <img src={hoddie11front} className="pic-1" alt='' />
        <img src={hoddie11back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Hooded-Sweatshirt11">Hooded Sweatshirt 11</a>
        </h3>
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Hooded-Sweatshirt12" className="image">
        <img src={hoddie12front} className="pic-1" alt='' />
        <img src={hoddie12back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Hooded-Sweatshirt12">Hooded Sweatshirt 12</a>
        </h3>
  </div>


   </div>
   <br/>

    

    </div>
</section>



</>
)
}

export default Hoddedsweatshirtsport

