import React from 'react';
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../global-components/navbar-v4';

import gal1 from "../../images/sports/gallery/gal1.jpg";
import gal2 from "../../images/sports/gallery/gal2.jpg";
import gal3 from "../../images/sports/gallery/gal3.jpg";
import gal4 from "../../images/sports/gallery/gal4.jpg";
import gal5 from "../../images/sports/gallery/gal5.jpg";
import gal6 from "../../images/sports/gallery/gal6.jpg";
import gal7 from "../../images/sports/gallery/gal7.jpg";
import gal8 from "../../images/sports/gallery/gal8.jpg";
import gal9 from "../../images/sports/gallery/gal9.jpg";
import gal10 from "../../images/sports/gallery/gal10.jpg";
import gal11 from "../../images/sports/gallery/gal11.jpg";
import gal12 from "../../images/sports/gallery/gal12.jpg";

import gallerybanner from "../../images/banners/gallerybanner.webp"
import gallerymobile from "../../images/banners/gallerymobile.webp"



const Gallery = () => {
    return (
<>

<NavbarV4 CustomClass="ltn__header-transparent gradient-color-2"  />
<Helmet>
<title>Gallery - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>


 {/* Start Slider area  */}
{/* <section className='careerbanner'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='Sports-bcurri'>
               <h1 className='bannhead'>Gallery </h1>
                <h2 className='Specialist'>Apparel Limited</h2>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={galleryimg} className='abhtbnrpick' alt='' />
            </div>
        </div>
    </div>
</section> */}


<picture>
  <source srcset={gallerymobile} media="(max-width: 660px)" />
  <source srcset={gallerybanner} />
  <img src={gallerybanner} alt="" className='ban' />
</picture>


<section className='sec_gallery'> 
        <div className='container'>
        <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center'>


        <div class="gallery">
	<div class="gallery__column">
		<a href={gal1} target="_blank" class="gallery__link">
			<figure class="gallery__thumb">
				<img src={gal1} alt="Portrait by Jessica Felicio" class="gallery__image"/>
				<figcaption class="gallery__caption">Portrait by Jessica Felicio</figcaption>
			</figure>
		</a>

		<a href={gal7} target="_blank" class="gallery__link">
			<figure class="gallery__thumb">
				<img src={gal7} alt="Portrait by Oladimeji Odunsi" class="gallery__image"/>
				<figcaption class="gallery__caption">Portrait by Oladimeji Odunsi</figcaption>
			</figure>
		</a>

		<a href={gal5} target="_blank" class="gallery__link">
			<figure class="gallery__thumb">
				<img src={gal5} alt="Portrait by Alex Perez" class="gallery__image"/>
				<figcaption class="gallery__caption">Portrait by Alex Perez</figcaption>
			</figure>
		</a>
	</div>
	
	<div class="gallery__column">
		<a href={gal2} target="_blank" class="gallery__link">
			<figure class="gallery__thumb">
				<img src={gal2} alt="Portrait by Hikiapp" class="gallery__image"/>
				<figcaption class="gallery__caption">Portrait by Hikiapp</figcaption>
			</figure>
		</a>
		
		<a href={gal6} target="_blank" class="gallery__link">
			<figure class="gallery__thumb">
				<img src={gal6} alt="Portrait by Ivana Cajina" class="gallery__image"/>
				<figcaption class="gallery__caption">Portrait by Ivana Cajina</figcaption>
			</figure>
		</a>

		<a href={gal8} target="_blank" class="gallery__link">
			<figure class="gallery__thumb">
				<img src={gal8} alt="Portrait by Jeffery Erhunse" class="gallery__image"/>
				<figcaption class="gallery__caption">Portrait by Jeffery Erhunse</figcaption>
			</figure>
		</a>
	</div>
	
	<div class="gallery__column">
		<a href={gal3} target="_blank" class="gallery__link">
			<figure class="gallery__thumb">
				<img src={gal3} alt="Portrait by Mari Lezhava" class="gallery__image"/>
				<figcaption class="gallery__caption">Portrait by Mari Lezhava</figcaption>
			</figure>
		</a>
		
		<a href={gal9} target="_blank" class="gallery__link">
			<figure class="gallery__thumb">
				<img src={gal9} alt="Portrait by Ethan Haddox" class="gallery__image"/>
				<figcaption class="gallery__caption">Portrait by Ethan Haddox</figcaption>
			</figure>
		</a>

		<a href={gal10} target="_blank" class="gallery__link">
			<figure class="gallery__thumb">
				<img src={gal10} alt="Portrait by Amir Geshani" class="gallery__image"/>
				<figcaption class="gallery__caption">Portrait by Amir Geshani</figcaption>
			</figure>
		</a>
	</div>
	
	<div class="gallery__column">
		<a href={gal4} target="_blank" class="gallery__link">
			<figure class="gallery__thumb">
				<img src={gal4} alt="Portrait by Tyler Nix" class="gallery__image"/>
				<figcaption class="gallery__caption">Portrait by Tyler Nix</figcaption>
			</figure>
		</a>

		<a href={gal11} target="_blank" class="gallery__link">
			<figure class="gallery__thumb">
				<img src={gal11} alt="Portrait by Jasmin Chew" class="gallery__image"/>
				<figcaption class="gallery__caption">Portrait by Jasmin Chew</figcaption>
			</figure>
		</a>
		
		<a href={gal12} target="_blank" class="gallery__link">
			<figure class="gallery__thumb">
				<img src={gal12} alt="Portrait by Dima DallAcqua" class="gallery__image"/>
				<figcaption class="gallery__caption">Portrait by Dima DallAcqua</figcaption>
			</figure>
		</a>
	</div>
</div>

        </div>
        </div>
        </div>
</section>



<Footer_v1 />
</>
)
}

export default Gallery

