import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import chatmessage from "../../images/sports/chatmessage.png";

class NavbarV3 extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
 
       
        return (
		<div>
           <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-4---">
			
			  <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
			    <div className="container">
			      <div className="row">
			        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
			          <div className="site-logo-wrap">
			            <div className="site-logo go-top">
			              <Link to="/"><img src={publicUrl+"assets/img/entrylogo.png"} alt="Logo"  className='bcurrilogo'/></Link>
			            </div>
			            <div className="get-support clearfix d-none">
			              <div className="get-support-icon">
			                <i className="icon-call" />
			              </div>
			              <div className="get-support-info">
			                {/* <h6>Get Support</h6>
			                <h4><a href="tel:+123456789">123-456-789-10</a></h4> */}
			              </div>
			            </div>
			          </div>
			        </div>
			        <div className="col header-menu-column menu-color-white">
			          <div className="header-menu d-none d-xl-block go-top">
			            {/* <nav>
			              <div className="ltn__main-menu">
			                <ul>
							<li><Link to="/">Home</Link></li>
			                					
							  <li><Link to="/about">About Us</Link>
							  <ul className="sub-menu">
								<li><Link to="/ourportfolio">Our Portfolio</Link> </li>
								<li><Link to="/career">Career</Link></li>
			                    </ul>
			                  </li>
														
							<li><a href="">Contact Us</a></li>
			               	 <li className="special-link">
							 <div className='chatmsg'>
								<img src={chatmessage}  alt='' className='chatmessage' />
							 </div>
							  <div className="box-body">
 							 <h3 className="nav-info-box-title">24 hours every day </h3>
 							 <p>1-800-956-6729</p>
								</div>
			                  </li>
			                </ul>
			              </div>
			            </nav> */}
			          </div>
			        </div>
			        <div className="col--- ltn__header-options ltn__header-options-2 ">
			          {/* Mobile Menu Button */}
			          {/* <div className="mobile-menu-toggle d-xl-none">
			            <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
			              <svg viewBox="0 0 800 600">
			                <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
			                <path d="M300,320 L540,320" id="middle" />
			                <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
			              </svg>
			            </a>
			          </div> */}
			        </div>
			      </div>
			    </div>
			  </div>
			</header>
			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
					<div className="site-logo">
						<Link to="/"><img src={publicUrl+"assets/img/entrylogo.png"} alt="Logo"  className='logo'/></Link>
					</div>
					<button className="ltn__utilize-close">×</button>
					</div>
				{/* <div className="ltn__utilize-menu">
					<ul>
						<li><a href="/">Home</a></li>
						<li><Link to="/about">About</Link></li>
														
						<li><Link to="/ourportfolio">Our Portfolio</Link> </li>
						<li><Link to="/ourblog">Blog</Link></li>
						<li><Link to="/contactus">Contact</Link></li>
					</ul>
				</div> */}
				
				</div>
				</div>
		</div>
        )
    }
}


export default NavbarV3