import React from 'react';
import { Helmet } from 'react-helmet';

import socks1front from "../../../images/sports/products/socks/socks1front.png";
import socks1back from "../../../images/sports/products/socks/socks1back.png";
import socks2front from "../../../images/sports/products/socks/socks2front.png";
import socks2back from "../../../images/sports/products/socks/socks2back.png";
import socks3front from "../../../images/sports/products/socks/socks3front.png";
import socks3back from "../../../images/sports/products/socks/socks3back.png";
import socks4front from "../../../images/sports/products/socks/socks4front.png";
import socks4back from "../../../images/sports/products/socks/socks4back.png";
import socks5front from "../../../images/sports/products/socks/socks5front.png";
import socks5back from "../../../images/sports/products/socks/socks5back.png";
import socks6front from "../../../images/sports/products/socks/socks6front.png";
import socks6back from "../../../images/sports/products/socks/socks6back.png";
import socks7front from "../../../images/sports/products/socks/socks7front.png";
import socks7back from "../../../images/sports/products/socks/socks7back.png";
import socks8front from "../../../images/sports/products/socks/socks8front.png";
import socks8back from "../../../images/sports/products/socks/socks8back.png";
import socks9front from "../../../images/sports/products/socks/socks9front.png";
import socks9back from "../../../images/sports/products/socks/socks9back.png";
import socks10front from "../../../images/sports/products/socks/socks10front.png";
import socks10back from "../../../images/sports/products/socks/socks10back.png";
import socks11front from "../../../images/sports/products/socks/socks11front.png";
import socks11back from "../../../images/sports/products/socks/socks11back.png";
import socks12front from "../../../images/sports/products/socks/socks12front.png";
import socks12back from "../../../images/sports/products/socks/socks12back.png";
import socks13front from "../../../images/sports/products/socks/socks13front.png";
import socks13back from "../../../images/sports/products/socks/socks13back.png";
import socks14front from "../../../images/sports/products/socks/socks14front.png";
import socks14back from "../../../images/sports/products/socks/socks14back.png";


const RugbySockssport = () => {
    return (
<>


<Helmet>
<title>Socks - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>



{/* Rugby Socks */}

<section className='cloth-rugby4' id='Rugby-Socks'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Socks</h2>
        <p>Here are some of our most popular products that you can choose from.</p>
        </div>
    </div>
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks1" className="image">
        <img src={socks1front} className="pic-1" alt='' />
        <img src={socks1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
      <h3 className="title-product">
         <a href="/Rugby-Socks1">Socks 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks2" className="image">
        <img src={socks2front} className="pic-1" alt='' />
        <img src={socks2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks2">Socks 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks3" className="image">
        <img src={socks3front} className="pic-1" alt='' />
        <img src={socks3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks3"> Socks 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks4" className="image">
        <img src={socks4front} className="pic-1" alt='' />
        <img src={socks4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks4"> Socks 4</a>
        </h3>
  </div>
 
   </div>
<br/>
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks5" className="image">
        <img src={socks5front} className="pic-1" alt='' />
        <img src={socks5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks5"> Socks 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks6" className="image">
        <img src={socks6front} className="pic-1" alt='' />
        <img src={socks6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks6"> Socks 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks7" className="image">
        <img src={socks7front} className="pic-1" alt='' />
        <img src={socks7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks7"> Socks 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks8" className="image">
        <img src={socks8front} className="pic-1" alt='' />
        <img src={socks8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks8">Socks 8</a>
        </h3>
  </div>
 
   </div>

   <br/>
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks9" className="image">
        <img src={socks9front} className="pic-1" alt='' />
        <img src={socks9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks9"> Socks 9</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks10" className="image">
        <img src={socks10front} className="pic-1" alt='' />
        <img src={socks10back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks10">Socks 10</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks11" className="image">
        <img src={socks11front} className="pic-1" alt='' />
        <img src={socks11back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks11">Socks 11</a>
        </h3>
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks12" className="image">
        <img src={socks12front} className="pic-1" alt='' />
        <img src={socks12back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks12">Socks 12</a>
        </h3>
  </div>


   </div>

   <br/>
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks13" className="image">
        <img src={socks13front} className="pic-1" alt='' />
        <img src={socks13back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks13"> Socks 13</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Socks14" className="image">
        <img src={socks14front} className="pic-1" alt='' />
        <img src={socks14back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Socks14">Socks 14</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>


   </div>

    </div>
</section>



</>
)
}

export default RugbySockssport

