import React from 'react';
import { Helmet } from 'react-helmet';

import top1front from "../../../images/sports/products/track-top/1/top1front.png";
import top1back from "../../../images/sports/products/track-top/1/top1back.png";
import top2front from "../../../images/sports/products/track-top/2/top2front.png";
import top2back from "../../../images/sports/products/track-top/2/top2back.png";
import top3front from "../../../images/sports/products/track-top/3/top3front.png";
import top3back from "../../../images/sports/products/track-top/3/top3back.png";
import top4front from "../../../images/sports/products/track-top/4/top4front.png";
import top4back from "../../../images/sports/products/track-top/4/top4back.png";
import top5front from "../../../images/sports/products/track-top/5/top5front.png";
import top5back from "../../../images/sports/products/track-top/5/top5back.png";
import top6front from "../../../images/sports/products/track-top/6/top6front.png";
import top6back from "../../../images/sports/products/track-top/6/top6back.png";
import top7front from "../../../images/sports/products/track-top/7/top7front.png";
import top7back from "../../../images/sports/products/track-top/7/top7back.png";
import top8front from "../../../images/sports/products/track-top/8/top8front.png";
import top8back from "../../../images/sports/products/track-top/8/top8back.png";
import top9front from "../../../images/sports/products/track-top/9/top9front.png";
import top9back from "../../../images/sports/products/track-top/9/top9back.png";
import top10front from "../../../images/sports/products/track-top/10/top10front.png";
import top10back from "../../../images/sports/products/track-top/10/top10back.png";


const Tracktopsport  = () => {
    return (
<>

<Helmet>
<title>Track Top - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

{/* Rugby top */}

<section className='cloth-rugby4' id='Track-Top'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Track Top</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Track-Top1" className="image">
        <img src={top1front} className="pic-1" alt='' />
        <img src={top1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Track-Top1">Track Top 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Track-Top2" className="image">
        <img src={top2front} className="pic-1" alt='' />
        <img src={top2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Track-Top2">Track Top 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Track-Top3" className="image">
        <img src={top3front} className="pic-1" alt='' />
        <img src={top3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Track-Top3">Track Top 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Track-Top4" className="image">
        <img src={top4front} className="pic-1" alt='' />
        <img src={top4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Track-Top4">Track Top 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Track-Top5" className="image">
        <img src={top5front} className="pic-1" alt='' />
        <img src={top5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Track-Top5">Track Top 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Track-Top6" className="image">
        <img src={top6front} className="pic-1" alt='' />
        <img src={top6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Track-Top6">Track Top 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Track-Top7" className="image">
        <img src={top7front} className="pic-1" alt='' />
        <img src={top7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Track-Top7">Track Top 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Track-Top8" className="image">
        <img src={top8front} className="pic-1" alt='' />
        <img src={top8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Track-Top8">Track Top 8</a>
        </h3>
  </div>
 
   </div>
   <br/>

       {/* Row 3 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Track-Top9" className="image">
        <img src={top9front} className="pic-1" alt='' />
        <img src={top9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Track-Top9">Track Top 9</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Track-Top10" className="image">
        <img src={top10front} className="pic-1" alt='' />
        <img src={top10back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Track-Top10">Track Top 10</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>


   </div>
   <br/>

   
    

    </div>
</section>



</>
)
}

export default Tracktopsport 

