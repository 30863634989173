import React from 'react';
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../global-components/navbar-v4';

import workwearbanner from "../../images/sports/workwearbanner.jpg";
import workwearmobile from "../../images/sports/workwearmobile.jpg";

import workleftimg from"../../images/sports/workleftimg.webp";


const Workwear = () => {
    return (
<>

<NavbarV4 CustomClass="ltn__header-transparent gradient-color-2"  />
<Helmet>
<title>Workwear - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

 {/* Start Slider area  */}
 <picture>
  <source srcset={workwearmobile} media="(max-width: 660px)" />
  <source srcset={workwearbanner} />
  <img src={workwearbanner} alt="" className='ban' />
</picture>


{/* <section className='workwearbanner1'>
        <img src={workwearbanner} className='workwr' alt='' />     
            
</section> */}


<section className='worksec'>
    <div className='container'>
        <div className='row collb'>

        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
          <img src={workleftimg} className='collab1' alt='' />
       </div>

      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
        <h2>Designing Unique Corporate Uniforms That Reflect Brand Identity </h2>
        <p> We collaborate with individual corporations to design top-notch uniforms for their staff that not only stand out from the crowd but also help them promote their brand.  Our designs are <b>customized</b> to match your corporate colors, and we source the finest quality material to craft unique products for you. </p>
        <p>Our extensive range of products includes <b>T-shirts</b>, <b>fleece jackets</b>, <b>sweatshirts</b>, <b>windbreakers</b>, <b>softshell jackets</b>, <b>caps</b>, and more. </p> 
        <p>We cater to various sectors, including <b>warehouses</b>, <b>supermarkets</b>,  <b>aviation</b>,  <b>logistics</b>, <b>schools</b>, <b>universities</b> and many more. </p>
        <p>At <b>Bcurri Apparel</b>, you’ll get access to a wide range of fabrics, diverse color options, flexible production capabilities, no size limitations and extensive customization options.</p>
        <p>Want <b>custom workwear</b> that represents your brand? We’d love to collaborate with you to enhance your corporate workwear. Send us an email at <a className="collabmail" href="mailto:sales@bcurriapparel.com">sales@bcurriapparel.com</a>, and let us design customized, high-quality, and long-lasting workwear that aligns with your corporate identity. 
        </p>
      </div>

        </div>
    </div>
</section>



<Footer_v1 />
</>
)
}

export default Workwear

