import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../../global-components/navbar-v4';
import Footer_v1 from '../../global-components/footer';
import Formmodal from '../../section-components/formmodal';
import item1Image from '../../../images/sports/products/item1.jpg';
import item2Image from '../../../images/sports/products/item2.jpg';
import item3Image from '../../../images/sports/products/item3.jpg';
import item4Image from '../../../images/sports/products/item4.jpg';
import Sublimationrugbyjerseysport from '../../section-components/Rugby-cloth/Sublimation-Rugby-Jersey-sport';
import SublimationRugbyShortssport from '../../section-components/Rugby-cloth/Sublimation-Rugby-Shorts-sport';



const SublimationRugbyShorts = () => {
  const smallImages = [item1Image, item2Image, item3Image, item4Image];

  const [productImage, setProductImage] = useState(smallImages[0]);

  const handleSmallImageClick = (src) => {
    setProductImage(src);
  };

  return (
    <>
      <NavbarV4 />

      <Helmet>
        <title>Bcurri Sports</title>
        <meta name="description" content="" />
      </Helmet>


 {/* Start Slider area  */}
 <section className='rugby1 overlay'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-md-12'>
                 <div className='rugby1-bcurri'>
                <h1>Rugby</h1>
             </div>
         
            </div>
          </div>
    </div>
</section>


      <SublimationRugbyShortssport />

      <Footer_v1 />
    </>
  );
};

export default SublimationRugbyShorts;
