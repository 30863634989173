import React from 'react';
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../global-components/navbar-v4';

import ab1 from "../../images/sports/ab1.jpg";
import ab2 from "../../images/sports/ab2.jpg";
import ab3 from "../../images/sports/ab3.jpg";
import ab4 from "../../images/sports/ab4.jpg";
import choose3 from "../../images/sports/choose3.webp";

import aboutusbanner from "../../images/banners/aboutusbanner.webp";
import aboutusmobile from "../../images/banners/aboutusmobile.webp";


const Aboutus = () => {
    return (
<>

<NavbarV4 CustomClass="ltn__header-transparent gradient-color-2"  />
<Helmet>
<title>About Us - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>


 {/* Start Slider area  */}

{/* <section className='aboutbanner'>
    <div className='container'>
        <div className='row'>

            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='Sports-bcurri'>
                <h1 className='bannhead'>About Bcurri </h1>
                <h2 className='Specialist'>Apparel Limited</h2>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={about1} className='abhtbnrpick' alt='' />
            </div>
        </div>
    </div>
</section> */}

<picture>
  <source srcset={aboutusmobile} media="(max-width: 660px)" />
  <source srcset={aboutusbanner} />
  <img src={aboutusbanner} alt="" className='ban' />
</picture>


<section className='abtusbcurri'>
    
{/* <div class="scroll-parent1">
    <div class="scroll-element1 primary1">
      <span> 15% discount on first order</span>
      <span> To become our Partner Club</span>
    </div>
    <div class="scroll-element1 secondary1">
    <span> 15% discount on first order</span>
      <span> To become our Partner Club</span>
    </div>
  </div> */}

    <div className='container'>
        <div className='row'>

            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <h2>Our Story</h2>
            <hr />
            <div className='first-p padbot'>
            <p>At Bcurri Apparel, we specialize in providing designing and printing services for sportswear and workwear. From our modest beginnings in 2008, we have grown into a leading name in custom apparel, having collaborated with some of the best sporting teams and organizations to deliver high-quality customized apparel. Our journey is driven by our unwavering passion for creating exceptional sportswear and teamwear, along with a commitment to deliver the highest quality garments. </p>
            <p>Our company started its journey with the mission to create custom apparel that reflects your unique identity. From concept to creation, we craft attires that inspire and empower individuals to perform at their best level. Our journey is a perfect blend of style, performance and team spirit. </p>
            <p>We firmly believe that apparel is more than just a mere garment. It’s a driving force behind great performances. Therefore, our designs combine style and functionality to ensure that individuals feel comfortable and look their best. Our teamwear is crafted to foster a sense of unity among teammates.</p>
            <p>Our operations comprise a passionate designing, manufacturing and logistics team that ensures efficient production as well as on-time delivery. All our sports and work apparel are customized to meet your specific requirements from beginning to end. With our customization services, you can craft personalized teamwear and sportswear that reflects your vision.</p>
            <p>From professional sports teams to corporate organizations, we’re proud to have partnered with some of the best sports teams and businesses and have them dressed up in the bespoke attires that made them stand apart from the crowd. </p>
             
             </div>
            </div>

            {/* <Corpclient /> */}


            {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='secong-p'>
                <p>Quality, style and comfort have always been the benchmark for the Bcurri Teamwear range which includes playing Shirts, tracksuits, t-shirts, polo shirts, bags, shorts or specialized garments tailored to meet your individual needs.</p>
                <p>Choose from a wide range, starting from our in-house designs or design your own kit, all of which can be personalized through Embroidery, Screen Printing, or even Digital Printing numbers to suit your garment's style and matched by are unique minimum quantities as low as 10units per product style.</p>
                <p>Bcurri will ensure you will receive competitive prices combined with a prompt delivery service for all of your garment requirements. Talk to your Bcurri Teamwear representative today for more information or for help in outfitting your team.</p>
                </div>
            </div> */}
        </div>
      


<section className='jourssec'>
    <div className='container'>
        <div className='row'>

        <div className='col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12'>
            <h2>Unleashing The Power Of Innovation In Sportswear & Workwear</h2>
            <p>Throughout our journey, we have constantly strived to follow our beliefs, delivering the finest quality custom apparel to our customers at affordable rates. What sets us apart from our competitors is our belief in using cutting-edge technology to bring your ideas to life. We are thrilled to craft custom apparel that leaves our customers extremely satisfied. </p>
            <div class="btn-contleft"><a class="btn" href="/Contact"> PARTNER WITH US <span class="line-1"></span><span class="line-2"></span><span class="line-3"></span><span class="line-4"></span></a></div>
        </div>
        <div className='col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12 text-center'>
           <img src={choose3} className='jour' alt='' />
        </div>

        </div>
    </div>
</section>


    </div>
</section>


<section className='value'>
    <div className='container'>
        <div className='row'>

        <div className='row' id='two'>
      <div className='col-xxl-3 col-xl-3 col-md-3 col-lg-3 col-md-6 col-12 text-center'>
        <div className='about-sec'>
            <div className='abt-content'>
            <img src={ab1} className='topquality' alt='' />
                <h4>Quality Excellence </h4>
                <p>We are committed to delivering customized apparel of the finest quality, ensuring the highest level of comfort and better performance.</p>
            </div>
        </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-md-3 col-lg-3 col-md-6 col-12 text-center'>
        <div className='about-sec'>
            <div className='abt-content'>
            <img src={ab2} className='topquality' alt='' />
                <h4>Innovation</h4>
                <p>We thrive on embracing a culture of continuous innovation to stay ahead of the latest design and printing trends.</p>
            </div>
        </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-md-3 col-lg-3 col-md-6 col-12 text-center'>
        <div className='about-sec'>
            <div className='abt-content'>
            <img src={ab4} className='topquality' alt='' />
                <h4>Customer-Centric </h4>
                <p>Our customers are the center of our every decision. We constantly strive to understand their needs and exceed their expectations.</p>

            </div>
        </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-md-3 col-lg-3 col-md-6 col-12 text-center'>
        <div className='about-sec'>
            <div className='abt-content'>
            <img src={ab3} className='topquality' alt='' />
                <h4>Integrity </h4>
                <p>  We commit to upholding ethical and transparent practices in all our dealings to foster long-term relations with our customers. </p>

            </div>
        </div>
      </div>

        </div>
        </div>
    </div>
</section>


{/* <section className='abt-cta'>
    <div className='container'>
    <div className='row text-center'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <h4 class="Simplified">About Bcurri</h4>
            <h2>Playing sports like there is no tomorrow</h2>
            </div>
            </div>
        <div className='row'>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'></div>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'></div>
        </div>
    </div>
</section> */}



<Footer_v1 />
</>
)
}

export default Aboutus

