import React from 'react';
import { Helmet } from 'react-helmet';

import top1front from "../../../images/sports/products/full-sleeves/1/top1front.png";
import top1back from "../../../images/sports/products/full-sleeves/1/top1back.png";
import top2front from "../../../images/sports/products/full-sleeves/2/top2front.png";
import top2back from "../../../images/sports/products/full-sleeves/2/top2back.png";
import top3front from "../../../images/sports/products/full-sleeves/3/top3front.png";
import top3back from "../../../images/sports/products/full-sleeves/3/top3back.png";
import top4front from "../../../images/sports/products/full-sleeves/4/top4front.png";
import top4back from "../../../images/sports/products/full-sleeves/4/top4back.png";
import top5front from "../../../images/sports/products/full-sleeves/5/top5front.png";
import top5back from "../../../images/sports/products/full-sleeves/5/top5back.png";


const Corelayerfullsleevessport = () => {
    return (
<>

<Helmet>
<title>Corelayer Fullsleeves - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>


{/* Rugby top */}

<section className='cloth-rugby4' id='top'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Corelayer Fullsleeves</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Corelayer-Fullsleeves1" className="image">
        <img src={top1front} className="pic-1" alt='' />
        <img src={top1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Corelayer-Fullsleeves1">Corelayer Fullsleeves 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Corelayer-Fullsleeves2" className="image">
        <img src={top2front} className="pic-1" alt='' />
        <img src={top2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Corelayer-Fullsleeves2">Corelayer Fullsleeves 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Corelayer-Fullsleeves3" className="image">
        <img src={top3front} className="pic-1" alt='' />
        <img src={top3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Corelayer-Fullsleeves3">Corelayer Fullsleeves 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Corelayer-Fullsleeves4" className="image">
        <img src={top4front} className="pic-1" alt='' />
        <img src={top4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Corelayer-Fullsleeves4">Corelayer Fullsleeves 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Corelayer-Fullsleeves5" className="image">
        <img src={top5front} className="pic-1" alt='' />
        <img src={top5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Corelayer-Fullsleeves5">Corelayer Fullsleeves 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>
 
   </div>
   <br/>

   
    

    </div>
</section>



</>
)
}

export default Corelayerfullsleevessport

