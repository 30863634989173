
import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Rugbyshortsform = () => {
    const [validationErrors, setValidationErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        // Add validation logic here
        const selectColor1 = event.target.selectcolor1.value;
        const selectColor2 = event.target.selectcolor2.value;
        const selectColor3 = event.target.selectcolor3.value;
        const selectpocket = event.target.selectpocket.value;
    
        // Validation logic
        const errors = {};
        if (selectColor1 === 'Select Color 1' ) {
          errors.color1 = 'Please select color 1';
        }
        if (selectColor2 === 'Select Color 2' ) {
          errors.color2 = 'Please select color 2';
        }
        if (selectColor3 === 'Select Color 3' ) {
          errors.color3 = 'Please select color 3';
        }
        if (selectpocket === 'Select Pocket') {
          errors.pocket = 'Please select pocket';
        }

        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return; // Prevent form submission if validation fails
        }

        // Reset validation errors
        setValidationErrors({});

        const formData = new FormData(event.target);

        event.target.submit();

      };

       // Get the current page slug
  const currentSlug = window.location.pathname.split('/').pop();


  return (
 <>

       <form action="rugbyshorts.php"
                      method="post"
                      encType="multipart/form-data"
                      id="jobdetil"
                      onSubmit={handleSubmit}>
                        <div class="row">
                            <div class="col-sm-6">
                                {/* <label class="label" for="name">Name</label> */}
                                <input type="text" name="fname" class="form-control1 prod" placeholder='First Name'required />  
                            </div>
                            <div class="col-sm-6">
                                <input type="text" name="lname" class="form-control1 prod" placeholder='Last Name' />  
                            </div>
                            <div class="col-sm-6">
                            <input type="tel" name="phone" class="form-control1 prod" placeholder='Phone No.' required />
                            </div>
                            <div class="col-sm-6">
                                <input type="email" name="email" class="form-control1 prod" placeholder='Email Id' required />
                            </div>

                            <div class="col-sm-6">
                            <select name="selectpocket" class="form-control1 prod" id="Country" required>
                                <option>Select Pocket</option>
                                <option value="With Pockets">With Pockets</option>
                                <option value="Without Pockets" >Without Pockets</option>
                              </select>
                              {validationErrors.pocket && <p className="error">{validationErrors.pocket}</p>}
                            </div>

                            
                            <div class="col-sm-6">
                            <select name="selectcolor1" class="form-control1 prod" id="Country" required>
                                <option>Select Color 1</option>
                                <option value="black" name="Black">Black</option>
                                <option value="white" name="White">White</option>
                                <option value="canaryyellow" name="Canary Yellow">Canary Yellow</option>
                                <option value="amberyellow" name="Amber Yellow">Amber Yellow</option>
                                <option value="royalblue" name="Royal Blue">Royal Blue</option>
                                <option value="skyblue" name="Sky Blue">Sky Blue</option>
                                <option value="navy" name="Navy">Navy</option>
                                <option value="red" name="Red">Red</option>
                                <option value="emeraldgreen" name="Emerald Green">Emerald Green</option>
                                <option value="bottlegreen" name="Bottle Green">Bottle Green</option>
                                <option value="grey" name="Grey">Grey</option>
                                <option value="orange" name="Orange">Orange</option>
                                <option value="purple" name="Purple">Purple</option>
                                <option value="brown" name="Brown">Brown</option>
                                <option value="pink" name="Pink">Pink</option>
                                <option value="hotpink" name="Hot Pink">Hot Pink</option>
                                <option value="cyan" name="Cyan">Cyan</option>
                                </select>
                                {validationErrors.color1 && <p className="error">{validationErrors.color1}</p>}
                            </div>

                            <div class="col-sm-6">
                            <select name="selectcolor2" class="form-control1 prod" id="Country" required>
                                <option>Select Color 2</option>
                                <option value="black" name="Black">Black</option>
                                <option value="white" name="White">White</option>
                                <option value="canaryyellow" name="Canary Yellow">Canary Yellow</option>
                                <option value="amberyellow" name="Amber Yellow">Amber Yellow</option>
                                <option value="royalblue" name="Royal Blue">Royal Blue</option>
                                <option value="skyblue" name="Sky Blue">Sky Blue</option>
                                <option value="navy" name="Navy">Navy</option>
                                <option value="red" name="Red">Red</option>
                                <option value="emeraldgreen" name="Emerald Green">Emerald Green</option>
                                <option value="bottlegreen" name="Bottle Green">Bottle Green</option>
                                <option value="grey" name="Grey">Grey</option>
                                <option value="orange" name="Orange">Orange</option>
                                <option value="purple" name="Purple">Purple</option>
                                <option value="brown" name="Brown">Brown</option>
                                <option value="pink" name="Pink">Pink</option>
                                <option value="hotpink" name="Hot Pink">Hot Pink</option>
                                <option value="cyan" name="Cyan">Cyan</option>
                                </select>
                                {validationErrors.color2 && <p className="error">{validationErrors.color2}</p>}
                            </div>

                            <div class="col-sm-6">
                            <select name="selectcolor3" class="form-control1 prod" id="Country" required>
                                <option>Select Color 3</option>
                                <option value="black" name="Black">Black</option>
                                <option value="white" name="White">White</option>
                                <option value="canaryyellow" name="Canary Yellow">Canary Yellow</option>
                                <option value="amberyellow" name="Amber Yellow">Amber Yellow</option>
                                <option value="royalblue" name="Royal Blue">Royal Blue</option>
                                <option value="skyblue" name="Sky Blue">Sky Blue</option>
                                <option value="navy" name="Navy">Navy</option>
                                <option value="red" name="Red">Red</option>
                                <option value="emeraldgreen" name="Emerald Green">Emerald Green</option>
                                <option value="bottlegreen" name="Bottle Green">Bottle Green</option>
                                <option value="grey" name="Grey">Grey</option>
                                <option value="orange" name="Orange">Orange</option>
                                <option value="purple" name="Purple">Purple</option>
                                <option value="brown" name="Brown">Brown</option>
                                <option value="pink" name="Pink">Pink</option>
                                <option value="hotpink" name="Hot Pink">Hot Pink</option>
                                <option value="cyan" name="Cyan">Cyan</option>
                                </select>
                                {validationErrors.color3 && <p className="error">{validationErrors.color3}</p>}
                            </div>
                    
                            <div class="col-sm-12">
                            <label class="fieldlabels1">Upload Logo</label>
                            <input type="file" name="file" required/>
                            </div>
                            
                          
                        </div>
                        <input type="hidden" name="pageSlug" value={currentSlug} />
                        <button class="btn btn-block product" type="submit">Submit Now</button>
       </form>

 </>
    );
}
 
export default Rugbyshortsform;