import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../../global-components/navbar-v4';
import Footer_v1 from '../../global-components/footer';
import Formmodal from '../../section-components/formmodal';
import shirt11front from '../../../images/sports/products/netball-tshirts/11/shirt11front.png';
import shirt11back from '../../../images/sports/products/netball-tshirts/11/shirt11back.png';



const Netballtshirt11 = () => {
  const smallImages = [shirt11front, shirt11back, shirt11front, shirt11back];

  const [productImage, setProductImage] = useState(smallImages[0]);

  const handleSmallImageClick = (src) => {
    setProductImage(src);
  };

  return (
    <>
      <NavbarV4 />

      <Helmet>
        <title>Netball Top</title>
        <meta name="description" content="" />
      </Helmet>

      <section className='bnnr-product-pg'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
              <h2>Netball Top</h2>
            </div>
          </div>
        </div>
      </section>

      <section className='rugby-first-p1'>
        <div className='small-container single-product'>
          <div className='row'>
            <div className='col-2 text-center'>
              <img src={productImage} alt='' width='110%' id='ProductImg' />

              <div className='small-img-row'>
                {smallImages.map((image, index) => (
                  <div key={index} className='small-img-col'>
                    <img
                      src={image}
                      alt=''
                      width='110%'
                      className='small-img'
                      onClick={() => handleSmallImageClick(image)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='col-2'>
            <h5>Bcurri Apparel</h5>
              <h2>Netball Top 11</h2>
              {/* <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias,
                accusamus soluta quasi illum, deserunt ut iste rem obcaecati
                inventore, est harum repellendus fuga velit odio sint officia
                corrupti eum perspiciatis.
              </p> */}

         
              <form action="rugbyshorts.php"
                      method="post"
                      encType="multipart/form-data"
                      id="jobdetil">
                        <div class="row">
                            <div class="col-sm-6">
                                {/* <label class="label" for="name">Name</label> */}
                                <input type="text" name="fname" class="form-control1 prod" placeholder='First Name'required />  
                            </div>
                            <div class="col-sm-6">
                                <input type="text" name="lname" class="form-control1 prod" placeholder='Last Name' />  
                            </div>
                            <div class="col-sm-6">
                            <input type="tel" name="phone" class="form-control1 prod" placeholder='Phone No.' required />
                            </div>
                            <div class="col-sm-6">
                                <input type="email" name="email" class="form-control1 prod" placeholder='Email Id' required />
                            </div>

                            <div class="col-sm-6">
                            <select name="selectcollar" class="form-control1 prod" id="Country" required>
                                <option>Select Collar</option>
                                <option value="Self Collar with Stripe" name="Self Collar with Stripe">Self Collar with Stripe</option>
                                <option value="Collar with V- Placket" name="Collar with V- Placket">Collar with V- Placket</option>
                                <option value="Round Neck Collar" name="Round Neck Collar">Round Neck Collar</option>
                                <option value="V-Neck Collar" name="V-Neck Collar">V-Neck Collar</option>
                              </select>
                            </div>
                            
                            <div class="col-sm-6">
                            <select name="selectcolor1" class="form-control1 prod" id="Country" required>
                                <option>Select Color 1</option>
                                <option value="black" name="Black">Black</option>
                                <option value="white" name="White">White</option>
                                <option value="canaryyellow" name="Canary Yellow">Canary Yellow</option>
                                <option value="amberyellow" name="Amber Yellow">Amber Yellow</option>
                                <option value="royalblue" name="Royal Blue">Royal Blue</option>
                                <option value="skyblue" name="Sky Blue">Sky Blue</option>
                                <option value="navy" name="Navy">Navy</option>
                                <option value="red" name="Red">Red</option>
                                <option value="emeraldgreen" name="Emerald Green">Emerald Green</option>
                                <option value="bottlegreen" name="Bottle Green">Bottle Green</option>
                                <option value="grey" name="Grey">Grey</option>
                                <option value="orange" name="Orange">Orange</option>
                                <option value="purple" name="Purple">Purple</option>
                                <option value="brown" name="Brown">Brown</option>
                                <option value="pink" name="Pink">Pink</option>
                                <option value="hotpink" name="Hot Pink">Hot Pink</option>
                                <option value="cyan" name="Cyan">Cyan</option>
                                </select>
                            </div>

                            <div class="col-sm-6">
                            <select name="selectcolor2" class="form-control1 prod" id="Country" required>
                                <option>Select Color 2</option>
                                <option value="black" name="Black">Black</option>
                                <option value="white" name="White">White</option>
                                <option value="canaryyellow" name="Canary Yellow">Canary Yellow</option>
                                <option value="amberyellow" name="Amber Yellow">Amber Yellow</option>
                                <option value="royalblue" name="Royal Blue">Royal Blue</option>
                                <option value="skyblue" name="Sky Blue">Sky Blue</option>
                                <option value="navy" name="Navy">Navy</option>
                                <option value="red" name="Red">Red</option>
                                <option value="emeraldgreen" name="Emerald Green">Emerald Green</option>
                                <option value="bottlegreen" name="Bottle Green">Bottle Green</option>
                                <option value="grey" name="Grey">Grey</option>
                                <option value="orange" name="Orange">Orange</option>
                                <option value="purple" name="Purple">Purple</option>
                                <option value="brown" name="Brown">Brown</option>
                                <option value="pink" name="Pink">Pink</option>
                                <option value="hotpink" name="Hot Pink">Hot Pink</option>
                                <option value="cyan" name="Cyan">Cyan</option>
                                </select>
                            </div>

                            <div class="col-sm-6">
                            <select name="selectcolor3" class="form-control1 prod" id="Country" required>
                                <option>Select Color 3</option>
                                <option value="black" name="Black">Black</option>
                                <option value="white" name="White">White</option>
                                <option value="canaryyellow" name="Canary Yellow">Canary Yellow</option>
                                <option value="amberyellow" name="Amber Yellow">Amber Yellow</option>
                                <option value="royalblue" name="Royal Blue">Royal Blue</option>
                                <option value="skyblue" name="Sky Blue">Sky Blue</option>
                                <option value="navy" name="Navy">Navy</option>
                                <option value="red" name="Red">Red</option>
                                <option value="emeraldgreen" name="Emerald Green">Emerald Green</option>
                                <option value="bottlegreen" name="Bottle Green">Bottle Green</option>
                                <option value="grey" name="Grey">Grey</option>
                                <option value="orange" name="Orange">Orange</option>
                                <option value="purple" name="Purple">Purple</option>
                                <option value="brown" name="Brown">Brown</option>
                                <option value="pink" name="Pink">Pink</option>
                                <option value="hotpink" name="Hot Pink">Hot Pink</option>
                                <option value="cyan" name="Cyan">Cyan</option>
                                </select>
                            </div>
                    
                          
                        </div>

                        <button class="btn btn-block product" type="submit">Submit Now</button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer_v1 />
    </>
  );
};

export default Netballtshirt11;
