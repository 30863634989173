import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../../global-components/navbar-v4';
import Footer_v1 from '../../global-components/footer';
import Formmodal from '../../section-components/formmodal';
import cutshorts10front from '../../../images/sports/products/rugby-cut-sew-shorts/10/cutshorts10front.png';
import cutshorts10back from '../../../images/sports/products/rugby-cut-sew-shorts/10/cutshorts10back.png';



const RugbyCutSewshorts10 = () => {
  const smallImages = [cutshorts10front, cutshorts10back, cutshorts10front, cutshorts10back];

  const [productImage, setProductImage] = useState(smallImages[0]);

  const handleSmallImageClick = (src) => {
    setProductImage(src);
  };

  return (
    <>
      <NavbarV4 />

      <Helmet>
        <title>Bcurri Sports</title>
        <meta name="description" content="" />
      </Helmet>

      <section className='bnnr-product-pg'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
              <h2>Bcurri Sports</h2>
            </div>
          </div>
        </div>
      </section>

      <section className='rugby-first-p1'>
        <div className='small-container single-product'>
          <div className='row'>
            <div className='col-2 text-center'>
              <img src={productImage} alt='' width='100%' id='ProductImg' />

              <div className='small-img-row'>
                {smallImages.map((image, index) => (
                  <div key={index} className='small-img-col'>
                    <img
                      src={image}
                      alt=''
                      width='100%'
                      className='small-img'
                      onClick={() => handleSmallImageClick(image)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='col-2'>
              <p>Bcurri Apparel</p>
              <h2>Rugby Jersey</h2>
              <h3>Product Details</h3>
              <br />
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias,
                accusamus soluta quasi illum, deserunt ut iste rem obcaecati
                inventore, est harum repellendus fuga velit odio sint officia
                corrupti eum perspiciatis.
              </p>
 <fieldset class="swatch-picker">
  <p>Select Size</p>
<label>
  <input type="radio" name="swatch_1234" value="blue" /> XXL
  <span>XXL</span>
</label>
<label>
  <input type="radio" name="swatch_1234" value="gray" /> XL
  <span>XL</span>
</label>  
<label>  
  <input type="radio" name="swatch_1234" value="black" /> L
  <span>L</span>
</label>
<label>  
  <input type="radio" name="swatch_1234" value="black" /> M
  <span>M</span>
</label>
<label>  
  <input type="radio" name="swatch_1234" value="black" /> S
  <span>S</span>
</label>
</fieldset>



                
              <Formmodal />
            </div>
          </div>
        </div>
      </section>

      <Footer_v1 />
    </>
  );
};

export default RugbyCutSewshorts10;
