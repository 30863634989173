import React from 'react';
import { Helmet } from 'react-helmet';
import jacket1front from "../../../images/sports/products/Fleece-jacket/1/jacket1front.png";
import jacket1back from "../../../images/sports/products/Fleece-jacket/1/jacket1back.png";
import jacket2front from "../../../images/sports/products/Fleece-jacket/2/jacket2front.png";
import jacket2back from "../../../images/sports/products/Fleece-jacket/2/jacket2back.png";
import jacket3front from "../../../images/sports/products/Fleece-jacket/3/jacket3front.png";
import jacket3back from "../../../images/sports/products/Fleece-jacket/3/jacket3back.png";
import jacket4front from "../../../images/sports/products/Fleece-jacket/4/jacket4front.png";
import jacket4back from "../../../images/sports/products/Fleece-jacket/4/jacket4back.png";
import jacket5front from "../../../images/sports/products/Fleece-jacket/5/jacket5front.png";
import jacket5back from "../../../images/sports/products/Fleece-jacket/5/jacket5back.png";
import jacket6front from "../../../images/sports/products/Fleece-jacket/6/jacket6front.png";
import jacket6back from "../../../images/sports/products/Fleece-jacket/6/jacket6back.png";
import jacket7front from "../../../images/sports/products/Fleece-jacket/7/jacket7front.png";
import jacket7back from "../../../images/sports/products/Fleece-jacket/7/jacket7back.png";
import jacket8front from "../../../images/sports/products/Fleece-jacket/8/jacket8front.png";
import jacket8back from "../../../images/sports/products/Fleece-jacket/8/jacket8back.png";
import jacket9front from "../../../images/sports/products/Fleece-jacket/9/jacket9front.png";
import jacket9back from "../../../images/sports/products/Fleece-jacket/9/jacket9back.png";
import jacket10front from "../../../images/sports/products/Fleece-jacket/10/jacket10front.png";
import jacket10back from "../../../images/sports/products/Fleece-jacket/10/jacket10back.png";



const Fleecejacketsport = () => {
    return (
<>

<Helmet>
<title>Fleece Jackets - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

{/* Rugby jacket */}

<section className='cloth-rugby4' id='Fleece-jacket'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Fleece Jackets</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Fleece-jacket1" className="image">
        <img src={jacket1front} className="pic-1" alt='' />
        <img src={jacket1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Fleece-jacket1">Fleece Jacket 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Fleece-jacket2" className="image">
        <img src={jacket2front} className="pic-1" alt='' />
        <img src={jacket2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Fleece-jacket2">Fleece Jacket 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Fleece-jacket3" className="image">
        <img src={jacket3front} className="pic-1" alt='' />
        <img src={jacket3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Fleece-jacket3">Fleece Jacket 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Fleece-jacket4" className="image">
        <img src={jacket4front} className="pic-1" alt='' />
        <img src={jacket4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Fleece-jacket4">Fleece Jacket 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Fleece-jacket5" className="image">
        <img src={jacket5front} className="pic-1" alt='' />
        <img src={jacket5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Fleece-jacket5">Fleece Jacket 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Fleece-jacket6" className="image">
        <img src={jacket6front} className="pic-1" alt='' />
        <img src={jacket6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Fleece-jacket6">Fleece Jacket 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Fleece-jacket7" className="image">
        <img src={jacket7front} className="pic-1" alt='' />
        <img src={jacket7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Fleece-jacket7">Fleece Jacket 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Fleece-jacket8" className="image">
        <img src={jacket8front} className="pic-1" alt='' />
        <img src={jacket8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Fleece-jacket8">Fleece Jacket 8</a>
        </h3>
  </div>
 
   </div>
    <br/>

      {/* Row 3 */}
      <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Fleece-jacket9" className="image">
        <img src={jacket9front} className="pic-1" alt='' />
        <img src={jacket9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Fleece-jacket9">Fleece Jacket 9</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Fleece-jacket10" className="image">
        <img src={jacket10front} className="pic-1" alt='' />
        <img src={jacket10back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Fleece-jacket10">Fleece Jacket 10</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
 
     </div>
    <br/>

   
    

    </div>
</section>



</>
)
}

export default Fleecejacketsport

