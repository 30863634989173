import React from 'react';
import { Helmet } from 'react-helmet';


import shorts1front from "../../../images/sports/products/rugby-shorts/1/shorts1front.png";
import shorts1back from "../../../images/sports/products/rugby-shorts/1/shorts1back.png";
import shorts2front from "../../../images/sports/products/rugby-shorts/2/shorts2front.png";
import shorts2back from "../../../images/sports/products/rugby-shorts/2/shorts2back.png";
import shorts3front from "../../../images/sports/products/rugby-shorts/3/shorts3front.png";
import shorts3back from "../../../images/sports/products/rugby-shorts/3/shorts3back.png";
import shorts4front from "../../../images/sports/products/rugby-shorts/4/shorts4front.png";
import shorts4back from "../../../images/sports/products/rugby-shorts/4/shorts4back.png";
import shorts5front from "../../../images/sports/products/rugby-shorts/5/shorts5front.png";
import shorts5back from "../../../images/sports/products/rugby-shorts/5/shorts5back.png";
import shorts6front from "../../../images/sports/products/rugby-shorts/6/shorts6front.png";
import shorts6back from "../../../images/sports/products/rugby-shorts/6/shorts6back.png";
import shorts7front from "../../../images/sports/products/rugby-shorts/7/shorts7front.png";
import shorts7back from "../../../images/sports/products/rugby-shorts/7/shorts7back.png";
import shorts8front from "../../../images/sports/products/rugby-shorts/8/shorts8front.png";
import shorts8back from "../../../images/sports/products/rugby-shorts/8/shorts8back.png";
import shorts9front from "../../../images/sports/products/rugby-shorts/9/shorts9front.png";
import shorts9back from "../../../images/sports/products/rugby-shorts/9/shorts9back.png";
import shorts10front from "../../../images/sports/products/rugby-shorts/10/shorts10front.png";
import shorts10back from "../../../images/sports/products/rugby-shorts/10/shorts10back.png";
import shorts11front from "../../../images/sports/products/rugby-shorts/11/shorts11front.png";
import shorts11back from "../../../images/sports/products/rugby-shorts/11/shorts11back.png";
import shorts12front from "../../../images/sports/products/rugby-shorts/12/shorts12front.png";
import shorts12back from "../../../images/sports/products/rugby-shorts/12/shorts12back.png";
import shorts13front from "../../../images/sports/products/rugby-shorts/13/shorts13front.png";
import shorts13back from "../../../images/sports/products/rugby-shorts/13/shorts13back.png";
import shorts14front from "../../../images/sports/products/rugby-shorts/14/shorts14front.png";
import shorts14back from "../../../images/sports/products/rugby-shorts/14/shorts14back.png";
import shorts15front from "../../../images/sports/products/rugby-shorts/15/shorts15front.png";
import shorts15back from "../../../images/sports/products/rugby-shorts/15/shorts15back.png";
import shorts16front from "../../../images/sports/products/rugby-shorts/16/shorts16front.png";
import shorts16back from "../../../images/sports/products/rugby-shorts/16/shorts16back.png";
import shorts17front from "../../../images/sports/products/rugby-shorts/17/shorts17front.png";
import shorts17back from "../../../images/sports/products/rugby-shorts/17/shorts17back.png";
import shorts18front from "../../../images/sports/products/rugby-shorts/18/shorts18front.png";
import shorts18back from "../../../images/sports/products/rugby-shorts/18/shorts18back.png";
import shorts19front from "../../../images/sports/products/rugby-shorts/19/shorts19front.png";
import shorts19back from "../../../images/sports/products/rugby-shorts/19/shorts19back.png";
import shorts20front from "../../../images/sports/products/rugby-shorts/20/shorts20front.png";
import shorts20back from "../../../images/sports/products/rugby-shorts/20/shorts20back.png";


const CutSewRugbyShortssport = () => {
    return (
<>

<Helmet>
<title>Rugby Shorts - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>


{/* Cut & Sew Rugby Shorts */}

<section className='cloth-rugby3' id='Sublimation-Rugby-Shorts'>
    <div className='container'>
    <div className='row'>
      <div className='people'>
        <h2>Rugby Shorts</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
      </div>
    </div>


    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts1" className="image">
        <img src={shorts1front} className="pic-1" alt='' />
        <img src={shorts1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="Rugby-Shorts1">Rugby Shorts 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts2" className="image">
        <img src={shorts2front} className="pic-1" alt='' />
        <img src={shorts2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
       <h3 className="title-product">
          <a href="/Rugby-Shorts2">Rugby Shorts 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts3" className="image">
        <img src={shorts3front} className="pic-1" alt='' />
        <img src={shorts3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
        <h3 className="title-product">
           <a href="/Rugby-Shorts3">Rugby Shorts 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts4" className="image">
        <img src={shorts4front} className="pic-1" alt='' />
        <img src={shorts4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
        <h3 className="title-product">
            <a href="/Rugby-Shorts4">Rugby Shorts 4</a>
        </h3>
  </div>
 
   </div>

  <br/>

      {/* Row 2 */}
      <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts5" className="image">
        <img src={shorts5front} className="pic-1" alt='' />
        <img src={shorts5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts5">Rugby Shorts 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts6" className="image">
        <img src={shorts6front} className="pic-1" alt='' />
        <img src={shorts6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
       <h3 className="title-product">
          <a href="/Rugby-Shorts6">Rugby Shorts 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts7" className="image">
        <img src={shorts7front} className="pic-1" alt='' />
        <img src={shorts7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
        <h3 className="title-product">
           <a href="/Rugby-Shorts7">Rugby Shorts 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts8" className="image">
        <img src={shorts8front} className="pic-1" alt='' />
        <img src={shorts8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
        <h3 className="title-product">
            <a href="/Rugby-Shorts8">Rugby Shorts 8</a>
        </h3>
  </div>
 
      </div>

  <br/>

    {/* Row 3 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts9" className="image">
        <img src={shorts9front} className="pic-1" alt='' />
        <img src={shorts9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts9">Rugby Shorts 9</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts10" className="image">
        <img src={shorts10front} className="pic-1" alt='' />
        <img src={shorts10back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
       <h3 className="title-product">
          <a href="/Rugby-Shorts10">Rugby Shorts 10</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
           <a href="/Rugby-Shorts11" className="image">
           <img src={shorts11front} className="pic-1" alt='' />
           <img src={shorts11back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts11">Rugby Shorts 11</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
    <div className="product-image">
           <a href="/Rugby-Shorts12" className="image">
           <img src={shorts12front} className="pic-1" alt='' />
           <img src={shorts12back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts12">Rugby Shorts 12</a>
        </h3>
  </div>
 
   </div>


   <br/>
{/* Row 4 */}
<div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts13" className="image">
        <img src={shorts13front} className="pic-1" alt='' />
        <img src={shorts13back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts13">Rugby Shorts 13</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
           <a href="/Rugby-Shorts14" className="image">
           <img src={shorts14front} className="pic-1" alt='' />
           <img src={shorts14back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts14">Rugby Shorts 14</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
           <a href="/Rugby-Shorts15" className="image">
           <img src={shorts15front} className="pic-1" alt='' />
           <img src={shorts15back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts15">Rugby Shorts 15</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
    <div className="product-image">
           <a href="/Rugby-Shorts16" className="image">
           <img src={shorts16front} className="pic-1" alt='' />
           <img src={shorts16back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts16">Rugby Shorts 16</a>
        </h3>
  </div>
 
</div>


<br/>
{/* Row 5 */}
<div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts17" className="image">
        <img src={shorts17front} className="pic-1" alt='' />
        <img src={shorts17back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts17">Rugby Shorts 17</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
           <a href="/Rugby-Shorts18" className="image">
           <img src={shorts18front} className="pic-1" alt='' />
           <img src={shorts18back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts18">Rugby Shorts 18</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts19" className="image">
        <img src={shorts19front} className="pic-1" alt='' />
        <img src={shorts19back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Rugby-Shorts19">Rugby Shorts 19</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Rugby-Shorts20" className="image">
        <img src={shorts20front} className="pic-1" alt='' />
        <img src={shorts20back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
       <h3 className="title-product">
          <a href="/Rugby-Shorts20">Rugby Shorts 20</a>
        </h3>
  </div>
 
</div>


<br/>



    </div>
</section>







</>
)
}

export default CutSewRugbyShortssport

