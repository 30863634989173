import { Helmet } from 'react-helmet';
import React from 'react';

import ladiesvest1front from "../../../images/sports/products/ladies-vest/1/ladiesvest1front.png";
import ladiesvest1back from "../../../images/sports/products/ladies-vest/1/ladiesvest1back.png";
import ladiesvest2front from "../../../images/sports/products/ladies-vest/2/ladiesvest2front.png";
import ladiesvest2back from "../../../images/sports/products/ladies-vest/2/ladiesvest2back.png";
import ladiesvest3front from "../../../images/sports/products/ladies-vest/3/ladiesvest3front.png";
import ladiesvest3back from "../../../images/sports/products/ladies-vest/3/ladiesvest3back.png";
import ladiesvest4front from "../../../images/sports/products/ladies-vest/4/ladiesvest4front.png";
import ladiesvest4back from "../../../images/sports/products/ladies-vest/4/ladiesvest4back.png";
import ladiesvest5front from "../../../images/sports/products/ladies-vest/5/ladiesvest5front.png";
import ladiesvest5back from "../../../images/sports/products/ladies-vest/5/ladiesvest5back.png";
import ladiesvest6front from "../../../images/sports/products/ladies-vest/6/ladiesvest6front.png";
import ladiesvest6back from "../../../images/sports/products/ladies-vest/6/ladiesvest6back.png";
import ladiesvest7front from "../../../images/sports/products/ladies-vest/7/ladiesvest7front.png";
import ladiesvest7back from "../../../images/sports/products/ladies-vest/7/ladiesvest7back.png";
import ladiesvest8front from "../../../images/sports/products/ladies-vest/8/ladiesvest8front.png";
import ladiesvest8back from "../../../images/sports/products/ladies-vest/8/ladiesvest8back.png";
import ladiesvest9front from "../../../images/sports/products/ladies-vest/9/ladiesvest9front.png";
import ladiesvest9back from "../../../images/sports/products/ladies-vest/9/ladiesvest9back.png";
import ladiesvest10front from "../../../images/sports/products/ladies-vest/10/ladiesvest10front.png";
import ladiesvest10back from "../../../images/sports/products/ladies-vest/10/ladiesvest10back.png";



const LadiesVestsport = () => {
    return (
<>

<Helmet>
<title>Ladies Vest- Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

 {/* Start Slider area  */}
<section className='cloth-rugby' id='Hockey-Shirts'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Ladies Vests</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>


     {/* Row 1 */}
     <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/ladies-vest1" className="image">
        <img src={ladiesvest1front} className="pic-1" alt='' />
        <img src={ladiesvest1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/ladies-vest1">Ladies Vest 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/ladies-vest2" className="image">     
          <img src={ladiesvest2front} className="pic-1" alt='' />
          <img src={ladiesvest2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/ladies-vest2">Ladies Vest 2</a>
     </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
      <a href="/ladies-vest3" className="image">     
          <img src={ladiesvest3front} className="pic-1" alt='' />
          <img src={ladiesvest3back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/ladies-vest3">Ladies Vest 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
      <a href="/ladies-vest4" className="image">     
          <img src={ladiesvest4front} className="pic-1" alt='' />
          <img src={ladiesvest4back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/ladies-vest4">Ladies Vest 4</a>
        </h3>
  </div>
 
   </div>

  <br/>
     {/* Row 2 */}
     <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/ladies-vest5" className="image">
        <img src={ladiesvest5front} className="pic-1" alt='' />
        <img src={ladiesvest5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/ladies-vest5">Ladies Vest 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/ladies-vest6" className="image">     
          <img src={ladiesvest6front} className="pic-1" alt='' />
          <img src={ladiesvest6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/ladies-vest6">Ladies Vest 6</a>
     </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
      <a href="/ladies-vest7" className="image">     
          <img src={ladiesvest7front} className="pic-1" alt='' />
          <img src={ladiesvest7back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/ladies-vest7">Ladies Vest 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
      <a href="/ladies-vest8" className="image">     
          <img src={ladiesvest8front} className="pic-1" alt='' />
          <img src={ladiesvest8back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/ladies-vest8">Ladies Vest 8</a>
        </h3>
  </div>
 
   </div>

   <br/>
     {/* Row 3 */}
     <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/ladies-vest9" className="image">
        <img src={ladiesvest9front} className="pic-1" alt='' />
        <img src={ladiesvest9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/ladies-vest9">Ladies Vest 9</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/ladies-vest10" className="image">     
          <img src={ladiesvest10front} className="pic-1" alt='' />
          <img src={ladiesvest10back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
     <h3 className="title-product">
        <a href="/ladies-vest10">Ladies Vest 10</a>
     </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>
 
   </div>






    </div>
</section>


</>
)
}

export default LadiesVestsport

