import React, { useRef, useState } from "react";
import football2 from  "../../images/sports/products/product1.png";
import cricket from  "../../images/sports/products/product2.png";
import rubgy from "../../images/sports/products/product3.png";
import hockey from "../../images/sports/products/product4.png";
import netball from  "../../images/sports/products/product1.png";
import football from "../../images/sports/products/product2.png";

import about1 from "../../images/sports/about1.png";
import ladiessize from "../../images/sports/ladiessize.png";



import { HiMiniStar, HiOutlineStar } from "react-icons/hi2";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const Ladiessize = () => {
  return (
    <>

      <div className="container">
      <div className='row text-center'>

      <div className="Sportcatagries-wrapper sizesec align-items-center">
      {/* <h3 className='seldesign'>Men's Size Guide</h3> */}
<div className="sg-ctn0">
  <div className="return_dialog_bg" />
  <div className="sg-ctn1">
    <div className="hidectn-mask" onclick="hideReference()" />
    <div className="sg-ctn">
      <div className="sg-ctn2 j-sg-ctn">
        <div className="sg-title"></div>
        {/* <h3 className='seldesign'>Size Guide</h3> */}
        {/* <div className="sg-close iconfont" onclick="hideReference()">
          
        </div> */}


{/* Ladies size */}
        <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
           <div className="sg-table-wrap">
           <h3 className='sizesub'>Ladies Top</h3>
           <div className="units-opt">
            <label className="she-radioed j-show-inch">
              <i />
            </label>
            <span>(Inches)</span>
          </div>
          <table
            className="sg-table j-cm-table"
            cellSpacing={0}
            cellPadding={0}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right sizehead">Size</td>
                <td className="border-right sizehead1">Chest Size</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">8</td>
                <td className="border-right">28-29.50</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">10</td>
                <td className="border-right">30-31.50</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">12</td>
                <td className="border-right">32-33.50</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">14</td>
                <td className="border-right">34-35.50</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">16</td>
                <td className="border-right">36-37.50</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">18</td>
                <td className="border-right">38-39.50</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">20</td>
                <td className="border-right">40-41.50</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">22</td>
                <td className="border-right">42-43.50</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">24</td>
                <td className="border-right">44-45.50</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">26</td>
                <td className="border-right">46-47.50</td>
              </tr>
            </tbody>
          </table>
          <table
            className="sg-table j-inch-table"
            cellSpacing={0}
            cellPadding={0}
            style={{ display: "none" }}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right">Size</td>
                <td className="border-right">XS</td>
                <td className="border-right">S</td>
                <td className="border-right">M</td>
                <td>L</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Shoulder(cm)</td>
                <td id="_s_0" className="inch_value">
                  49cm
                </td>
                <td id="_s_1" className="inch_value">
                  50cm
                </td>
                <td id="_s_2" className="inch_value">
                  51cm
                </td>
                <td id="_s_3" className="inch_value">
                  52cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bust(cm)</td>
                <td id="_s_0" className="inch_value">
                  101cm
                </td>
                <td id="_s_1" className="inch_value">
                  105cm
                </td>
                <td id="_s_2" className="inch_value">
                  109cm
                </td>
                <td id="_s_3" className="inch_value">
                  113cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Waist Size(cm)</td>
                <td id="_s_0" className="inch_value">
                  93cm
                </td>
                <td id="_s_1" className="inch_value">
                  97cm
                </td>
                <td id="_s_2" className="inch_value">
                  101cm
                </td>
                <td id="_s_3" className="inch_value">
                  105cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  65cm
                </td>
                <td id="_s_1" className="inch_value">
                  66cm
                </td>
                <td id="_s_2" className="inch_value">
                  67cm
                </td>
                <td id="_s_3" className="inch_value">
                  68cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Sleeve Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bicep Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Cuff(cm)</td>
                <td id="_s_0" className="inch_value">
                  29cm
                </td>
                <td id="_s_1" className="inch_value">
                  30cm
                </td>
                <td id="_s_2" className="inch_value">
                  31cm
                </td>
                <td id="_s_3" className="inch_value">
                  32cm
                </td>
              </tr>
            </tbody>
          </table>
            </div>
        </div>


        <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
           <div className="sg-table-wrap">
           <h3 className='sizesub'>Ladies Bottom</h3>
           <div className="units-opt">
            <label className="she-radioed j-show-inch">
              <i />
            </label>
            <span>(Inches)</span>
          </div>
          <table
            className="sg-table j-cm-table"
            cellSpacing={0}
            cellPadding={0}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right sizehead">Size</td>
                <td className="border-right sizehead1">Waist Size </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">8</td>
                <td className="border-right">30</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">10</td>
                <td className="border-right">32</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">12</td>
                <td className="border-right">34</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">14</td>
                <td className="border-right">36</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">16</td>
                <td className="border-right">38</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">18</td>
                <td className="border-right">40</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">20</td>
                <td className="border-right">42</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">22</td>
                <td className="border-right">44</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">24</td>
                <td className="border-right">46</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">26</td>
                <td className="border-right">48</td>
              </tr>

            </tbody>
          </table>
          <table
            className="sg-table j-inch-table"
            cellSpacing={0}
            cellPadding={0}
            style={{ display: "none" }}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right">Size</td>
                <td className="border-right">XS</td>
                <td className="border-right">S</td>
                <td className="border-right">M</td>
                <td>L</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Shoulder(cm)</td>
                <td id="_s_0" className="inch_value">
                  49cm
                </td>
                <td id="_s_1" className="inch_value">
                  50cm
                </td>
                <td id="_s_2" className="inch_value">
                  51cm
                </td>
                <td id="_s_3" className="inch_value">
                  52cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bust(cm)</td>
                <td id="_s_0" className="inch_value">
                  101cm
                </td>
                <td id="_s_1" className="inch_value">
                  105cm
                </td>
                <td id="_s_2" className="inch_value">
                  109cm
                </td>
                <td id="_s_3" className="inch_value">
                  113cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Waist Size(cm)</td>
                <td id="_s_0" className="inch_value">
                  93cm
                </td>
                <td id="_s_1" className="inch_value">
                  97cm
                </td>
                <td id="_s_2" className="inch_value">
                  101cm
                </td>
                <td id="_s_3" className="inch_value">
                  105cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  65cm
                </td>
                <td id="_s_1" className="inch_value">
                  66cm
                </td>
                <td id="_s_2" className="inch_value">
                  67cm
                </td>
                <td id="_s_3" className="inch_value">
                  68cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Sleeve Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bicep Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Cuff(cm)</td>
                <td id="_s_0" className="inch_value">
                  29cm
                </td>
                <td id="_s_1" className="inch_value">
                  30cm
                </td>
                <td id="_s_2" className="inch_value">
                  31cm
                </td>
                <td id="_s_3" className="inch_value">
                  32cm
                </td>
              </tr>
            </tbody>
          </table>
            </div>
        </div>


        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 lrpad'>
           <div className="sg-table-wrap">
           <h3 className='sizesub'>Ladies Netball Dress </h3>
           <div className="units-opt">
            <label className="she-radioed j-show-inch">
              <i />
            </label>
            <span>(Inches)</span>
          </div>
          <table
            className="sg-table j-cm-table"
            cellSpacing={0}
            cellPadding={0}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right sizehead">Size</td>
                <td className="border-right sizehead1">Chest </td>
                <td className="border-right sizehead1">Waist </td>
                <td className="border-right sizehead1">Total Length </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">6</td>
                <td className="border-right">14.5	</td>
                <td className="border-right">13.5	</td>
                <td className="border-right">30.5	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">8</td>
                <td className="border-right">15.5	</td>
                <td className="border-right">14.5	</td>
                <td className="border-right">31.5	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">10</td>
                <td className="border-right">16.5	</td>
                <td className="border-right">15.5	</td>
                <td className="border-right">32	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">12</td>
                <td className="border-right">17	</td>
                <td className="border-right">16.5	</td>
                <td className="border-right">33	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">14</td>
                <td className="border-right">18.5	</td>
                <td className="border-right">17	</td>
                <td className="border-right">33.5	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">16</td>
                <td className="border-right">19.5	</td>
                <td className="border-right">18.5	</td>
                <td className="border-right">34.5	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">18</td>
                <td className="border-right">20.5	</td>
                <td className="border-right">19.5	</td>
                <td className="border-right">35.5	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">20</td>
                <td className="border-right">50	</td>
                <td className="border-right">20.5	</td>
                <td className="border-right">36	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">22</td>
                <td className="border-right">52.5	</td>
                <td className="border-right">50	</td>
                <td className="border-right">37	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">24</td>
                <td className="border-right">55 </td>
                <td className="border-right">52.5		</td>
                <td className="border-right">37.5	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">26</td>
                <td className="border-right">57.5	</td>
                <td className="border-right">55	</td>
                <td className="border-right">38.5 </td>
              </tr>
            </tbody>
          </table>
          <table
            className="sg-table j-inch-table"
            cellSpacing={0}
            cellPadding={0}
            style={{ display: "none" }}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right">Size</td>
                <td className="border-right">XS</td>
                <td className="border-right">S</td>
                <td className="border-right">M</td>
                <td>L</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Shoulder(cm)</td>
                <td id="_s_0" className="inch_value">
                  49cm
                </td>
                <td id="_s_1" className="inch_value">
                  50cm
                </td>
                <td id="_s_2" className="inch_value">
                  51cm
                </td>
                <td id="_s_3" className="inch_value">
                  52cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bust(cm)</td>
                <td id="_s_0" className="inch_value">
                  101cm
                </td>
                <td id="_s_1" className="inch_value">
                  105cm
                </td>
                <td id="_s_2" className="inch_value">
                  109cm
                </td>
                <td id="_s_3" className="inch_value">
                  113cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Waist Size(cm)</td>
                <td id="_s_0" className="inch_value">
                  93cm
                </td>
                <td id="_s_1" className="inch_value">
                  97cm
                </td>
                <td id="_s_2" className="inch_value">
                  101cm
                </td>
                <td id="_s_3" className="inch_value">
                  105cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  65cm
                </td>
                <td id="_s_1" className="inch_value">
                  66cm
                </td>
                <td id="_s_2" className="inch_value">
                  67cm
                </td>
                <td id="_s_3" className="inch_value">
                  68cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Sleeve Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bicep Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Cuff(cm)</td>
                <td id="_s_0" className="inch_value">
                  29cm
                </td>
                <td id="_s_1" className="inch_value">
                  30cm
                </td>
                <td id="_s_2" className="inch_value">
                  31cm
                </td>
                <td id="_s_3" className="inch_value">
                  32cm
                </td>
              </tr>
            </tbody>
          </table>
            </div>
        </div>


        <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-12'>
        </div>
        <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12 paddingtop lrpad'>
           <div className="sg-table-wrap">
           <h3 className='sizesub'>Ladies Track Pant </h3>
           <div className="units-opt">
            <label className="she-radioed j-show-inch">
              <i />
            </label>
            <span>(Inches)</span>
          </div>
          <table
            className="sg-table j-cm-table"
            cellSpacing={0}
            cellPadding={0}
          >
                
            <tbody>
               <tr>
                 <th></th>
                 <th></th>
                 <th className="inside">Inside Leg</th>
                 <th></th>
                 <th></th>
               </tr>
              <tr className="trhead">
                <td className="border-right sizehead">Size</td>
                <td className="border-right sizehead1">Petite </td>
                <td className="border-right sizehead1">Regular </td>
                <td className="border-right sizehead1">Long</td>
                <td className="border-right sizehead1">X-Long </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">6</td>
                <td className="border-right">28	</td>
                <td className="border-right">30	</td>
                <td className="border-right">32	</td>
                <td className="border-right">	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">8</td>
                <td className="border-right">28	</td>
                <td className="border-right">30	</td>
                <td className="border-right">32	</td>
                <td className="border-right">	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">10</td>
                <td className="border-right">28	</td>
                <td className="border-right">30	</td>
                <td className="border-right">32	</td>
                <td className="border-right">	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">12</td>
                <td className="border-right">28	</td>
                <td className="border-right">30	</td>
                <td className="border-right">32	</td>
                <td className="border-right">34	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">14</td>
                <td className="border-right">28	</td>
                <td className="border-right">30	</td>
                <td className="border-right">32	</td>
                <td className="border-right">34	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">16</td>
                <td className="border-right">28	</td>
                <td className="border-right">30	</td>
                <td className="border-right">32	</td>
                <td className="border-right">34	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">18</td>
                <td className="border-right">28	</td>
                <td className="border-right">30	</td>
                <td className="border-right">32	</td>
                <td className="border-right">34	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">20</td>
                <td className="border-right">	</td>
                <td className="border-right">30	</td>
                <td className="border-right">32	</td>
                <td className="border-right">34 </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">22</td>
                <td className="border-right">	</td>
                <td className="border-right">30	</td>
                <td className="border-right">32	</td>
                <td className="border-right">34	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">24</td>
                <td className="border-right">	</td>
                <td className="border-right">30	</td>
                <td className="border-right">32	</td>
                <td className="border-right">34	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">26</td>
                <td className="border-right">	</td>
                <td className="border-right">30	</td>
                <td className="border-right">32	</td>
                <td className="border-right">34	</td>
              </tr>
            </tbody>
          </table>
          <table
            className="sg-table j-inch-table"
            cellSpacing={0}
            cellPadding={0}
            style={{ display: "none" }}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right">Size</td>
                <td className="border-right">XS</td>
                <td className="border-right">S</td>
                <td className="border-right">M</td>
                <td>L</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Shoulder(cm)</td>
                <td id="_s_0" className="inch_value">
                  49cm
                </td>
                <td id="_s_1" className="inch_value">
                  50cm
                </td>
                <td id="_s_2" className="inch_value">
                  51cm
                </td>
                <td id="_s_3" className="inch_value">
                  52cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bust(cm)</td>
                <td id="_s_0" className="inch_value">
                  101cm
                </td>
                <td id="_s_1" className="inch_value">
                  105cm
                </td>
                <td id="_s_2" className="inch_value">
                  109cm
                </td>
                <td id="_s_3" className="inch_value">
                  113cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Waist Size(cm)</td>
                <td id="_s_0" className="inch_value">
                  93cm
                </td>
                <td id="_s_1" className="inch_value">
                  97cm
                </td>
                <td id="_s_2" className="inch_value">
                  101cm
                </td>
                <td id="_s_3" className="inch_value">
                  105cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  65cm
                </td>
                <td id="_s_1" className="inch_value">
                  66cm
                </td>
                <td id="_s_2" className="inch_value">
                  67cm
                </td>
                <td id="_s_3" className="inch_value">
                  68cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Sleeve Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bicep Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Cuff(cm)</td>
                <td id="_s_0" className="inch_value">
                  29cm
                </td>
                <td id="_s_1" className="inch_value">
                  30cm
                </td>
                <td id="_s_2" className="inch_value">
                  31cm
                </td>
                <td id="_s_3" className="inch_value">
                  32cm
                </td>
              </tr>
            </tbody>
          </table>
            </div>
        </div>
        <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-12'>
        </div>



{/* Measurement */}
<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 paddingtop">
        <div className='container'>
        <div className='row'>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 measure-pict">
            <img src={ladiessize} className='' alt='' />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 measure-guid">
            <div className="measure-wrap">
            <div className="measure-guide">
            <h5>How to measure</h5>
          <p>
            The size chart is only for reference. It may differ across brands. 
          </p>
        </div>
              <h6>
                <span>1.</span> Chest
              </h6>
              <p>
              Measure your chest’s fullest part. Make sure that the measuring tape is kept horizontal and not too tight. 
              </p>
            </div>
            <div className="measure-wrap">
              <h6>
                <span>2.</span> Waist
              </h6>
              <p>
              Wrap the measuring tape around the narrowest part of your waist, usually at the small of your back, where your body bends. Keep the measuring tape horizontal for accurate measurement. 
              </p>
            </div>

          </div>
        </div>
        </div> 

        </div> 



        {/* <p id="goods_dash_tip" className="sg-tip"></p>
        <div className="measure-guide">
          <h5>How to measure</h5>
          <p>
            This size chart is for reference purposes only. It may vary between brands.
          </p>
        </div> */}

        {/* <div className='container'>
        <div className='row'>
        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 measure-pict">
            <img src="//sheinsz.ltwebstatic.com/image/size_guide_vf8e71a0.png?v=shein_565" />
                <img src={about1} className='' alt='' />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 measure-guid">
            <div className="measure-wrap">
              <h6>
                <span>1.</span> Bust
              </h6>
              <p>
                Measure under your arms around the fullest part of your bust.
              </p>
            </div>
            <div className="measure-wrap">
              <h6>
                <span>2.</span> Waist
              </h6>
              <p>
                Measure around the narrowest part of your natural waist.
              </p>
            </div>
            <div className="measure-wrap">
              <h6>
                <span>3.</span> Hips
              </h6>
              <p>
                Measure around the widest part of your hips.
              </p>
            </div>
          </div>
        </div>
        </div>
        </div>  */}

      </div>
    </div>
  </div>
</div>

                       
                </div>
            </div>
            </div>
     
    </>
  );
}
export default Ladiessize;