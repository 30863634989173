import React, { useState } from "react";
import Footer_v1 from "../global-components/footer";
import { Helmet } from "react-helmet";
import NavbarV4 from "../global-components/navbar-v4";

import ukflag from "../../images/sports/ukflag.png";
import canadaflag from "../../images/sports/canadaflag.png";
import hongkongflag from "../../images/sports/hongkongflag.png";
import chinaflag from "../../images/sports/chinaflag.png";
import contactbanner from "../../images/banners/contactbanner.webp";
import contactmobile from "../../images/banners/contactmobile.webp";

const Contact = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [selected, setSelected] = useState(""); 

  const handleSubmit = (event) => {
    event.preventDefault();
    const country = event.target.country.value;

    const errors = {};
    if (country === "Select Country") {
      errors.country = "Please select country";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return; // Prevent form submission if validation fails
    }

    // Reset validation errors
    setValidationErrors({});
    event.target.submit();
  };

  const changeSelectOptionHandler = (event) => { 
    setSelected(event.target.value); 
  }; 

  const Asia = [ 
    "PRC China", "Hong Kong SAR", "Macau", "Japan", "South Korea", "Singapore", "Malaysia", "United Arab Emirates" 
  ]; 
  const Europe = ["United Kingdom", "Germany", "France", "Italy", "Spain", "Netherlands", "Belgium", "Sweden", "Portugal", "Switzerland", "Finland", "Norway", "Ireland"]; 
  const Northamerica = ["United States", "Canada", "Mexico", "Saint Martin", "Panama"]; 
  const Southamerica = ["Brazil", "Argentina", "Colombia"]; 
  const Oceania = ["Australia", "New Zealand"]; 

  let type = null; 

  if (selected === "Asia") { 
    type = Asia; 
  } else if (selected === "Europe") { 
    type = Europe; 
  } else if (selected === "North America") { 
    type = Northamerica; 
  } else if (selected === "South America") { 
    type = Southamerica; 
  } else if (selected === "Oceania") { 
    type = Oceania; 
  }

  const options = type ? type.map((el) => <option key={el} value={el} name={el} class="form-option" >{el}</option>) : null;

  return (
    <>
      <NavbarV4 CustomClass="ltn__header-transparent gradient-color-2" />
      <Helmet>
        <title>Contact Us - Bcurri Apparel Limited</title>
        <meta name="description" content="" />
      </Helmet>

      <picture>
        <source srcSet={contactmobile} media="(max-width: 660px)" />
        <source srcSet={contactbanner} />
        <img src={contactbanner} alt="" className="ban" />
      </picture>

      <section className="contact" id="contact">
        <div className="container">
          <div className="heading text-center">
            <h2>Contact Us </h2>
            <p>
              Our dedicated team is always on hand to help you with your
              inquiries
              <br />
              or provide you with the information you need.{" "}
            </p>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="title">
                <h3>Get In Touch </h3>
                <p>
                  Want to request a quote or have questions about our services?
                  Feel free to reach out to us.{" "}
                </p>
              </div>
              <div className="content">
                <div className="info">
                  <i className="fas fa-envelope"></i>{" "}
                  <h4 className="d-inline-grid1">
                    Sales Enquiry
                    <br />
                    <span>
                      <a href="mailto:sales@bcurriapparel.com">
                        sales@bcurriapparel.com
                      </a>
                    </span>
                    <br />
                  </h4>
                  <br />
                  <i className="fas fa-envelope"></i>{" "}
                  <h4 className="d-inline-grid1">
                    General Enquiry
                    <br />
                    <span>
                      <a href="mailto:team@bcurriapparel.com">
                        team@bcurriapparel.com
                      </a>
                    </span>
                  </h4>
                </div>
                <div className="info">
                  <h4 className="d-inline-block">
                    Locations
                    <br />
                    <img src={ukflag} className="flag" alt="" />
                    <img src={canadaflag} className="flag" alt="" />
                    <img src={hongkongflag} className="flag" alt="" />
                    <img src={chinaflag} className="flag" alt="" />
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-md-7">
              <form
                action="contact.php"
                method="post"
                encType="multipart/form-data"
                id="contform"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control1"
                      placeholder="Full Name"
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      name="teamname"
                      className="form-control1"
                      placeholder="Club / School / Team Name"
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="tel"
                      name="phone"
                      className="form-control1"
                      placeholder="Phone No."
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="email"
                      name="email"
                      className="form-control1"
                      placeholder="Email Id"
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      name="webpage"
                      className="form-control1"
                      placeholder="Website / Facebook Page"
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <select name="country" class="form-control3" id="Country1" value={selected} onChange={changeSelectOptionHandler} required> 
                      <option name="" value="" selected="selected" disabled="disabled">Select Continent </option> 
                      <option class="form-option" name="Asia" value="Asia">Asia</option>
                      <option class="form-option" name="Europe" value="Europe">Europe</option>
                      <option class="form-option" name="North America" value="North America">North America</option>
                      <option class="form-option" name="South America" value="South America">South America</option>
                      <option class="form-option" name="Oceania" value="Oceania">Oceania</option>
                    </select> 
                    {validationErrors.country && (
                      <p className="error">{validationErrors.country}</p>
                    )}
                  </div>
                  <div className="col-sm-12 location-data" >
                    <select name="locations" class="form-control2" id="locations" required> 
                    <option class="form-option" name="" value="" selected="selected" disabled="disabled" >Select Country</option> 
                      {options} 
                    </select> 
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    className="form-control1"
                    rows="5"
                    id="comment"
                    placeholder="Comments / Questions"
                    required
                  ></textarea>
                </div>

                <button className="btn btn-block" type="submit">
                  Submit Now
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer_v1 />
    </>
  );
};

export default Contact;
