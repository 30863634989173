import React from 'react';
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../global-components/navbar-v4';


const Privacypolicy = () => {
    return (
<>
<NavbarV4 CustomClass="ltn__header-transparent gradient-color-2"  />
<Helmet>
<title>Privacy Policy - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

<section className='privacy'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='seocontentbg1'>
            <h1>Privacy Policy</h1>
           </div>
            </div>
        </div>
       
    </div>
    <div className='left-seoshape1'></div>

</section>
<section className='privacycontent'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                {/* <div className='pricontent'>
                  <h2>Privacy Policy</h2>
                    <p>By using our website, you consent to the privacy practices as described in this document. This privacy policy outlines how we use your personal information collected at www.whizamet.com. You’re advised to read this privacy policy before providing your personal details to us. </p>
                  <h3>Information we collect</h3>
                  <p><strong>We collect the following information about when you visit our website.</strong></p>
                  <ul>
                    <li>General information related to the use of our website. Such information includes the pages you viewed on our site along with the resources that you accessed, information about traffic data, location, and any other communication details. </li>
                    <li>Information that you voluntarily provide while registering for information or making an inquiry or a purchase.</li>
                    <li>Any other information you provide to us when you communicate with us.How we use your personal information </li>
                  </ul>
                  <h3>Whizamet collects and uses your personal information for the following purposes. </h3>
                  <ul>
                    <li>To improve our site </li>
                    <li>We constantly strive to enhance our website experience based on the information collected from you. </li>
                    <li>To improve customer service</li>
                    <li>Your information helps us to respond to your customer service requests more effectively. </li>
                    <li>To send periodic emails </li>
                    <li>We leverage your personal information to send you periodic emails regarding company news, updates, or any related product or service. </li>
                    <li>To administer a promotional survey or any other site feature </li>
                     </ul>
                     <p><strong> We use your information to contact you regarding any promotional survey or send information about topics that we think may interest you. </strong></p>
                     <h3>How we protect your information </h3>
                     <p>We have put in place appropriate data collection, storage, and processing practices, and security measures to safeguard your personal information from unauthorized access. While we have put in all efforts to protect your data, you acknowledge that the use of the internet is not entirely secure and for this reason, we cannot provide a 100% guarantee of any personal data transferred via the internet. </p>
                     <h3>Use of cookies </h3>
                     <p>We may use cookies & other tracking technologies to gather information about browser type, operating system, number of website visitors, & how visitors use our website. Cookies allow us to customize the site according to the preferences of our customers. </p>
                     <p> You can accept or reject the cookies. However, if you refuse the cookies, you may not be able to enjoy the full features of our website. </p>
                     <h3>Disclosing your information </h3>
                     <p>We won’t share your personal information with any other third party other than as mentioned in this privacy policy. </p>
                     <ul>
                        <li>Where we need to disclose your information to comply with the legal procedures. </li>
                        <li>To minimize the chances of fraud. </li>
                        <li>In the event, we sell our business to a third party. </li>
                     </ul>
                     <h3>Third-party links</h3>
                     <p>Our website contains links to third-party websites. If you click on those links, you’ll be directed to third-party websites. We advise you to read the privacy policies of such third-party websites before submitting your personal information to them. We assume no responsibility for the privacy practices of such third-party sites. </p>
                     <h3>Rights to your information </h3>
                     <p>According to the data protection law, you have several rights related to the personal information we hold about you. We provide you the ability to exercise your rights at any time by reaching out to us. </p>
                     <h3>Changes to the privacy policy </h3>
                     <p>We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. We advise you to review this privacy policy frequently to stay informed about our privacy practices. </p>
                     <h3>Contact us</h3>
                     <p>If you have any queries or questions about our privacy policy, you can email us at <a hrf="mailto:reach@whizamet.com">reach@whizamet.com</a></p>
                </div> */}
            </div>
        </div>
    </div>
</section>







<Footer_v1 />
</>
)
}

export default Privacypolicy

