import React, { Component } from 'react';

import chatmessage from "../../images/sports/chatmessage.png";

class NavbarV2 extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
		let CustomClass = this.props.CustomClass ? this.props.CustomClass : ''
        return (
			<div>
				<header className={"ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent--- gradient-color-4--- "+ CustomClass}>
				{/* ltn__header-top-area start */}
				<div className="ltn__header-top-area d-none">
					<div className="container">
					<div className="row">
						<div className="col-md-7">
						<div className="ltn__top-bar-menu">
							<ul>
							<li><a href="mailto:info@webmail.com?Subject=Flower%20greetings%20to%20you"><i className="icon-mail" /> info@webmail.com</a></li>
							<li><a href="locations.html"><i className="icon-placeholder" /> 15/A, Nest Tower, NYC</a></li>
							</ul>
						</div>
						</div>
						<div className="col-md-5">
						<div className="top-bar-right text-end">
							<div className="ltn__top-bar-menu">
							<ul>
								<li>
								{/* ltn__language-menu */}
								<div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
									<ul>
									
									</ul>
								</div>
								</li>
								<li>
					
								</li>
							</ul>
							</div>
						</div>
						</div>
					</div>
					</div>
				</div>
				{/* ltn__header-top-area end */}
				{/* ltn__header-middle-area start */}
				<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
					<div className="container">
					<div className="row">
						<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
						<div className="site-logo-wrap">
							<div className="site-logo go-top">
						<a href='/'><img src={publicUrl+"assets/img/logo.png"} alt="Logo" /></a>
							</div>
							
						</div>
						</div>
						<div className="col-xxl-6 col-xl-6 col-lg-4 col-md-4 col-12">
						<div className="header-menu d-none d-xl-block go-top">
							<nav>
							<div className="ltn__main-menu">
								<ul>
								<li><a href='/'>Home</a></li>
								<li><a href="/financialplanning">Financial Planning</a></li>
								<li><a href="/lifeinsurance">life Insurance</a></li>
								<li><a href="/faq">FAQ</a></li>
								<li><a href="/contactus">Contact</a></li>
								<li><a href="/blogs">Blog</a></li>
								
								</ul>
							</div>
							</nav>
							
						</div>
						<div className="col--- ltn__header-options ltn__header-options-2 ">
						{/* Mobile Menu Button */}
						<div className="mobile-menu-toggle d-xl-none">
							<a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
							<svg viewBox="0 0 800 600">
								<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
								<path d="M300,320 L540,320" id="middle" />
								<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
							</svg>
							</a>
						</div>
						</div>
						</div>
						
						<div className="col-xxl-3 col-xl-3 col-lg-5 col-md-5 col-12" id='hdr-cta'>
						<div className="headercnt">
								<div className='chatmsg'>
								<img src={chatmessage}  alt='' className='chatmessage' />
							 </div>
							  <div className="box-body">
 							 <h3 className="nav-info-box-title">24 hours every day </h3>
 							 <p>+1 737 333 1133</p>
								</div>
								</div>
						</div>
						
					</div>
					</div>
				</div>
				{/* ltn__header-middle-area end */}
				</header>
				<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
					<div className="site-logo">
						<a href='/'><img src={publicUrl+"assets/img/logo.png"} alt="Logo" /></a>
					</div>
					<button className="ltn__utilize-close">×</button>
					</div>
					<div className="ltn__utilize-menu">
					<ul>
					<li><a href='/'>Home</a></li>
								<li><a href="/financialplanning">Financial Planning</a></li>
								<li><a href="/lifeinsurance">life Insurance</a></li>
								<li><a href="/faq">FAQ</a></li>
								<li><a href="/contactus">Contact</a></li>
								<li><a href="/blogs">Blog</a></li>
								
					</ul>
					</div>
					<div className="ltn__social-media-2">
					<ul>
						<li><a href="https://www.facebook.com/profile.php?id=100094368204625" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
						<li><a href="https://twitter.com/mypolicyplanner" title="Twitter"><i className="fab fa-twitter" /></a></li>
						<li><a href="https://www.linkedin.com/in/my-policy-planner-a54440282/" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
						<li><a href="https://www.instagram.com/my.policyplanner/" title="Instagram"><i className="fab fa-instagram" /></a></li>
					</ul>
					</div>
				</div>
				</div>
			</div>
		)
    }
}


export default NavbarV2