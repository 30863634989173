
import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Formmodal = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
 <>
 <div>
 <button className='modalbtn' onClick={onOpenModal}>Get a quote
 <span className="line-1" />
                 <span className="line-2" />
                 <span className="line-3" />
                 <span className="line-4" /></button>
 <Modal  open={open} onClose={onCloseModal} center>
 <div className="seekermodal">
   <h4>Get A Quote</h4>
   <p>Fill out this form with your details and one of our experts will contact you.</p>
   <form action="contact.php" method="post" enctype="multipart/form-data">
        <input type="text" name="name" className="form-control" placeholder='Name' required />
          <input type="email" name="email" className="form-control" placeholder='Email' required />
            <input type="tel" name="phone" className="form-control2" placeholder='Contact' required />
            <textarea id="message" 
                  title="Message" 
                  cols="20" 
                  name='message'
                  rows="3" 
                  maxlength="500" 
                  placeholder='Your Query....'
                  required />
             
                 <input type="submit" className="form-rol" defaultValue="send" />
      </form>
               </div>
 </Modal>
</div>

 </>
    );
}
 
export default Formmodal;