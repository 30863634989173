import React from 'react';
import { Helmet } from 'react-helmet';

import tshirt1front from "../../../images/sports/products/tshirt/1/tshirt1front.png";
import tshirt1back from "../../../images/sports/products/tshirt/1/tshirt1back.png";
import tshirt2front from "../../../images/sports/products/tshirt/2/tshirt2front.png";
import tshirt2back from "../../../images/sports/products/tshirt/2/tshirt2back.png";
import tshirt3front from "../../../images/sports/products/tshirt/3/tshirt3front.png";
import tshirt3back from "../../../images/sports/products/tshirt/3/tshirt3back.png";
import tshirt4front from "../../../images/sports/products/tshirt/4/tshirt4front.png";
import tshirt4back from "../../../images/sports/products/tshirt/4/tshirt4back.png";
import tshirt5front from "../../../images/sports/products/tshirt/5/tshirt5front.png";
import tshirt5back from "../../../images/sports/products/tshirt/5/tshirt5back.png";
import tshirt6front from "../../../images/sports/products/tshirt/6/tshirt6front.png";
import tshirt6back from "../../../images/sports/products/tshirt/6/tshirt6back.png";
import tshirt7front from "../../../images/sports/products/tshirt/7/tshirt7front.png";
import tshirt7back from "../../../images/sports/products/tshirt/7/tshirt7back.png";
import tshirt8front from "../../../images/sports/products/tshirt/8/tshirt8front.png";
import tshirt8back from "../../../images/sports/products/tshirt/8/tshirt8back.png";
import tshirt9front from "../../../images/sports/products/tshirt/9/tshirt9front.png";
import tshirt9back from "../../../images/sports/products/tshirt/9/tshirt9back.png";
import tshirt10front from "../../../images/sports/products/tshirt/10/tshirt10front.png";
import tshirt10back from "../../../images/sports/products/tshirt/10/tshirt10back.png";


const Tshirtsport  = () => {
    return (
<>

<Helmet>
<title>Tshirts - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

{/* Rugby tshirt */}

<section className='cloth-rugby4' id='Tshirt'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>T-shirts</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Tshirt1" className="image">
        <img src={tshirt1front} className="pic-1" alt='' />
        <img src={tshirt1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Tshirt1">T-shirt 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Tshirt2" className="image">
        <img src={tshirt2front} className="pic-1" alt='' />
        <img src={tshirt2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Tshirt2">T-shirt 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Tshirt3" className="image">
        <img src={tshirt3front} className="pic-1" alt='' />
        <img src={tshirt3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Tshirt3">T-shirt 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Tshirt4" className="image">
        <img src={tshirt4front} className="pic-1" alt='' />
        <img src={tshirt4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Tshirt4">T-shirt 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Tshirt5" className="image">
        <img src={tshirt5front} className="pic-1" alt='' />
        <img src={tshirt5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Tshirt5">T-shirt 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Tshirt6" className="image">
        <img src={tshirt6front} className="pic-1" alt='' />
        <img src={tshirt6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Tshirt6">T-shirt 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Tshirt7" className="image">
        <img src={tshirt7front} className="pic-1" alt='' />
        <img src={tshirt7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Tshirt7">T-shirt 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Tshirt8" className="image">
        <img src={tshirt8front} className="pic-1" alt='' />
        <img src={tshirt8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Tshirt8">T-shirt 8</a>
        </h3>
  </div>
 
   </div>
   <br/>

       {/* Row 3 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Tshirt9" className="image">
        <img src={tshirt9front} className="pic-1" alt='' />
        <img src={tshirt9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Tshirt9">T-shirt 9</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Tshirt10" className="image">
        <img src={tshirt10front} className="pic-1" alt='' />
        <img src={tshirt10back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Tshirt10">T-shirt 10</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>


   </div>
   <br/>

   
    

    </div>
</section>



</>
)
}

export default Tshirtsport

