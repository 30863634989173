import React, { useState, useEffect } from 'react';

const Slidersec = () => {
  const [index, setIndex] = useState(0);

  const slides = [

    // { backgroundImage: "url('images/slide-3.jpg')", title: "Special Offers For  Schools & Universities", text: "Fuel your team’s spirit by unlocking our special offers." }
    {   },
    {  title: "First Order 15% Discount", text: "Enjoy an exclusive first-order discount on our services. " },
    {  title: "To Become Partner Club", text: "Get access to unique designs customized to your club’s identity." },
    {  title: "Special Offers For  Schools & Universities", text: "Fuel your team’s spirit by unlocking our special offers." }
  ];

  let timer;

  const prevSlide = () => {
    setIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
    resetTimer();
  };

  const nextSlide = () => {
    setIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
    resetTimer();
  };

  const indicateSlide = (elementId) => {
    setIndex(elementId);
    resetTimer();
  };

  const updateCircleIndicator = () => {
    const indicators = document.querySelectorAll('.indicator > div');
    indicators.forEach((indicator, i) => {
      if (i === index) {
        indicator.classList.add('active');
      } else {
        indicator.classList.remove('active');
      }
    });
  };

  const resetTimer = () => {
    clearInterval(timer);
    timer = setInterval(autoPlay, 7000);
  };

  const autoPlay = () => {
    nextSlide();
    updateCircleIndicator();
  };

  useEffect(() => {
    timer = setInterval(autoPlay, 6000);
    return () => {
      clearInterval(timer);
    };
  }, []);


  return (
    <section className="home">
      <div className="slider">
        {slides.map((slide, idx) => (
          <div key={idx} className={`slide ${index === idx ? 'active' : ''}`} style={{ backgroundImage: slide.backgroundImage }}>
            <div className="container">
              <div className="caption">
                <h1>{slide.title}</h1>
                <p className='avoid'>{slide.text}</p>
                {idx !== 0 && <button className="my-btn"><a href="/How-to-order">Explore Now</a></button>}
              </div>

            </div>
          </div>
        ))}
      </div>

      <div className="controls">
        <div className="prev" onClick={prevSlide}>
          &lt;
        </div>
        <div className="next" onClick={nextSlide}>
          &gt;
        </div>
      </div>

      <div className="indicator">
        {slides.map((_, idx) => (
          <div key={idx} onClick={() => indicateSlide(idx)} className={idx === index ? 'active' : ''}>
            {idx + 1}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Slidersec;
