import React, { useRef, useState } from "react";
import ukflag1 from '../../../images/sports/ukflag1.png';
import canadaflag1 from '../../../images/sports/canadaflag1.png';
import chinaflag1 from '../../../images/sports/chinaflag1.png';
import hongkongflag1 from '../../../images/sports/hongkongflag1.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const Mapcountries = () => {
  return (
    <>
      <div className="Corpclient">
            <div className="container-fluid">
            <div className="Corpclient-wrapper align-items-center">
         <div className="Corpclient-slider">
          <div className="swiper-Corpclient">
          <Swiper
                          slidesPerView={2}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 4000,
                      disableOnInteraction: true,
                      
                                }}
                          modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 2,
                       spaceBetween: 20,
                         },
                         580: {
                       slidesPerView: 3,
                       spaceBetween: 20,
                         },
                         700: {
                       slidesPerView: 4,
                       spaceBetween: 20,
                         },
                         768: {
                        slidesPerView: 4,
                       spaceBetween: 10,
                          },
                          1024: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1241: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1400: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                              }}
              
                    className="mySwiper"
                               >
          
          <SwiperSlide>
            <img src={ukflag1} alt="" className="mflag" />
            <h4>United Kingdom</h4>
          </SwiperSlide>
          <SwiperSlide>
            <img src={canadaflag1} alt="" className="mflag" />
            <h4>Canada</h4>
          </SwiperSlide>
          <SwiperSlide>
            <img src={chinaflag1} alt="" className="mflag" />
            <h4>China</h4>
          </SwiperSlide>
          <SwiperSlide>
            <img src={hongkongflag1} alt="" className="mflag" />
            <h4>Hong Kong</h4>
          </SwiperSlide>
          <SwiperSlide>
            <img src={ukflag1} alt="" className="mflag" />
            <h4>United Kingdom</h4>
          </SwiperSlide>  
          <SwiperSlide>
            <img src={canadaflag1} alt="" className="mflag" />
            <h4>Canada</h4>
          </SwiperSlide>                   
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Mapcountries;