import React, { useRef, useState } from "react";
import corpclient1 from '../../../images/sports/corpclient1.jpg';
import corpclient2 from '../../../images/sports/corpclient2.jpg';
import corpclient3 from '../../../images/sports/corpclient3.jpg';
import corpclient4 from '../../../images/sports/corpclient4.jpg';

import logo1 from '../../../images/sports/logo1.jpg';


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const Corpclient = () => {
  return (
    <>
      <div className="Corpclient">
            <div className="container-fluid">
            <div className="Corpclient-wrapper align-items-center">
         <div className="Corpclient-slider">
          <div className="swiper-Corpclient">
          <Swiper
                          slidesPerView={2}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 4000,
                      disableOnInteraction: true,
                      
                                }}
                          modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 1,
                       spaceBetween: 20,
                         },
                         580: {
                       slidesPerView: 1,
                       spaceBetween: 20,
                         },
                         700: {
                       slidesPerView: 1,
                       spaceBetween: 20,
                         },
                         768: {
                        slidesPerView: 1,
                       spaceBetween: 10,
                          },
                          1024: {
                    slidesPerView: 1,
                        spaceBetween: 10,
                           },
                           1241: {
                    slidesPerView: 1,
                        spaceBetween: 10,
                           },
                           1400: {
                    slidesPerView: 1,
                        spaceBetween: 10,
                           },
                              }}
              
                    className="mySwiper"
                               >
          
          <SwiperSlide>
            <img src={logo1} alt="" className="corpclient1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo1} alt="" className="corpclient1" />
          </SwiperSlide>
                    
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Corpclient;