import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../../global-components/navbar-v4';
import Footer_v1 from '../../global-components/footer';
import Formmodal from '../../section-components/formmodal';
import item1Image from '../../../images/sports/products/item1.jpg';
import item2Image from '../../../images/sports/products/item2.jpg';
import item3Image from '../../../images/sports/products/item3.jpg';
import item4Image from '../../../images/sports/products/item4.jpg';

import Soccertshirtssport from '../../section-components/Soccer-cloth/Soccer-tshirts-sport';


import soccershirtsbanner from "../../../images/banners/soccershirtsbanner.jpg"
import soccershirtsmobile from "../../../images/banners/soccershirtsmobile.jpg"


const SoccerTshirts = () => {
  const smallImages = [item1Image, item2Image, item3Image, item4Image];

  const [productImage, setProductImage] = useState(smallImages[0]);

  const handleSmallImageClick = (src) => {
    setProductImage(src);
  };

  return (
    <>
      <NavbarV4 />

      <Helmet>
        <title>Bcurri Sports</title>
        <meta name="description" content="" />
      </Helmet>


<picture>
  <source srcset={soccershirtsmobile} media="(max-width: 660px)" />
  <source srcset={soccershirtsbanner} />
  <img src={soccershirtsbanner} alt="" className='ban' />
</picture>


 {/* Start Slider area  */}
 {/* <section className='rugby1 overlay'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-md-12'>
                 <div className='rugby1-bcurri'>
                <h1>Soccer Shirts</h1>
             </div>
         
            </div>
          </div>
    </div>
</section> */}

<Soccertshirtssport />

      <Footer_v1 />
    </>
  );
};

export default SoccerTshirts;
