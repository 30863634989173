import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";

import ComingSoon from './components/coming-soon';
import Error  from './components/404';

import Home from "./components/Pages/Home";
import Entryhome from "./components/Pages/Entryhome";
import Howtoorder from "./components/Pages/How-to-order";
import Career from "./components/Pages/career";
import Promotions from './components/Pages/Promotions';
import Gallery from './components/Pages/Gallery';
import Collaborations from './components/Pages/Collaborations';
import Contact from "./components/Pages/contact-us";
import Faq  from "./components/Pages/faqs";
import Blog from "./components/Pages/blogs";
import Thankyou from './components/Pages/thank-you';
import Privacypolicy from './components/Pages/privacypolicy';

import SublimationRugbyJersey from "./components/Pages/Rugby Products/Rugby-Jerseys";
import NetballBlouse from "./components/Pages/Netball Products/Netball-Blouse";
import NetballShirts from "./components/Pages/Netball Products/Netball-Top";
import NetballSkorts from "./components/Pages/Netball Products/Netball-Skorts";
import CutSewRugbyShorts from "./components/Pages/Rugby Products/Rugby-Shorts";
import SublimationRugbyShorts from "./components/Pages/Rugby Products/Sublimation-Rugby-Shorts";
import RugbySocks from "./components/Pages/Rugby Products/Rugby-Socks";
import LadiesVest from "./components/Pages/Hockey Products/Ladies-Vest";
import HockeyShorts from "./components/Pages/Hockey Products/Hockey-Shorts";
import HockeySocks from "./components/Pages/Hockey Products/Hockey-Socks";
import BasketballVest from "./components/Pages/Basketball Products/Basketball-Vest";
import BasketballShorts from "./components/Pages/Basketball Products/Basketball-Shorts";
import Acheryshirts from "./components/Pages/Achery Products/Achery-Shirts";
import Bowlingshirts from "./components/Pages/Bowling Products/Bowling-Shirts";
import CricketTShirts from "./components/Pages/Cricket Products/Cricket-Shirts";
import CricketPants from "./components/Pages/Cricket Products/Cricket-Pants";
import SoccerTshirts from './components/Pages/Soccer Products/Soccer-Shirts';
import SoccerShorts from "./components/Pages/Soccer Products/Soccer-Shorts";
import SoccerSocks from "./components/Pages/Soccer Products/Soccer-Socks";
import PoloShirts from './components/Pages/Casual Products/Polo-Shirts';
import Hoodie from "./components/Pages/Casual Products/Hoodie";
import FleeceJacket from "./components/Pages/Casual Products/Fleece-Jacket";

import Gillet from "./components/Pages/Casual Products/Gillet";
import Vests from "./components/Pages/Training Products/Vests";
import TrainingTop from "./components/Pages/Training Products/Training-Top";
import TrackTop from "./components/Pages/Training Products/Track-Top";
import TrackPants from "./components/Pages/Training Products/Track-Pants";

import RugbySocks1 from './components/Pages/Rugby Socks/Rugby-Socks1';
import RugbySocks2 from './components/Pages/Rugby Socks/Rugby-Socks2';
import RugbySocks3 from './components/Pages/Rugby Socks/Rugby-Socks3';
import RugbySocks4 from './components/Pages/Rugby Socks/Rugby-Socks4';
import RugbySocks5 from './components/Pages/Rugby Socks/Rugby-Socks5';
import RugbySocks6 from './components/Pages/Rugby Socks/Rugby-Socks6';
import RugbySocks8 from './components/Pages/Rugby Socks/Rugby-Socks8';
import RugbySocks7 from './components/Pages/Rugby Socks/Rugby-Socks7';
import RugbySocks9 from './components/Pages/Rugby Socks/Rugby-Socks9';
import RugbySocks10 from './components/Pages/Rugby Socks/Rugby-Socks10';
import RugbyJersy1 from './components/Pages/Rugby Jersey/Rugby-Jersey1';
import RugbyJersy2 from './components/Pages/Rugby Jersey/Rugby-Jersey2';
import RugbyJersy3 from './components/Pages/Rugby Jersey/Rugby-Jersey3';
import RugbyJersy4 from './components/Pages/Rugby Jersey/Rugby-Jersey4';
import RugbyJersy5 from './components/Pages/Rugby Jersey/Rugby-Jersey5';
import RugbyJersy6 from './components/Pages/Rugby Jersey/Rugby-Jersey6';
import RugbyJersy7 from './components/Pages/Rugby Jersey/Rugby-Jersey7';
import RugbyJersy8 from './components/Pages/Rugby Jersey/Rugby-Jersey8';
import RugbyJersy9 from './components/Pages/Rugby Jersey/Rugby-Jersey9';
import RugbyJersy10 from './components/Pages/Rugby Jersey/Rugby-Jersey10';
import RugbyJersy11 from './components/Pages/Rugby Jersey/Rugby-Jersey11';
import RugbyJersy12 from './components/Pages/Rugby Jersey/Rugby-Jersey12';
import RugbyJersy13 from './components/Pages/Rugby Jersey/Rugby-Jersey13';
import RugbyJersy14 from './components/Pages/Rugby Jersey/Rugby-Jersey14';
import RugbyJersy15 from './components/Pages/Rugby Jersey/Rugby-Jersey15';
import RugbyJersy16 from './components/Pages/Rugby Jersey/Rugby-Jersey16';
import RugbyJersy17 from './components/Pages/Rugby Jersey/Rugby-Jersey17';
import RugbyJersy18 from './components/Pages/Rugby Jersey/Rugby-Jersey18';
import RugbyJersy19 from './components/Pages/Rugby Jersey/Rugby-Jersey19';
import RugbyJersy20 from './components/Pages/Rugby Jersey/Rugby-Jersey20';
import RugbyJersy21 from './components/Pages/Rugby Jersey/Rugby-Jersey21';
import RugbyJersy22 from './components/Pages/Rugby Jersey/Rugby-Jersey22';
import RugbyJersy23 from './components/Pages/Rugby Jersey/Rugby-Jersey23';
import RugbyJersy24 from './components/Pages/Rugby Jersey/Rugby-Jersey24';
import RugbyJersy25 from './components/Pages/Rugby Jersey/Rugby-Jersey25';
import RugbyJersy26 from './components/Pages/Rugby Jersey/Rugby-Jersey26';
import RugbyJersy27 from './components/Pages/Rugby Jersey/Rugby-Jersey27';
import RugbyJersy28 from './components/Pages/Rugby Jersey/Rugby-Jersey28';
import RugbyJersy29 from './components/Pages/Rugby Jersey/Rugby-Jersey29';
import RugbyJersy30 from './components/Pages/Rugby Jersey/Rugby-Jersey30';
import RugbyJersy31 from './components/Pages/Rugby Jersey/Rugby-Jersey31';
import RugbyJersy32 from './components/Pages/Rugby Jersey/Rugby-Jersey32';
import RugbyJersy33 from './components/Pages/Rugby Jersey/Rugby-Jersey33';
import RugbyJersy34 from './components/Pages/Rugby Jersey/Rugby-Jersey34';
import GkSmock from './components/Pages/Hockey Products/Gk-Smock';
import MensHockeyShirts from './components/Pages/Hockey Products/Mens-Hockey-Shirts';
import LadiesHockeyShirts from './components/Pages/Hockey Products/Ladies-Hockey-Shirts';
import Rugbyshorts1 from './components/Pages/Rugby Shorts/Rugby-Shorts1';
import Rugbyshorts2 from './components/Pages/Rugby Shorts/Rugby-Shorts2';
import Rugbyshorts3 from './components/Pages/Rugby Shorts/Rugby-Shorts3';
import Rugbyshorts4 from './components/Pages/Rugby Shorts/Rugby-Shorts4';
import Rugbyshorts5 from './components/Pages/Rugby Shorts/Rugby-Shorts5';
import Rugbyshorts6 from './components/Pages/Rugby Shorts/Rugby-Shorts6';
import Rugbyshorts7 from './components/Pages/Rugby Shorts/Rugby-Shorts7';
import Rugbyshorts8 from './components/Pages/Rugby Shorts/Rugby-Shorts8';
import Rugbyshorts9 from './components/Pages/Rugby Shorts/Rugby-Shorts9';
import Rugbyshorts10 from './components/Pages/Rugby Shorts/Rugby-Shorts10';
import Rugbyshorts11 from './components/Pages/Rugby Shorts/Rugby-Shorts11';
import Rugbyshorts12 from './components/Pages/Rugby Shorts/Rugby-Shorts12';
import Rugbyshorts13 from './components/Pages/Rugby Shorts/Rugby-Shorts13';
import Rugbyshorts14 from './components/Pages/Rugby Shorts/Rugby-Shorts14';
import Rugbyshorts15 from './components/Pages/Rugby Shorts/Rugby-Shorts15';
import Rugbyshorts16 from './components/Pages/Rugby Shorts/Rugby-Shorts16';
import Rugbyshorts17 from './components/Pages/Rugby Shorts/Rugby-Shorts17';
import Rugbyshorts18 from './components/Pages/Rugby Shorts/Rugby-Shorts18';
import Rugbyshorts19 from './components/Pages/Rugby Shorts/Rugby-Shorts19';
import Rugbyshorts20 from './components/Pages/Rugby Shorts/Rugby-Shorts20';

import RugbyCutSewShorts1 from './components/Pages/Rugby Cut Sew Shorts/Rugby-Cut-Sew-Shorts1';
import RugbyCutSewShorts2 from './components/Pages/Rugby Cut Sew Shorts/Rugby-Cut-Sew-Shorts2';
import RugbyCutSewshorts3 from './components/Pages/Rugby Cut Sew Shorts/Rugby-Cut-Sew-Shorts3';
import RugbyCutSewshorts4 from './components/Pages/Rugby Cut Sew Shorts/Rugby-Cut-Sew-Shorts4';
import RugbyCutSewshorts5 from './components/Pages/Rugby Cut Sew Shorts/Rugby-Cut-Sew-Shorts5';
import RugbyCutSewshorts6 from './components/Pages/Rugby Cut Sew Shorts/Rugby-Cut-Sew-Shorts6';
import RugbyCutSewshorts7 from './components/Pages/Rugby Cut Sew Shorts/Rugby-Cut-Sew-Shorts7';
import RugbyCutSewshorts8 from './components/Pages/Rugby Cut Sew Shorts/Rugby-Cut-Sew-Shorts8';
import RugbyCutSewshorts9 from './components/Pages/Rugby Cut Sew Shorts/Rugby-Cut-Sew-Shorts9';
import RugbyCutSewshorts10 from './components/Pages/Rugby Cut Sew Shorts/Rugby-Cut-Sew-Shorts10';
import MensHockeyShirt1 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt1';
import MensHockeyshirt2 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt2';
import MensHockeyshirt3 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt3';
import MensHockeyshirt4 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt4';
import MensHockeyshirt5 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt5';
import MensHockeyshirt6 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt6';
import MensHockeyshirt7 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt7';
import MensHockeyshirt8 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt8';
import MensHockeyshirt9 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt9';
import MensHockeyshirt10 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt10';
import MensHockeyshirt11 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt11';
import MensHockeyshirt12 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt12';
import MensHockeyshirt13 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt13';
import MensHockeyshirt14 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt14';
import MensHockeyshirt15 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt15';
import MensHockeyshirt16 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt16';
import MensHockeyshirt17 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt17';
import MensHockeyshirt18 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt18';
import MensHockeyshirt19 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt19';
import MensHockeyshirt20 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt20';
import LadiesHockeyshirt1 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt1';
import LadiesHockeyshirt2 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt2';
import LadiesHockeyshirt3 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt3';
import LadiesHockeyshirt4 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt4';
import LadiesHockeyshirt5 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt5';
import LadiesHockeyshirt6 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt6';
import LadiesHockeyshirt7 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt7';
import LadiesHockeyshirt8 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt8';
import LadiesHockeyshirt9 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt9';
import LadiesHockeyshirt10 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt10';
import LadiesHockeyshirt11 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt11';
import LadiesHockeyshirt12 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt12';
import LadiesHockeyshirt13 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt13';
import LadiesHockeyshirt14 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt14';
import LadiesHockeyshirt15 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt15';
import LadiesHockeyshirt16 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt16';
import LadiesHockeyshirt17 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt17';
import LadiesHockeyshirt18 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt18';
import LadiesHockeyshirt19 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt19';
import LadiesHockeyshirt20 from './components/Pages/Ladies Hockey Shirts/Ladies-Hockey-Shirt20';
import MensHockeyshorts1 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts1';
import MensHockeyshorts2 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts2';
import MensHockeyshorts3 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts3';
import MensHockeyshorts4 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts4';
import MensHockeyshorts5 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts5';
import MensHockeyshorts6 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts6';
import MensHockeyshorts7 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts7';
import MensHockeyshorts8 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts8';
import MensHockeyshorts9 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts9';
import MensHockeyshorts10 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts10';
import Ladiesvest1 from './components/Pages/Ladies vests/Ladies-Vest1';
import Ladiesvest2 from './components/Pages/Ladies vests/Ladies-Vest2';
import Ladiesvest3 from './components/Pages/Ladies vests/Ladies-Vest3';
import Ladiesvest4 from './components/Pages/Ladies vests/Ladies-Vest4';
import Ladiesvest5 from './components/Pages/Ladies vests/Ladies-Vest5';
import Ladiesvest6 from './components/Pages/Ladies vests/Ladies-Vest6';
import Ladiesvest7 from './components/Pages/Ladies vests/Ladies-Vest7';
import Ladiesvest8 from './components/Pages/Ladies vests/Ladies-Vest8';
import Ladiesvest9 from './components/Pages/Ladies vests/Ladies-Vest9';
import Ladiesvest10 from './components/Pages/Ladies vests/Ladies-Vest10';
import Gksmock1 from './components/Pages/Gk Smock/Gk-Smock1';
import Gksmock2 from './components/Pages/Gk Smock/Gk-Smock2';
import Gksmock3 from './components/Pages/Gk Smock/Gk-Smock3';
import Gksmock4 from './components/Pages/Gk Smock/Gk-Smock4';
import Gksmock5 from './components/Pages/Gk Smock/Gk-Smock5';
import Gksmock6 from './components/Pages/Gk Smock/Gk-Smock6';
import Gksmock7 from './components/Pages/Gk Smock/Gk-Smock7';
import Gksmock8 from './components/Pages/Gk Smock/Gk-Smock8';
import Gksmock9 from './components/Pages/Gk Smock/Gk-Smock9';
import Gksmock10 from './components/Pages/Gk Smock/Gk-Smock10';
import Basketballvest1 from './components/Pages/Basketball Vests/Basketball-Vest1';
import Basketballvest2 from './components/Pages/Basketball Vests/Basketball-Vest2';
import Basketballvest3 from './components/Pages/Basketball Vests/Basketball-Vest3';
import Basketballvest4 from './components/Pages/Basketball Vests/Basketball-Vest4';
import Basketballvest5 from './components/Pages/Basketball Vests/Basketball-Vest5';
import Basketballvest6 from './components/Pages/Basketball Vests/Basketball-Vest6';
import Basketballvest7 from './components/Pages/Basketball Vests/Basketball-Vest7';
import Basketballvest8 from './components/Pages/Basketball Vests/Basketball-Vest8';
import Basketballvest9 from './components/Pages/Basketball Vests/Basketball-Vest9';
import Basketballvest10 from './components/Pages/Basketball Vests/Basketball-Vest10';
import Acheryshirt1 from './components/Pages/Achery Shirts/Achery-Shirt1';
import Acheryshirt2 from './components/Pages/Achery Shirts/Achery-Shirt2';
import Acheryshirt3 from './components/Pages/Achery Shirts/Achery-Shirt3';
import Acheryshirt4 from './components/Pages/Achery Shirts/Achery-Shirt4';
import Acheryshirt5 from './components/Pages/Achery Shirts/Achery-Shirt5';
import Acheryshirt6 from './components/Pages/Achery Shirts/Achery-Shirt6';
import Acheryshirt7 from './components/Pages/Achery Shirts/Achery-Shirt7';
import Acheryshirt8 from './components/Pages/Achery Shirts/Achery-Shirt8';
import Acheryshirt9 from './components/Pages/Achery Shirts/Achery-Shirt9';
import Acheryshirt10 from './components/Pages/Achery Shirts/Achery-Shirt10';
import Acheryshirt11 from './components/Pages/Achery Shirts/Achery-Shirt11';
import Acheryshirt12 from './components/Pages/Achery Shirts/Achery-Shirt12';
import Acheryshirt13 from './components/Pages/Achery Shirts/Achery-Shirt13';
import Acheryshirt14 from './components/Pages/Achery Shirts/Achery-Shirt14';
import Acheryshirt15 from './components/Pages/Achery Shirts/Achery-Shirt15';
import Bowlingshirt1 from './components/Pages/Bowling Shirts/Bowling-Shirt1';
import Bowlingshirt2 from './components/Pages/Bowling Shirts/Bowling-Shirt2';
import Bowlingshirt3 from './components/Pages/Bowling Shirts/Bowling-Shirt3';
import Bowlingshirt4 from './components/Pages/Bowling Shirts/Bowling-Shirt4';
import Bowlingshirt5 from './components/Pages/Bowling Shirts/Bowling-Shirt5';
import Bowlingshirt6 from './components/Pages/Bowling Shirts/Bowling-Shirt6';
import Bowlingshirt7 from './components/Pages/Bowling Shirts/Bowling-Shirt7';
import Bowlingshirt8 from './components/Pages/Bowling Shirts/Bowling-Shirt8';
import Bowlingshirt9 from './components/Pages/Bowling Shirts/Bowling-Shirt9';
import Bowlingshirt10 from './components/Pages/Bowling Shirts/Bowling-Shirt10';
import Bowlingshirt11 from './components/Pages/Bowling Shirts/Bowling-Shirt11';
import Bowlingshirt12 from './components/Pages/Bowling Shirts/Bowling-Shirt12';
import Bowlingshirt13 from './components/Pages/Bowling Shirts/Bowling-Shirt13';
import Bowlingshirt14 from './components/Pages/Bowling Shirts/Bowling-Shirt14';
import Bowlingshirt15 from './components/Pages/Bowling Shirts/Bowling-Shirt15';
import Netballblouse1 from './components/Pages/Netball Blouse/Netball-Blouse1';
import Netballblouse2 from './components/Pages/Netball Blouse/Netball-Blouse2';
import Netballblouse3 from './components/Pages/Netball Blouse/Netball-Blouse3';
import Netballblouse4 from './components/Pages/Netball Blouse/Netball-Blouse4';
import Netballblouse5 from './components/Pages/Netball Blouse/Netball-Blouse5';
import Netballblouse6 from './components/Pages/Netball Blouse/Netball-Blouse6';
import Netballblouse7 from './components/Pages/Netball Blouse/Netball-Blouse7';
import Netballblouse8 from './components/Pages/Netball Blouse/Netball-Blouse8';
import Netballblouse9 from './components/Pages/Netball Blouse/Netball-Blouse9';
import Netballblouse10 from './components/Pages/Netball Blouse/Netball-Blouse10';
import Netballblouse11 from './components/Pages/Netball Blouse/Netball-Blouse11';
import Netballblouse12 from './components/Pages/Netball Blouse/Netball-Blouse12';
import Netballblouse13 from './components/Pages/Netball Blouse/Netball-Blouse13';
import Netballblouse14 from './components/Pages/Netball Blouse/Netball-Blouse14';
import Netballblouse15 from './components/Pages/Netball Blouse/Netball-Blouse15';
import Gilletshirt1 from './components/Pages/Gillet Shirts/Gillet1';
import Gilletshirt2 from './components/Pages/Gillet Shirts/Gillet2';
import Gilletshirt3 from './components/Pages/Gillet Shirts/Gillet3';
import Gilletshirt4 from './components/Pages/Gillet Shirts/Gillet4';
import Gilletshirt5 from './components/Pages/Gillet Shirts/Gillet5';
import LadiesHockeyskorts1 from './components/Pages/Hockey Skorts/Ladies-Hockey-Skorts1';
import LadiesHockeyskorts2 from './components/Pages/Hockey Skorts/Ladies-Hockey-Skorts2';
import LadiesHockeyskorts3 from './components/Pages/Hockey Skorts/Ladies-Hockey-Skorts3';
import LadiesHockeyskorts4 from './components/Pages/Hockey Skorts/Ladies-Hockey-Skorts4';
import LadiesHockeyskorts5 from './components/Pages/Hockey Skorts/Ladies-Hockey-Skorts5';
import LadiesHockeyskorts6 from './components/Pages/Hockey Skorts/Ladies-Hockey-Skorts6';
import LadiesHockeyskorts7 from './components/Pages/Hockey Skorts/Ladies-Hockey-Skorts7';
import LadiesHockeyskorts8 from './components/Pages/Hockey Skorts/Ladies-Hockey-Skorts8';
import LadiesHockeyskorts9 from './components/Pages/Hockey Skorts/Ladies-Hockey-Skorts9';
import LadiesHockeyskorts10 from './components/Pages/Hockey Skorts/Ladies-Hockey-Skorts10';
import HockeySkorts from './components/Pages/Hockey Products/Hockey-Skorts';
import Fleecejacket1 from './components/Pages/Fleece Jacket/Fleece-Jacket1';
import Gillets from './components/Pages/Casual Products/Gillets';
import Fleecejacket2 from './components/Pages/Fleece Jacket/Fleece-Jacket2';
import Fleecejacket3 from './components/Pages/Fleece Jacket/Fleece-Jacket3';
import Fleecejacket4 from './components/Pages/Fleece Jacket/Fleece-Jacket4';
import Fleecejacket5 from './components/Pages/Fleece Jacket/Fleece-Jacket5';
import Fleecejacket6 from './components/Pages/Fleece Jacket/Fleece-Jacket6';
import Fleecejacket7 from './components/Pages/Fleece Jacket/Fleece-Jacket7';
import Fleecejacket8 from './components/Pages/Fleece Jacket/Fleece-Jacket8';
import Fleecejacket9 from './components/Pages/Fleece Jacket/Fleece-Jacket9';
import Fleecejacket10 from './components/Pages/Fleece Jacket/Fleece-Jacket10';
import Polotshirt1 from './components/Pages/Polo Tshirts/Polo-Shirt1';
import Polotshirt2 from './components/Pages/Polo Tshirts/Polo-Shirt2';
import Polotshirt3 from './components/Pages/Polo Tshirts/Polo-Shirt3';
import Polotshirt4 from './components/Pages/Polo Tshirts/Polo-Shirt4';
import Polotshirt5 from './components/Pages/Polo Tshirts/Polo-Shirt5';
import Polotshirt6 from './components/Pages/Polo Tshirts/Polo-Shirt6';
import Polotshirt7 from './components/Pages/Polo Tshirts/Polo-Shirt7';
import Polotshirt8 from './components/Pages/Polo Tshirts/Polo-Shirt8';
import Polotshirt9 from './components/Pages/Polo Tshirts/Polo-Shirt9';
import Polotshirt10 from './components/Pages/Polo Tshirts/Polo-Shirt10';
import Polotshirt11 from './components/Pages/Polo Tshirts/Polo-Shirt11';
import Polotshirt12 from './components/Pages/Polo Tshirts/Polo-Shirt12';
import Polotshirt13 from './components/Pages/Polo Tshirts/Polo-Shirt13';
import Polotshirt14 from './components/Pages/Polo Tshirts/Polo-Shirt14';
import Polotshirt15 from './components/Pages/Polo Tshirts/Polo-Shirt15';
import Managerjacket1 from './components/Pages/Manager Jackets/Manager-Jacket1';
import Managerjacket2 from './components/Pages/Manager Jackets/Manager-Jacket2';
import Managerjacket3 from './components/Pages/Manager Jackets/Manager-Jacket3';
import Managerjacket4 from './components/Pages/Manager Jackets/Manager-Jacket4';
import Managerjacket5 from './components/Pages/Manager Jackets/Manager-Jacket5';
import Managerjacket6 from './components/Pages/Manager Jackets/Manager-Jacket6';
import Managerjacket7 from './components/Pages/Manager Jackets/Manager-Jacket7';
import Managerjacket8 from './components/Pages/Manager Jackets/Manager-Jacket8';
import Managerjacket9 from './components/Pages/Manager Jackets/Manager-Jacket9';
import Managerjacket10 from './components/Pages/Manager Jackets/Manager-Jacket10';
import Netballskort1 from './components/Pages/Netball Skorts/Netball-Skort1';
import Netballskort2 from './components/Pages/Netball Skorts/Netball-Skort2';
import Netballskort3 from './components/Pages/Netball Skorts/Netball-Skort3';
import Netballskort4 from './components/Pages/Netball Skorts/Netball-Skort4';
import Netballskort5 from './components/Pages/Netball Skorts/Netball-Skort5';
import Netballskort6 from './components/Pages/Netball Skorts/Netball-Skort6';
import Netballskort7 from './components/Pages/Netball Skorts/Netball-Skort7';
import Netballskort8 from './components/Pages/Netball Skorts/Netball-Skort8';
import Netballskort9 from './components/Pages/Netball Skorts/Netball-Skort9';
import Netballskort10 from './components/Pages/Netball Skorts/Netball-Skort10';
import Netballtshirt1 from './components/Pages/Netball Tshirts/Netball-Top1';
import Netballtshirt2 from './components/Pages/Netball Tshirts/Netball-Top2';
import Netballtshirt3 from './components/Pages/Netball Tshirts/Netball-Top3';
import Netballtshirt4 from './components/Pages/Netball Tshirts/Netball-Top4';
import Netballtshirt5 from './components/Pages/Netball Tshirts/Netball-Top5';
import Netballtshirt6 from './components/Pages/Netball Tshirts/Netball-Top6';
import Netballtshirt7 from './components/Pages/Netball Tshirts/Netball-Top7';
import Netballtshirt8 from './components/Pages/Netball Tshirts/Netball-Top8';
import Netballtshirt9 from './components/Pages/Netball Tshirts/Netball-Top9';
import Netballtshirt10 from './components/Pages/Netball Tshirts/Netball-Top10';
import Netballtshirt11 from './components/Pages/Netball Tshirts/Netball-Top11';
import Netballtshirt12 from './components/Pages/Netball Tshirts/Netball-Top12';
import Netballtshirt13 from './components/Pages/Netball Tshirts/Netball-Top13';
import Netballtshirt14 from './components/Pages/Netball Tshirts/Netball-Top14';
import Netballtshirt15 from './components/Pages/Netball Tshirts/Netball-Top15';
import Crickettshirt1 from './components/Pages/Cricket Tshirts/Cricket-Shirt1';
import Crickettshirt2 from './components/Pages/Cricket Tshirts/Cricket-Shirt2';
import Crickettshirt3 from './components/Pages/Cricket Tshirts/Cricket-Shirt3';
import Crickettshirt4 from './components/Pages/Cricket Tshirts/Cricket-Shirt4';
import Crickettshirt5 from './components/Pages/Cricket Tshirts/Cricket-Shirt5';
import Crickettshirt6 from './components/Pages/Cricket Tshirts/Cricket-Shirt6';
import Crickettshirt7 from './components/Pages/Cricket Tshirts/Cricket-Shirt7';
import Crickettshirt8 from './components/Pages/Cricket Tshirts/Cricket-Shirt8';
import Crickettshirt9 from './components/Pages/Cricket Tshirts/Cricket-Shirt9';
import Crickettshirt10 from './components/Pages/Cricket Tshirts/Cricket-Shirt10';
import Cricketpant1 from './components/Pages/Cricket Pants/Cricket-Pant1';
import Cricketpant2 from './components/Pages/Cricket Pants/Cricket-Pant2';
import Cricketpant3 from './components/Pages/Cricket Pants/Cricket-Pant3';
import Cricketpant4 from './components/Pages/Cricket Pants/Cricket-Pant4';
import Cricketpant5 from './components/Pages/Cricket Pants/Cricket-Pant5';

import About from './components/Pages/About-us';
import Basketballshorts1 from './components/Pages/Basketball Shorts/Basketball-Shorts1';
import Basketballshorts2 from './components/Pages/Basketball Shorts/Basketball-Shorts2';
import Basketballshorts3 from './components/Pages/Basketball Shorts/Basketball-Shorts3';
import Basketballshorts4 from './components/Pages/Basketball Shorts/Basketball-Shorts4';
import Basketballshorts5 from './components/Pages/Basketball Shorts/Basketball-Shorts5';
import Basketballshorts6 from './components/Pages/Basketball Shorts/Basketball-Shorts6';
import Basketballshorts7 from './components/Pages/Basketball Shorts/Basketball-Shorts7';
import Basketballshorts8 from './components/Pages/Basketball Shorts/Basketball-Shorts8';
import Basketballshorts9 from './components/Pages/Basketball Shorts/Basketball-Shorts9';
import Basketballshorts10 from './components/Pages/Basketball Shorts/Basketball-Shorts10';
import Soccershorts1 from './components/Pages/Soccer Shorts/Soccer-Shorts1';
import Soccershorts2 from './components/Pages/Soccer Shorts/Soccer-Shorts2';
import Soccershorts3 from './components/Pages/Soccer Shorts/Soccer-Shorts3';
import Soccershorts4 from './components/Pages/Soccer Shorts/Soccer-Shorts4';
import Soccershorts5 from './components/Pages/Soccer Shorts/Soccer-Shorts5';
import Soccershorts6 from './components/Pages/Soccer Shorts/Soccer-Shorts6';
import Soccershorts7 from './components/Pages/Soccer Shorts/Soccer-Shorts7';
import Soccershorts8 from './components/Pages/Soccer Shorts/Soccer-Shorts8';
import Soccershorts9 from './components/Pages/Soccer Shorts/Soccer-Shorts9';
import Soccershorts10 from './components/Pages/Soccer Shorts/Soccer-Shorts10';
import Soccertshirt1 from './components/Pages/Soccer Tshirts/Soccer-Shirt1';
import Soccertshirt2 from './components/Pages/Soccer Tshirts/Soccer-Shirt2';
import Soccertshirt3 from './components/Pages/Soccer Tshirts/Soccer-Shirt3';
import Soccertshirt4 from './components/Pages/Soccer Tshirts/Soccer-Shirt4';
import Soccertshirt5 from './components/Pages/Soccer Tshirts/Soccer-Shirt5';
import Soccertshirt6 from './components/Pages/Soccer Tshirts/Soccer-Shirt6';
import Soccertshirt7 from './components/Pages/Soccer Tshirts/Soccer-Shirt7';
import Soccertshirt8 from './components/Pages/Soccer Tshirts/Soccer-Shirt8';
import Soccertshirt9 from './components/Pages/Soccer Tshirts/Soccer-Shirt9';
import Soccertshirt10 from './components/Pages/Soccer Tshirts/Soccer-Shirt10';
import Hoodedsweatshirts from './components/Pages/Hoodie Products/Hooded-Sweatshirts';
import Quarterzipsweatshirts from './components/Pages/Hoodie Products/Quarter-Zip-Sweatshirts';
import Roundnecksweatshirts from './components/Pages/Hoodie Products/Round-Neck-Sweatshirts';
import Roundnecksweatshirt1 from './components/Pages/Round Neck Sweatshirts/Round-Neck-Sweatshirt1';
import Roundnecksweatshirt2 from './components/Pages/Round Neck Sweatshirts/Round-Neck-Sweatshirt2';
import Roundnecksweatshirt3 from './components/Pages/Round Neck Sweatshirts/Round-Neck-Sweatshirt3';
import Roundnecksweatshirt4 from './components/Pages/Round Neck Sweatshirts/Round-Neck-Sweatshirt4';
import Roundnecksweatshirt5 from './components/Pages/Round Neck Sweatshirts/Round-Neck-Sweatshirt5';
import Roundnecksweatshirt6 from './components/Pages/Round Neck Sweatshirts/Round-Neck-Sweatshirt6';
import Roundnecksweatshirt7 from './components/Pages/Round Neck Sweatshirts/Round-Neck-Sweatshirt7';
import Roundnecksweatshirt8 from './components/Pages/Round Neck Sweatshirts/Round-Neck-Sweatshirt8';
import Roundnecksweatshirt9 from './components/Pages/Round Neck Sweatshirts/Round-Neck-Sweatshirt9';
import Roundnecksweatshirt10 from './components/Pages/Round Neck Sweatshirts/Round-Neck-Sweatshirt10';
import Hoodedsweathoodie1 from './components/Pages/Hooded Sweatshirts/Hooded-Sweatshirt1';
import Hoodedsweathoddie2 from './components/Pages/Hooded Sweatshirts/Hooded-Sweatshirt2';
import Hoodedsweathoddie3 from './components/Pages/Hooded Sweatshirts/Hooded-Sweatshirt3';
import Hoodedsweathoddie4 from './components/Pages/Hooded Sweatshirts/Hooded-Sweatshirt4';
import Hoodedsweathoddie5 from './components/Pages/Hooded Sweatshirts/Hooded-Sweatshirt5';
import Hoodedsweathoddie6 from './components/Pages/Hooded Sweatshirts/Hooded-Sweatshirt6';
import Hoodedsweathoddie7 from './components/Pages/Hooded Sweatshirts/Hooded-Sweatshirt7';
import Hoodedsweathoddie8 from './components/Pages/Hooded Sweatshirts/Hooded-Sweatshirt8';
import Hoodedsweathoddie9 from './components/Pages/Hooded Sweatshirts/Hooded-Sweatshirt9';
import Hoodedsweathoddie10 from './components/Pages/Hooded Sweatshirts/Hooded-Sweatshirt10';
import Quarterziphoodie1 from './components/Pages/Quarter Zip Sweatshirts/Quarter-Zip-Sweatshirt1';
import Quarterziphoodie2 from './components/Pages/Quarter Zip Sweatshirts/Quarter-Zip-Sweatshirt2';
import Quarterziphoodie3 from './components/Pages/Quarter Zip Sweatshirts/Quarter-Zip-Sweatshirt3';
import Quarterziphoodie4 from './components/Pages/Quarter Zip Sweatshirts/Quarter-Zip-Sweatshirt4';
import Quarterziphoodie5 from './components/Pages/Quarter Zip Sweatshirts/Quarter-Zip-Sweatshirt5';
import Quarterziphoodie6 from './components/Pages/Quarter Zip Sweatshirts/Quarter-Zip-Sweatshirt6';
import Quarterziphoodie7 from './components/Pages/Quarter Zip Sweatshirts/Quarter-Zip-Sweatshirt7';
import Quarterziphoodie8 from './components/Pages/Quarter Zip Sweatshirts/Quarter-Zip-Sweatshirt8';
import Quarterziphoodie9 from './components/Pages/Quarter Zip Sweatshirts/Quarter-Zip-Sweatshirt9';
import Quarterziphoodie10 from './components/Pages/Quarter Zip Sweatshirts/Quarter-Zip-Sweatshirt10';
import Tshirts from './components/Pages/Training Products/T-shirts';
import Tracktop1 from './components/Pages/Track Top/Track-Top1';
import Tracktop2 from './components/Pages/Track Top/Track-Top2';
import Tracktop3 from './components/Pages/Track Top/Track-Top3';
import Tracktop4 from './components/Pages/Track Top/Track-Top4';
import Tracktop5 from './components/Pages/Track Top/Track-Top5';
import Tracktop6 from './components/Pages/Track Top/Track-Top6';
import Tracktop7 from './components/Pages/Track Top/Track-Top7';
import Tracktop8 from './components/Pages/Track Top/Track-Top8';
import Tracktop9 from './components/Pages/Track Top/Track-Top9';
import Tracktop10 from './components/Pages/Track Top/Track-Top10';
import Trainingtop1 from './components/Pages/Training Top/Training-Top1';
import Trainingtop2 from './components/Pages/Training Top/Training-Top2';
import Trainingtop3 from './components/Pages/Training Top/Training-Top3';
import Trainingtop4 from './components/Pages/Training Top/Training-Top4';
import Trainingtop5 from './components/Pages/Training Top/Training-Top5';
import Trainingtop6 from './components/Pages/Training Top/Training-Top6';
import Trainingtop7 from './components/Pages/Training Top/Training-Top7';
import Trainingtop8 from './components/Pages/Training Top/Training-Top8';
import Trainingtop9 from './components/Pages/Training Top/Training-Top9';
import Trainingtop10 from './components/Pages/Training Top/Training-Top10';
import Tshirt1 from './components/Pages/Tshirts/Tshirt1';
import Tshirt2 from './components/Pages/Tshirts/Tshirt2';
import Tshirt3 from './components/Pages/Tshirts/Tshirt3';
import Tshirt4 from './components/Pages/Tshirts/Tshirt4';
import Tshirt5 from './components/Pages/Tshirts/Tshirt5';
import Tshirt6 from './components/Pages/Tshirts/Tshirt6';
import Tshirt7 from './components/Pages/Tshirts/Tshirt7';
import Tshirt8 from './components/Pages/Tshirts/Tshirt8';
import Tshirt9 from './components/Pages/Tshirts/Tshirt9';
import Tshirt10 from './components/Pages/Tshirts/Tshirt10';
import Vest1 from './components/Pages/Vests/Vest1';
import Vest2 from './components/Pages/Vests/Vest2';
import Vest3 from './components/Pages/Vests/Vest3';
import Vest4 from './components/Pages/Vests/Vest4';
import Vest5 from './components/Pages/Vests/Vest5';
import Vest6 from './components/Pages/Vests/Vest6';
import Vest7 from './components/Pages/Vests/Vest7';
import Vest8 from './components/Pages/Vests/Vest8';
import Vest9 from './components/Pages/Vests/Vest9';
import Vest10 from './components/Pages/Vests/Vest10';
import CorelayerFullsleeves from './components/Pages/Training Products/Corelayer-Fullsleeves';
import CorelayerFullsleeves1 from './components/Pages/Corelayer Fullsleeves/Corelayer-Fullsleeves1';
import CorelayerFullsleeves2 from './components/Pages/Corelayer Fullsleeves/Corelayer-Fullsleeves2';
import CorelayerFullsleeves3 from './components/Pages/Corelayer Fullsleeves/Corelayer-Fullsleeves3';
import CorelayerFullsleeves4 from './components/Pages/Corelayer Fullsleeves/Corelayer-Fullsleeves4';
import CorelayerFullsleeves5 from './components/Pages/Corelayer Fullsleeves/Corelayer-Fullsleeves5';
import CorelayerHalfsleeves from './components/Pages/Training Products/Corelayer-Halfsleeves';
import CorelayerHalfsleeves1 from './components/Pages/Corelayer Halfsleeves/Corelayer-Halfsleeves1';
import CorelayerHalfsleeves2 from './components/Pages/Corelayer Halfsleeves/Corelayer-Halfsleeves2';
import CorelayerHalfsleeves3 from './components/Pages/Corelayer Halfsleeves/Corelayer-Halfsleeves3';
import CorelayerHalfsleeves4 from './components/Pages/Corelayer Halfsleeves/Corelayer-Halfsleeves4';
import CorelayerHalfsleeves5 from './components/Pages/Corelayer Halfsleeves/Corelayer-Halfsleeves5';
import CorelayerSleeveless from './components/Pages/Training Products/Corelayer-Sleeveless';
import CorelayerSleeveless1 from './components/Pages/Corelayer Sleeveless/Corelayer-Sleeveless1';
import CorelayerSleeveless2 from './components/Pages/Corelayer Sleeveless/Corelayer-Sleeveless2';
import CorelayerSleeveless3 from './components/Pages/Corelayer Sleeveless/Corelayer-Sleeveless3';
import CorelayerSleeveless4 from './components/Pages/Corelayer Sleeveless/Corelayer-Sleeveless4';
import CorelayerSleeveless5 from './components/Pages/Corelayer Halfsleeves/Corelayer-Halfsleeves5';
import Trackpant1 from './components/Pages/Track Pants/Track-Pant1';
import Trackpant2 from './components/Pages/Track Pants/Track-Pant2';
import Trackpant3 from './components/Pages/Track Pants/Track-Pant3';
import Trackpant4 from './components/Pages/Track Pants/Track-Pant4';
import Trackpant5 from './components/Pages/Track Pants/Track-Pant5';
import Trackpant6 from './components/Pages/Track Pants/Track-Pant6';
import Trackpant7 from './components/Pages/Track Pants/Track-Pant7';
import MensHockeyshorts11 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts11';
import MensHockeyshorts12 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts12';
import MensHockeyshorts13 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts13';
import MensHockeyshorts14 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts14';
import MensHockeyshorts15 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts15';
import MensHockeyshorts16 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts16';
import MensHockeyshorts17 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts17';
import MensHockeyshorts18 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts18';
import MensHockeyshorts19 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts19';
import MensHockeyshorts20 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts20';
import Workwear from './components/Pages/Workwear';
import Crickettshirt11 from './components/Pages/Cricket Tshirts/Cricket-Shirt11';
import Gksmock11 from './components/Pages/Gk Smock/Gk-Smock11';
import Quarterziphoodie11 from './components/Pages/Quarter Zip Sweatshirts/Quarter-Zip-Sweatshirt11';
import Netballblouse16 from './components/Pages/Netball Blouse/Netball-Blouse16';
import MensHockeyshirt21 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt21';
import MensHockeyshirt22 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt22';
import MensHockeyshirt23 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt23';
import MensHockeyshirt24 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt24';
import MensHockeyshirt25 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt25';
import MensHockeyshirt26 from './components/Pages/Men Hocket Shirts/Mens-Hockey-Shirt26';
import Polotshirt16 from './components/Pages/Polo Tshirts/Polo-Shirt16';
import Polotshirt17 from './components/Pages/Polo Tshirts/Polo-Shirt17';
import Polotshirt18 from './components/Pages/Polo Tshirts/Polo-Shirt18';
import Polotshirt19 from './components/Pages/Polo Tshirts/Polo-Shirt19';
import Hoodedsweathoddie11 from './components/Pages/Hooded Sweatshirts/Hooded-Sweatshirt11';
import Hoodedsweathoddie12 from './components/Pages/Hooded Sweatshirts/Hooded-Sweatshirt12';
import Vest11 from './components/Pages/Vests/Vest11';
import Vest12 from './components/Pages/Vests/Vest12';
import Vest13 from './components/Pages/Vests/Vest13';
import MensHockeyshorts21 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts21';
import MensHockeyshorts22 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts22';
import MensHockeyshorts23 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts23';
import MensHockeyshorts24 from './components/Pages/Hockey Shorts/Mens-Hockey-Shorts24';
import Trainingtop11 from './components/Pages/Training Top/Training-Top11';
import Trainingtop12 from './components/Pages/Training Top/Training-Top12';
import Trainingtop13 from './components/Pages/Training Top/Training-Top13';
import Trainingtop14 from './components/Pages/Training Top/Training-Top14';
import Trainingtop15 from './components/Pages/Training Top/Training-Top15';
import RugbySocks11 from './components/Pages/Rugby Socks/Rugby-Socks11';
import RugbySocks12 from './components/Pages/Rugby Socks/Rugby-Socks12';
import RugbySocks13 from './components/Pages/Rugby Socks/Rugby-Socks13';
import RugbySocks14 from './components/Pages/Rugby Socks/Rugby-Socks14';
import ManagerJacket from './components/Pages/Casual Products/Manager-Jacket';

import Stadiumjacket1 from './components/Pages/Stadium Jackets/Stadium-Jacket1';
import StadiumJacket from './components/Pages/Casual Products/Stadium-Jacket';
import Stadiumjacket2 from './components/Pages/Stadium Jackets/Stadium-Jacket2';
import Stadiumjacket3 from './components/Pages/Stadium Jackets/Stadium-Jacket3';
import Stadiumjacket4 from './components/Pages/Stadium Jackets/Stadium-Jacket4';
import Stadiumjacket5 from './components/Pages/Stadium Jackets/Stadium-Jacket5';




class Root extends Component {
    render() {
        return(
                <Router>
	                <div>
	                <Switch>

                    {/* Pages */}
                    <Route exact path="/" component={Entryhome}/>
                    <Route exact path="/Home" component={Home}/>
                    <Route exact path="/Workwear" component={Workwear}/>
                    <Route exact path="/Career" component={Career}/>
                    <Route exact path="/About-us" component={About}/>
                    <Route exact path="/How-to-order" component={Howtoorder}/>
                    <Route exact path="/promotions" component={Promotions}/>
                    <Route exact path="/gallery" component={Gallery}/>
                    <Route exact path="/Collaborations" component={Collaborations}/>
                    <Route exact path="/blogs" component={Blog}/>
                     <Route exact path="/faqs" component={Faq}/>
                    <Route exact path="/Contact-us" component={Contact}/>
                    <Route exact path="/privacypolicy" component={Privacypolicy} />
                    <Route path="/coming-soon" component={ ComingSoon } />
                    <Route path="/404" component={ Error } />
                    <Route path="/location" component={ Location } />
                    <Route exact path="/thank-you" component={Thankyou}/>


                    {/* Sports */}

                    {/* Sports internal Pages */}
                    <Route exact path="/Track-Pants" component={TrackPants}/>
                    <Route exact path="/Track-Top" component={TrackTop}/>
                    <Route exact path="/Training-Top" component={TrainingTop}/>
                    <Route exact path="/T-shirts" component={Tshirts}/>
                    <Route exact path="/Vests" component={Vests}/>
                    <Route exact path="/Corelayer-Fullsleeves" component={CorelayerFullsleeves}/>
                    <Route exact path="/Corelayer-Halfsleeves" component={CorelayerHalfsleeves}/>
                    <Route exact path="/Corelayer-Sleeveless" component={CorelayerSleeveless}/>
                    <Route exact path="/Gillets" component={Gillets}/>
                    <Route exact path="/Fleece-Jacket" component={FleeceJacket}/>
                    <Route exact path="/Hoodie" component={Hoodie}/>
                    <Route exact path="/Polo-Shirts" component={PoloShirts}/>
                    {/* <Route exact path="/Bowling" component={Bowling}/> */}
                    <Route exact path="/Soccer-Socks" component={SoccerSocks}/>
                    <Route exact path="/Soccer-Shorts" component={SoccerShorts}/>
                    <Route exact path="/Soccer-Shirts" component={SoccerTshirts}/>
                    <Route exact path="/Cricket-Pants" component={CricketPants}/>
                    <Route exact path="/Cricket-Shirts" component={CricketTShirts}/>
                    <Route exact path="/Basketball-Shorts" component={BasketballShorts}/>
                    <Route exact path="/Basketball-Vest" component={BasketballVest}/>
                    <Route exact path="/Hockey-Socks" component={HockeySocks}/>
                    <Route exact path="/Hockey-Shorts" component={HockeyShorts}/>
                    {/* <Route exact path="/Hockey-Skorts" component={HockeySkortssport}/> */}
                    <Route exact path="/Hockey-Skorts" component={HockeySkorts}/>
                    <Route exact path="/Gk-Smock" component={GkSmock}/>
                    <Route exact path="/Ladies-Vest" component={LadiesVest}/>
                    <Route exact path="/Mens-Hockey-Shirts" component={MensHockeyShirts}/>
                    <Route exact path="/Ladies-Hockey-Shirts" component={LadiesHockeyShirts}/>
                    <Route exact path="/Rugby-Socks" component={RugbySocks}/>
                    <Route exact path="/Sublimation-Rugby-Shorts" component={SublimationRugbyShorts}/>
                    <Route exact path="/Netball-Skorts" component={NetballSkorts}/>
                    <Route exact path="/Rugby-Shorts" component={CutSewRugbyShorts}/>
                    <Route exact path="/Netball-Top" component={NetballShirts}/>
                    <Route exact path="/Netball-Blouse" component={NetballBlouse}/>
                    <Route exact path="/Rugby-Jerseys" component={SublimationRugbyJersey}/>
                    <Route exact path="/manager-jacket" component={ ManagerJacket}/>
                    <Route exact path="/stadium-jacket" component={ StadiumJacket}/>
                    <Route exact path="/Hooded-Sweatshirts" component={Hoodedsweatshirts}/>
                    <Route exact path="/Quarter-Zip-Sweatshirts" component={Quarterzipsweatshirts}/>
                    <Route exact path="/Round-Neck-Sweatshirts" component={Roundnecksweatshirts}/>

                    
                    {/* RUGBY */}

                    {/* Rugby Socks */}
                    <Route exact path="/Rugby-Socks1" component={RugbySocks1}/>
                    <Route exact path="/Rugby-Socks2" component={RugbySocks2}/>
                    <Route exact path="/Rugby-Socks3" component={RugbySocks3}/>
                    <Route exact path="/Rugby-Socks4" component={RugbySocks4}/>
                    <Route exact path="/Rugby-Socks5" component={RugbySocks5}/>
                    <Route exact path="/Rugby-Socks6" component={RugbySocks6}/>
                    <Route exact path="/Rugby-Socks7" component={RugbySocks7}/>
                    <Route exact path="/Rugby-Socks8" component={RugbySocks8}/>
                    <Route exact path="/Rugby-Socks9" component={RugbySocks9}/>
                    <Route exact path="/Rugby-Socks10" component={RugbySocks10}/>
                    <Route exact path="/Rugby-Socks11" component={RugbySocks11}/>
                    <Route exact path="/Rugby-Socks12" component={RugbySocks12}/>
                    <Route exact path="/Rugby-Socks13" component={RugbySocks13}/>
                    <Route exact path="/Rugby-Socks14" component={RugbySocks14}/>


                    {/* Rugby Jersey */}
                    <Route exact path="/Rugby-Jersey1" component={RugbyJersy1}/>
                    <Route exact path="/Rugby-Jersey2" component={RugbyJersy2}/>
                    <Route exact path="/Rugby-Jersey3" component={RugbyJersy3}/>
                    <Route exact path="/Rugby-Jersey4" component={RugbyJersy4}/>
                    <Route exact path="/Rugby-Jersey5" component={RugbyJersy5}/>
                    <Route exact path="/Rugby-Jersey6" component={RugbyJersy6}/>
                    <Route exact path="/Rugby-Jersey7" component={RugbyJersy7}/>
                    <Route exact path="/Rugby-Jersey8" component={RugbyJersy8}/>
                    <Route exact path="/Rugby-Jersey9" component={RugbyJersy9}/>
                    <Route exact path="/Rugby-Jersey10" component={RugbyJersy10}/>
                    <Route exact path="/Rugby-Jersey11" component={RugbyJersy11}/>
                    <Route exact path="/Rugby-Jersey12" component={RugbyJersy12}/>
                    <Route exact path="/Rugby-Jersey13" component={RugbyJersy13}/>
                    <Route exact path="/Rugby-Jersey14" component={RugbyJersy14}/>
                    <Route exact path="/Rugby-Jersey15" component={RugbyJersy15}/>
                    <Route exact path="/Rugby-Jersey16" component={RugbyJersy16}/>
                    <Route exact path="/Rugby-Jersey17" component={RugbyJersy17}/>
                    <Route exact path="/Rugby-Jersey18" component={RugbyJersy18}/>
                    <Route exact path="/Rugby-Jersey19" component={RugbyJersy19}/>
                    <Route exact path="/Rugby-Jersey20" component={RugbyJersy20}/>
                    <Route exact path="/Rugby-Jersey21" component={RugbyJersy21}/>
                    <Route exact path="/Rugby-Jersey22" component={RugbyJersy22}/>
                    <Route exact path="/Rugby-Jersey23" component={RugbyJersy23}/>
                    <Route exact path="/Rugby-Jersey24" component={RugbyJersy24}/>
                    <Route exact path="/Rugby-Jersey25" component={RugbyJersy25}/>
                    <Route exact path="/Rugby-Jersey26" component={RugbyJersy26}/>
                    <Route exact path="/Rugby-Jersey27" component={RugbyJersy27}/>
                    <Route exact path="/Rugby-Jersey28" component={RugbyJersy28}/>
                    <Route exact path="/Rugby-Jersey29" component={RugbyJersy29}/>
                    <Route exact path="/Rugby-Jersey30" component={RugbyJersy30}/>
                    <Route exact path="/Rugby-Jersey31" component={RugbyJersy31}/>
                    <Route exact path="/Rugby-Jersey32" component={RugbyJersy32}/>
                    <Route exact path="/Rugby-Jersey33" component={RugbyJersy33}/>
                    <Route exact path="/Rugby-Jersey34" component={RugbyJersy34}/>


                    {/* Rugby Shorts */}
                    <Route exact path="/Rugby-Shorts1" component={Rugbyshorts1}/>
                    <Route exact path="/Rugby-Shorts2" component={Rugbyshorts2}/>
                    <Route exact path="/Rugby-Shorts3" component={Rugbyshorts3}/>
                    <Route exact path="/Rugby-Shorts4" component={Rugbyshorts4}/>
                    <Route exact path="/Rugby-Shorts5" component={Rugbyshorts5}/>
                    <Route exact path="/Rugby-Shorts6" component={Rugbyshorts6}/>
                    <Route exact path="/Rugby-Shorts7" component={Rugbyshorts7}/>
                    <Route exact path="/Rugby-Shorts8" component={Rugbyshorts8}/>
                    <Route exact path="/Rugby-Shorts9" component={Rugbyshorts9}/>
                    <Route exact path="/Rugby-Shorts10" component={Rugbyshorts10}/>
                    <Route exact path="/Rugby-Shorts11" component={Rugbyshorts11}/>
                    <Route exact path="/Rugby-Shorts12" component={Rugbyshorts12}/>
                    <Route exact path="/Rugby-Shorts13" component={Rugbyshorts13}/>
                    <Route exact path="/Rugby-Shorts14" component={Rugbyshorts14}/>
                    <Route exact path="/Rugby-Shorts15" component={Rugbyshorts15}/>
                    <Route exact path="/Rugby-Shorts16" component={Rugbyshorts16}/>
                    <Route exact path="/Rugby-Shorts17" component={Rugbyshorts17}/>
                    <Route exact path="/Rugby-Shorts18" component={Rugbyshorts18}/>
                    <Route exact path="/Rugby-Shorts19" component={Rugbyshorts19}/>
                    <Route exact path="/Rugby-Shorts20" component={Rugbyshorts20}/>


                    {/* Rugby Cut Sew Shorts */}
                    <Route exact path="/Rugby-Cut-Sew-Shorts1" component={RugbyCutSewShorts1}/>
                    <Route exact path="/Rugby-Cut-Sew-Shorts2" component={RugbyCutSewShorts2}/>
                    <Route exact path="/Rugby-Cut-Sew-Shorts3" component={RugbyCutSewshorts3}/>
                    <Route exact path="/Rugby-Cut-Sew-Shorts4" component={RugbyCutSewshorts4}/>
                    <Route exact path="/Rugby-Cut-Sew-Shorts5" component={RugbyCutSewshorts5}/>
                    <Route exact path="/Rugby-Cut-Sew-Shorts6" component={RugbyCutSewshorts6}/>
                    <Route exact path="/Rugby-Cut-Sew-Shorts7" component={RugbyCutSewshorts7}/>
                    <Route exact path="/Rugby-Cut-Sew-Shorts8" component={RugbyCutSewshorts8}/>
                    <Route exact path="/Rugby-Cut-Sew-Shorts9" component={RugbyCutSewshorts9}/>
                    <Route exact path="/Rugby-Cut-Sew-Shorts10" component={RugbyCutSewshorts10}/>


                    {/* Men Hockey Shirts */}
                    <Route exact path="/Mens-Hockey-Shirt1" component={MensHockeyShirt1}/>
                    <Route exact path="/Mens-Hockey-Shirt2" component={MensHockeyshirt2}/>
                    <Route exact path="/Mens-Hockey-Shirt3" component={MensHockeyshirt3}/>
                    <Route exact path="/Mens-Hockey-Shirt4" component={MensHockeyshirt4}/>
                    <Route exact path="/Mens-Hockey-Shirt5" component={MensHockeyshirt5}/>
                    <Route exact path="/Mens-Hockey-Shirt6" component={MensHockeyshirt6}/>
                    <Route exact path="/Mens-Hockey-Shirt7" component={MensHockeyshirt7}/>
                    <Route exact path="/Mens-Hockey-Shirt8" component={MensHockeyshirt8}/>
                    <Route exact path="/Mens-Hockey-Shirt9" component={MensHockeyshirt9}/>
                    <Route exact path="/Mens-Hockey-Shirt10" component={MensHockeyshirt10}/>
                    <Route exact path="/Mens-Hockey-Shirt11" component={MensHockeyshirt11}/>
                    <Route exact path="/Mens-Hockey-Shirt12" component={MensHockeyshirt12}/>
                    <Route exact path="/Mens-Hockey-Shirt13" component={MensHockeyshirt13}/>
                    <Route exact path="/Mens-Hockey-Shirt14" component={MensHockeyshirt14}/>
                    <Route exact path="/Mens-Hockey-Shirt15" component={MensHockeyshirt15}/>
                    <Route exact path="/Mens-Hockey-Shirt16" component={MensHockeyshirt16}/>
                    <Route exact path="/Mens-Hockey-Shirt17" component={MensHockeyshirt17}/>
                    <Route exact path="/Mens-Hockey-Shirt18" component={MensHockeyshirt18}/>
                    <Route exact path="/Mens-Hockey-Shirt19" component={MensHockeyshirt19}/>
                    <Route exact path="/Mens-Hockey-Shirt20" component={MensHockeyshirt20}/>
                    <Route exact path="/Mens-Hockey-Shirt21" component={MensHockeyshirt21}/>
                    <Route exact path="/Mens-Hockey-Shirt22" component={MensHockeyshirt22}/>
                    <Route exact path="/Mens-Hockey-Shirt23" component={MensHockeyshirt23}/>
                    <Route exact path="/Mens-Hockey-Shirt24" component={MensHockeyshirt24}/>
                    <Route exact path="/Mens-Hockey-Shirt25" component={MensHockeyshirt25}/>
                    <Route exact path="/Mens-Hockey-Shirt26" component={MensHockeyshirt26}/>



                    {/* Ladies Hockey Shirts */}
                    <Route exact path="/Ladies-Hockey-Shirt1" component={LadiesHockeyshirt1}/>
                    <Route exact path="/Ladies-Hockey-Shirt2" component={LadiesHockeyshirt2}/>
                    <Route exact path="/Ladies-Hockey-Shirt3" component={LadiesHockeyshirt3}/>
                    <Route exact path="/Ladies-Hockey-Shirt4" component={LadiesHockeyshirt4}/>
                    <Route exact path="/Ladies-Hockey-Shirt5" component={LadiesHockeyshirt5}/>
                    <Route exact path="/Ladies-Hockey-Shirt6" component={LadiesHockeyshirt6}/>
                    <Route exact path="/Ladies-Hockey-Shirt7" component={LadiesHockeyshirt7}/>
                    <Route exact path="/Ladies-Hockey-Shirt8" component={LadiesHockeyshirt8}/>
                    <Route exact path="/Ladies-Hockey-Shirt9" component={LadiesHockeyshirt9}/>
                    <Route exact path="/Ladies-Hockey-Shirt10" component={LadiesHockeyshirt10}/>
                    <Route exact path="/Ladies-Hockey-Shirt11" component={LadiesHockeyshirt11}/>
                    <Route exact path="/Ladies-Hockey-Shirt12" component={LadiesHockeyshirt12}/>
                    <Route exact path="/Ladies-Hockey-Shirt13" component={LadiesHockeyshirt13}/>
                    <Route exact path="/Ladies-Hockey-Shirt14" component={LadiesHockeyshirt14}/>
                    <Route exact path="/Ladies-Hockey-Shirt15" component={LadiesHockeyshirt15}/>
                    <Route exact path="/Ladies-Hockey-Shirt16" component={LadiesHockeyshirt16}/>
                    <Route exact path="/Ladies-Hockey-Shirt17" component={LadiesHockeyshirt17}/>
                    <Route exact path="/Ladies-Hockey-Shirt18" component={LadiesHockeyshirt18}/>
                    <Route exact path="/Ladies-Hockey-Shirt19" component={LadiesHockeyshirt19}/>
                    <Route exact path="/Ladies-Hockey-Shirt20" component={LadiesHockeyshirt20}/>


                    {/* Men's Hockey Shorts */}
                    <Route exact path="/Mens-Hockey-Shorts1" component={MensHockeyshorts1}/>
                    <Route exact path="/Mens-Hockey-Shorts2" component={MensHockeyshorts2}/>
                    <Route exact path="/Mens-Hockey-Shorts3" component={MensHockeyshorts3}/>
                    <Route exact path="/Mens-Hockey-Shorts4" component={MensHockeyshorts4}/>
                    <Route exact path="/Mens-Hockey-Shorts5" component={MensHockeyshorts5}/>
                    <Route exact path="/Mens-Hockey-Shorts6" component={MensHockeyshorts6}/>
                    <Route exact path="/Mens-Hockey-Shorts7" component={MensHockeyshorts7}/>
                    <Route exact path="/Mens-Hockey-Shorts8" component={MensHockeyshorts8}/>
                    <Route exact path="/Mens-Hockey-Shorts9" component={MensHockeyshorts9}/>
                    <Route exact path="/Mens-Hockey-Shorts10" component={MensHockeyshorts10}/>
                    <Route exact path="/Mens-Hockey-Shorts11" component={MensHockeyshorts11}/>
                    <Route exact path="/Mens-Hockey-Shorts12" component={MensHockeyshorts12}/>
                    <Route exact path="/Mens-Hockey-Shorts13" component={MensHockeyshorts13}/>
                    <Route exact path="/Mens-Hockey-Shorts14" component={MensHockeyshorts14}/>
                    <Route exact path="/Mens-Hockey-Shorts15" component={MensHockeyshorts15}/>
                    <Route exact path="/Mens-Hockey-Shorts16" component={MensHockeyshorts16}/>
                    <Route exact path="/Mens-Hockey-Shorts17" component={MensHockeyshorts17}/>
                    <Route exact path="/Mens-Hockey-Shorts18" component={MensHockeyshorts18}/>
                    <Route exact path="/Mens-Hockey-Shorts19" component={MensHockeyshorts19}/>
                    <Route exact path="/Mens-Hockey-Shorts20" component={MensHockeyshorts20}/>
                    <Route exact path="/Mens-Hockey-Shorts21" component={MensHockeyshorts21}/>
                    <Route exact path="/Mens-Hockey-Shorts22" component={MensHockeyshorts22}/>
                    <Route exact path="/Mens-Hockey-Shorts23" component={MensHockeyshorts23}/>
                    <Route exact path="/Mens-Hockey-Shorts24" component={MensHockeyshorts24}/>


                    {/* Ladies Hockey Skorts */}
                    <Route exact path="/Ladies-Hockey-Skorts1" component={LadiesHockeyskorts1}/>
                    <Route exact path="/Ladies-Hockey-Skorts2" component={LadiesHockeyskorts2}/>
                    <Route exact path="/Ladies-Hockey-Skorts3" component={LadiesHockeyskorts3}/>
                    <Route exact path="/Ladies-Hockey-Skorts4" component={LadiesHockeyskorts4}/>
                    <Route exact path="/Ladies-Hockey-Skorts5" component={LadiesHockeyskorts5}/>
                    <Route exact path="/Ladies-Hockey-Skorts6" component={LadiesHockeyskorts6}/>
                    <Route exact path="/Ladies-Hockey-Skorts7" component={LadiesHockeyskorts7}/>
                    <Route exact path="/Ladies-Hockey-Skorts8" component={LadiesHockeyskorts8}/>
                    <Route exact path="/Ladies-Hockey-Skorts9" component={LadiesHockeyskorts9}/>
                    <Route exact path="/Ladies-Hockey-Skorts10" component={LadiesHockeyskorts10}/>


                    {/* Ladies Vests */}
                    <Route exact path="/Ladies-Vest1" component={Ladiesvest1}/>
                    <Route exact path="/Ladies-Vest2" component={Ladiesvest2}/>
                    <Route exact path="/Ladies-Vest3" component={Ladiesvest3}/>
                    <Route exact path="/Ladies-Vest4" component={Ladiesvest4}/>
                    <Route exact path="/Ladies-Vest5" component={Ladiesvest5}/>
                    <Route exact path="/Ladies-Vest6" component={Ladiesvest6}/>
                    <Route exact path="/Ladies-Vest7" component={Ladiesvest7}/>
                    <Route exact path="/Ladies-Vest8" component={Ladiesvest8}/>
                    <Route exact path="/Ladies-Vest9" component={Ladiesvest9}/>
                    <Route exact path="/Ladies-Vest10" component={Ladiesvest10}/>


                    {/* Gk Smock */}
                    <Route exact path="/Gk-Smock1" component={Gksmock1}/>
                    <Route exact path="/Gk-Smock2" component={Gksmock2}/>
                    <Route exact path="/Gk-Smock3" component={Gksmock3}/>
                    <Route exact path="/Gk-Smock4" component={Gksmock4}/>
                    <Route exact path="/Gk-Smock5" component={Gksmock5}/>
                    <Route exact path="/Gk-Smock6" component={Gksmock6}/>
                    <Route exact path="/Gk-Smock7" component={Gksmock7}/>
                    <Route exact path="/Gk-Smock8" component={Gksmock8}/>
                    <Route exact path="/Gk-Smock9" component={Gksmock9}/>
                    <Route exact path="/Gk-Smock10" component={Gksmock10}/>
                    <Route exact path="/Gk-Smock11" component={Gksmock11}/>

                    {/* Basketball Vests*/}
                    <Route exact path="/Basketball-Vest1" component={Basketballvest1}/>
                    <Route exact path="/Basketball-Vest2" component={Basketballvest2}/>
                    <Route exact path="/Basketball-Vest3" component={Basketballvest3}/>
                    <Route exact path="/Basketball-Vest4" component={Basketballvest4}/>
                    <Route exact path="/Basketball-Vest5" component={Basketballvest5}/>
                    <Route exact path="/Basketball-Vest6" component={Basketballvest6}/>
                    <Route exact path="/Basketball-Vest7" component={Basketballvest7}/>
                    <Route exact path="/Basketball-Vest8" component={Basketballvest8}/>
                    <Route exact path="/Basketball-Vest9" component={Basketballvest9}/>
                    <Route exact path="/Basketball-Vest10" component={Basketballvest10}/>

                    {/* Basketball Shorts */}
                    <Route exact path="/Basketball-Shorts1" component={Basketballshorts1}/>
                    <Route exact path="/Basketball-Shorts2" component={Basketballshorts2}/>
                    <Route exact path="/Basketball-Shorts3" component={Basketballshorts3}/>
                    <Route exact path="/Basketball-Shorts4" component={Basketballshorts4}/>
                    <Route exact path="/Basketball-Shorts5" component={Basketballshorts5}/>
                    <Route exact path="/Basketball-Shorts6" component={Basketballshorts6}/>
                    <Route exact path="/Basketball-Shorts7" component={Basketballshorts7}/>
                    <Route exact path="/Basketball-Shorts8" component={Basketballshorts8}/>
                    <Route exact path="/Basketball-Shorts9" component={Basketballshorts9}/>
                    <Route exact path="/Basketball-Shorts10" component={Basketballshorts10}/>


                    {/* Achery Shirts*/}
                    <Route exact path="/Achery-Shirts" component={Acheryshirts}/>

                    <Route exact path="/Achery-Shirt1" component={Acheryshirt1}/>
                    <Route exact path="/Achery-Shirt2" component={Acheryshirt2}/>
                    <Route exact path="/Achery-Shirt3" component={Acheryshirt3}/>
                    <Route exact path="/Achery-Shirt4" component={Acheryshirt4}/>
                    <Route exact path="/Achery-Shirt5" component={Acheryshirt5}/>
                    <Route exact path="/Achery-Shirt6" component={Acheryshirt6}/>
                    <Route exact path="/Achery-Shirt7" component={Acheryshirt7}/>
                    <Route exact path="/Achery-Shirt8" component={Acheryshirt8}/>
                    <Route exact path="/Achery-Shirt9" component={Acheryshirt9}/>
                    <Route exact path="/Achery-Shirt10" component={Acheryshirt10}/>
                    <Route exact path="/Achery-Shirt11" component={Acheryshirt11}/>
                    <Route exact path="/Achery-Shirt12" component={Acheryshirt12}/>
                    <Route exact path="/Achery-Shirt13" component={Acheryshirt13}/>
                    <Route exact path="/Achery-Shirt14" component={Acheryshirt14}/>
                    <Route exact path="/Achery-Shirt15" component={Acheryshirt15}/>


                    {/* Bowling Shirts*/}
                    <Route exact path="/Bowling-Shirts" component={Bowlingshirts}/>

                    <Route exact path="/Bowling-Shirt1" component={Bowlingshirt1}/>
                    <Route exact path="/Bowling-Shirt2" component={Bowlingshirt2}/>
                    <Route exact path="/Bowling-Shirt3" component={Bowlingshirt3}/>
                    <Route exact path="/Bowling-Shirt4" component={Bowlingshirt4}/>
                    <Route exact path="/Bowling-Shirt5" component={Bowlingshirt5}/>
                    <Route exact path="/Bowling-Shirt6" component={Bowlingshirt6}/>
                    <Route exact path="/Bowling-Shirt7" component={Bowlingshirt7}/>
                    <Route exact path="/Bowling-Shirt8" component={Bowlingshirt8}/>
                    <Route exact path="/Bowling-Shirt9" component={Bowlingshirt9}/>
                    <Route exact path="/Bowling-Shirt10" component={Bowlingshirt10}/>
                    <Route exact path="/Bowling-Shirt11" component={Bowlingshirt11}/>
                    <Route exact path="/Bowling-Shirt12" component={Bowlingshirt12}/>
                    <Route exact path="/Bowling-Shirt13" component={Bowlingshirt13}/>
                    <Route exact path="/Bowling-Shirt14" component={Bowlingshirt14}/>
                    <Route exact path="/Bowling-Shirt15" component={Bowlingshirt15}/>


                    {/* Netball Shirts*/}
                    <Route exact path="/Netball-blouse1" component={Netballblouse1}/>
                    <Route exact path="/Netball-blouse2" component={Netballblouse2}/>
                    <Route exact path="/Netball-blouse3" component={Netballblouse3}/>
                    <Route exact path="/Netball-blouse4" component={Netballblouse4}/>
                    <Route exact path="/Netball-blouse5" component={Netballblouse5}/>
                    <Route exact path="/Netball-blouse6" component={Netballblouse6}/>
                    <Route exact path="/Netball-blouse7" component={Netballblouse7}/>
                    <Route exact path="/Netball-blouse8" component={Netballblouse8}/>
                    <Route exact path="/Netball-blouse9" component={Netballblouse9}/>
                    <Route exact path="/Netball-blouse10" component={Netballblouse10}/>
                    <Route exact path="/Netball-blouse11" component={Netballblouse11}/>
                    <Route exact path="/Netball-blouse12" component={Netballblouse12}/>
                    <Route exact path="/Netball-blouse13" component={Netballblouse13}/>
                    <Route exact path="/Netball-blouse14" component={Netballblouse14}/>
                    <Route exact path="/Netball-blouse15" component={Netballblouse15}/>
                    <Route exact path="/Netball-blouse16" component={Netballblouse16}/>


                    {/* Gillet Shirts */}
                    <Route exact path="/Gillet1" component={Gilletshirt1}/>
                    <Route exact path="/Gillet2" component={Gilletshirt2}/>
                    <Route exact path="/Gillet3" component={Gilletshirt3}/>
                    <Route exact path="/Gillet4" component={Gilletshirt4}/>
                    <Route exact path="/Gillet5" component={Gilletshirt5}/>

                    {/* Fleece Jacket */}
                    <Route exact path="/Fleece-Jacket1" component={Fleecejacket1}/>
                    <Route exact path="/Fleece-Jacket2" component={Fleecejacket2}/>
                    <Route exact path="/Fleece-Jacket3" component={Fleecejacket3}/>
                    <Route exact path="/Fleece-Jacket4" component={Fleecejacket4}/>
                    <Route exact path="/Fleece-Jacket5" component={Fleecejacket5}/>
                    <Route exact path="/Fleece-Jacket6" component={Fleecejacket6}/>
                    <Route exact path="/Fleece-Jacket7" component={Fleecejacket7}/>
                    <Route exact path="/Fleece-Jacket8" component={Fleecejacket8}/>
                    <Route exact path="/Fleece-Jacket9" component={Fleecejacket9}/>
                    <Route exact path="/Fleece-Jacket10" component={Fleecejacket10}/>


                    {/* Polo Tshirts */}
                    <Route exact path="/Polo-Shirt1" component={Polotshirt1}/>
                    <Route exact path="/Polo-Shirt2" component={Polotshirt2}/>
                    <Route exact path="/Polo-Shirt3" component={Polotshirt3}/>
                    <Route exact path="/Polo-Shirt4" component={Polotshirt4}/>
                    <Route exact path="/Polo-Shirt5" component={Polotshirt5}/>
                    <Route exact path="/Polo-Shirt6" component={Polotshirt6}/>
                    <Route exact path="/Polo-Shirt7" component={Polotshirt7}/>
                    <Route exact path="/Polo-Shirtt8" component={Polotshirt8}/>
                    <Route exact path="/Polo-Shirt9" component={Polotshirt9}/>
                    <Route exact path="/Polo-Shirt10" component={Polotshirt10}/>
                    <Route exact path="/Polo-Shirt11" component={Polotshirt11}/>
                    <Route exact path="/Polo-Shirt12" component={Polotshirt12}/>
                    <Route exact path="/Polo-Shirt13" component={Polotshirt13}/>
                    <Route exact path="/Polo-Shirt14" component={Polotshirt14}/>
                    <Route exact path="/Polo-Shirt15" component={Polotshirt15}/>
                    <Route exact path="/Polo-Shirt16" component={Polotshirt16}/>
                    <Route exact path="/Polo-Shirt17" component={Polotshirt17}/>
                    <Route exact path="/Polo-Shirt18" component={Polotshirt18}/>
                    <Route exact path="/Polo-Shirt19" component={Polotshirt19}/>


                    {/* Manager Jackets */}
                    <Route exact path="/Manager-Jacket1" component={Managerjacket1}/>
                    <Route exact path="/Manager-Jacket2" component={Managerjacket2}/>
                    <Route exact path="/Manager-Jacket3" component={Managerjacket3}/>
                    <Route exact path="/Manager-Jacket4" component={Managerjacket4}/>
                    <Route exact path="/Manager-Jacket5" component={Managerjacket5}/>
                    <Route exact path="/Manager-Jacket6" component={Managerjacket6}/>
                    <Route exact path="/Manager-Jacket7" component={Managerjacket7}/>
                    <Route exact path="/Manager-Jacket8" component={Managerjacket8}/>
                    <Route exact path="/Manager-Jacket9" component={Managerjacket9}/>
                    <Route exact path="/Manager-Jacket10" component={Managerjacket10}/>


                    {/* Stadium Jackets */}
                    <Route exact path="/Stadium-Jacket1" component={Stadiumjacket1}/>
                    <Route exact path="/Stadium-Jacket2" component={Stadiumjacket2}/>
                    <Route exact path="/Stadium-Jacket3" component={Stadiumjacket3}/>
                    <Route exact path="/Stadium-Jacket4" component={Stadiumjacket4}/>
                    <Route exact path="/Stadium-Jacket5" component={Stadiumjacket5}/>
                    

                    {/* Netball Skorts */}
                    <Route exact path="/Netball-Skort1" component={Netballskort1}/>
                    <Route exact path="/Netball-Skort2" component={Netballskort2}/>
                    <Route exact path="/Netball-Skort3" component={Netballskort3}/>
                    <Route exact path="/Netball-Skort4" component={Netballskort4}/>
                    <Route exact path="/Netball-Skort5" component={Netballskort5}/>
                    <Route exact path="/Netball-Skort6" component={Netballskort6}/>
                    <Route exact path="/Netball-Skort7" component={Netballskort7}/>
                    <Route exact path="/Netball-Skort8" component={Netballskort8}/>
                    <Route exact path="/Netball-Skort9" component={Netballskort9}/>
                    <Route exact path="/Netball-Skort10" component={Netballskort10}/>


                    {/* Netball Shirts */}
                    <Route exact path="/Netball-Top1" component={Netballtshirt1}/>
                    <Route exact path="/Netball-Top2" component={Netballtshirt2}/>
                    <Route exact path="/Netball-Top3" component={Netballtshirt3}/>
                    <Route exact path="/Netball-Top4" component={Netballtshirt4}/>
                    <Route exact path="/Netball-Top5" component={Netballtshirt5}/>
                    <Route exact path="/Netball-Top6" component={Netballtshirt6}/>
                    <Route exact path="/Netball-Top7" component={Netballtshirt7}/>
                    <Route exact path="/Netball-Top8" component={Netballtshirt8}/>
                    <Route exact path="/Netball-Top9" component={Netballtshirt9}/>
                    <Route exact path="/Netball-Top10" component={Netballtshirt10}/>
                    <Route exact path="/Netball-Top11" component={Netballtshirt11}/>
                    <Route exact path="/Netball-Top12" component={Netballtshirt12}/>
                    <Route exact path="/Netball-Top13" component={Netballtshirt13}/>
                    <Route exact path="/Netball-Top14" component={Netballtshirt14}/>
                    <Route exact path="/Netball-Top15" component={Netballtshirt15}/>


                    {/* Cricket Tshirts */}
                    <Route exact path="/Cricket-Shirt1" component={Crickettshirt1}/>
                    <Route exact path="/Cricket-Shirt2" component={Crickettshirt2}/>
                    <Route exact path="/Cricket-Shirt3" component={Crickettshirt3}/>
                    <Route exact path="/Cricket-Shirt4" component={Crickettshirt4}/>
                    <Route exact path="/Cricket-Shirt5" component={Crickettshirt5}/>
                    <Route exact path="/Cricket-Shirt6" component={Crickettshirt6}/>
                    <Route exact path="/Cricket-Shirt7" component={Crickettshirt7}/>
                    <Route exact path="/Cricket-Shirt8" component={Crickettshirt8}/>
                    <Route exact path="/Cricket-Shirt9" component={Crickettshirt9}/>
                    <Route exact path="/Cricket-Shirt10" component={Crickettshirt10}/>
                    <Route exact path="/Cricket-Shirt11" component={Crickettshirt11}/>

                    {/* Cricket Pants */}
                    <Route exact path="/Cricket-Pant1" component={Cricketpant1}/>
                    <Route exact path="/Cricket-Pant2" component={Cricketpant2}/>
                    <Route exact path="/Cricket-Pant3" component={Cricketpant3}/>
                    <Route exact path="/Cricket-Pant4" component={Cricketpant4}/>
                    <Route exact path="/Cricket-Pant5" component={Cricketpant5}/>

                    {/* Soccer Shorts */}
                    <Route exact path="/Soccer-Shorts1" component={Soccershorts1}/>
                    <Route exact path="/Soccer-Shorts2" component={Soccershorts2}/>
                    <Route exact path="/Soccer-Shorts3" component={Soccershorts3}/>
                    <Route exact path="/Soccer-Shorts4" component={Soccershorts4}/>
                    <Route exact path="/Soccer-Shorts5" component={Soccershorts5}/>
                    <Route exact path="/Soccer-Shorts6" component={Soccershorts6}/>
                    <Route exact path="/Soccer-Shorts7" component={Soccershorts7}/>
                    <Route exact path="/Soccer-Shorts8" component={Soccershorts8}/>
                    <Route exact path="/Soccer-Shorts9" component={Soccershorts9}/>
                    <Route exact path="/Soccer-Shorts10" component={Soccershorts10}/>


                    {/* Soccer Tshirts */}
                    <Route exact path="/Soccer-Shirt1" component={Soccertshirt1}/>
                    <Route exact path="/Soccer-Shirt2" component={Soccertshirt2}/>
                    <Route exact path="/Soccer-Shirt3" component={Soccertshirt3}/>
                    <Route exact path="/Soccer-Shirt4" component={Soccertshirt4}/>
                    <Route exact path="/Soccer-Shirt5" component={Soccertshirt5}/>
                    <Route exact path="/Soccer-Shirt6" component={Soccertshirt6}/>
                    <Route exact path="/Soccer-Shirt7" component={Soccertshirt7}/>
                    <Route exact path="/Soccer-Shirt8" component={Soccertshirt8}/>
                    <Route exact path="/Soccer-Shirt9" component={Soccertshirt9}/>
                    <Route exact path="/Soccer-Shirt10" component={Soccertshirt10}/>


                    {/* Hooded Sweatshirts */}
                    <Route exact path="/Hooded-Sweatshirt1" component={Hoodedsweathoodie1}/>
                    <Route exact path="/Hooded-Sweatshirt2" component={Hoodedsweathoddie2}/>
                    <Route exact path="/Hooded-Sweatshirt3" component={Hoodedsweathoddie3}/>
                    <Route exact path="/Hooded-Sweatshirt4" component={Hoodedsweathoddie4}/>
                    <Route exact path="/Hooded-Sweatshirt5" component={Hoodedsweathoddie5}/>
                    <Route exact path="/Hooded-Sweatshirt6" component={Hoodedsweathoddie6}/>
                    <Route exact path="/Hooded-Sweatshirt7" component={Hoodedsweathoddie7}/>
                    <Route exact path="/Hooded-Sweatshirt8" component={Hoodedsweathoddie8}/>
                    <Route exact path="/Hooded-Sweatshirt9" component={Hoodedsweathoddie9}/>
                    <Route exact path="/Hooded-Sweatshirt10" component={Hoodedsweathoddie10}/>
                    <Route exact path="/Hooded-Sweatshirt11" component={Hoodedsweathoddie11}/>
                    <Route exact path="/Hooded-Sweatshirt12" component={Hoodedsweathoddie12}/>


                    {/* Quarter Zip Sweatshirts */}
                    <Route exact path="/Quarter-Zip-Sweatshirt1" component={Quarterziphoodie1}/>
                    <Route exact path="/Quarter-Zip-Sweatshirt2" component={Quarterziphoodie2}/>
                    <Route exact path="/Quarter-Zip-Sweatshirt3" component={Quarterziphoodie3}/>
                    <Route exact path="/Quarter-Zip-Sweatshirt4" component={Quarterziphoodie4}/>
                    <Route exact path="/Quarter-Zip-Sweatshirt5" component={Quarterziphoodie5}/>
                    <Route exact path="/Quarter-Zip-Sweatshirt6" component={Quarterziphoodie6}/>
                    <Route exact path="/Quarter-Zip-Sweatshirt7" component={Quarterziphoodie7}/>
                    <Route exact path="/Quarter-Zip-Sweatshirt8" component={Quarterziphoodie8}/>
                    <Route exact path="/Quarter-Zip-Sweatshirt9" component={Quarterziphoodie9}/>
                    <Route exact path="/Quarter-Zip-Sweatshirt10" component={Quarterziphoodie10}/>
                    <Route exact path="/Quarter-Zip-Sweatshirt11" component={Quarterziphoodie11}/>


                    {/* Round Neck Sweatshirts */}
                    <Route exact path="/Round-Neck-Sweatshirt1" component={Roundnecksweatshirt1}/>
                    <Route exact path="/Round-Neck-Sweatshirt2" component={Roundnecksweatshirt2}/>
                    <Route exact path="/Round-Neck-Sweatshirt3" component={Roundnecksweatshirt3}/>
                    <Route exact path="/Round-Neck-Sweatshirt4" component={Roundnecksweatshirt4}/>
                    <Route exact path="/Round-Neck-Sweatshirt5" component={Roundnecksweatshirt5}/>
                    <Route exact path="/Round-Neck-Sweatshirt6" component={Roundnecksweatshirt6}/>
                    <Route exact path="/Round-Neck-Sweatshirt7" component={Roundnecksweatshirt7}/>
                    <Route exact path="/Round-Neck-Sweatshirt8" component={Roundnecksweatshirt8}/>
                    <Route exact path="/Round-Neck-Sweatshirt9" component={Roundnecksweatshirt9}/>
                    <Route exact path="/Round-Neck-Sweatshirt10" component={Roundnecksweatshirt10}/>


                    {/* Track Top */}
                    <Route exact path="/Track-Top1" component={Tracktop1}/>
                    <Route exact path="/Track-Top2" component={Tracktop2}/>
                    <Route exact path="/Track-Top3" component={Tracktop3}/>
                    <Route exact path="/Track-Top4" component={Tracktop4}/>
                    <Route exact path="/Track-Top5" component={Tracktop5}/>
                    <Route exact path="/Track-Top6" component={Tracktop6}/>
                    <Route exact path="/Track-Top7" component={Tracktop7}/>
                    <Route exact path="/Track-Top8" component={Tracktop8}/>
                    <Route exact path="/Track-Top9" component={Tracktop9}/>
                    <Route exact path="/Track-Top10" component={Tracktop10}/>


                    {/* Training Top */}
                    <Route exact path="/Training-Top1" component={Trainingtop1}/>
                    <Route exact path="/Training-Top2" component={Trainingtop2}/>
                    <Route exact path="/Training-Top3" component={Trainingtop3}/>
                    <Route exact path="/Training-Top4" component={Trainingtop4}/>
                    <Route exact path="/Training-Top5" component={Trainingtop5}/>
                    <Route exact path="/Training-Top6" component={Trainingtop6}/>
                    <Route exact path="/Training-Top7" component={Trainingtop7}/>
                    <Route exact path="/Training-Top8" component={Trainingtop8}/>
                    <Route exact path="/Training-Top9" component={Trainingtop9}/>
                    <Route exact path="/Training-Top10" component={Trainingtop10}/>
                    <Route exact path="/Training-Top11" component={Trainingtop11}/>
                    <Route exact path="/Training-Top12" component={Trainingtop12}/>
                    <Route exact path="/Training-Top13" component={Trainingtop13}/>
                    <Route exact path="/Training-Top14" component={Trainingtop14}/>
                    <Route exact path="/Training-Top15" component={Trainingtop15}/>


                    {/* Tshirts */}
                    <Route exact path="/Tshirt1" component={Tshirt1}/>
                    <Route exact path="/Tshirt2" component={Tshirt2}/>
                    <Route exact path="/Tshirt3" component={Tshirt3}/>
                    <Route exact path="/Tshirt4" component={Tshirt4}/>
                    <Route exact path="/Tshirt5" component={Tshirt5}/>
                    <Route exact path="/Tshirt6" component={Tshirt6}/>
                    <Route exact path="/Tshirt7" component={Tshirt7}/>
                    <Route exact path="/Tshirt8" component={Tshirt8}/>
                    <Route exact path="/Tshirt9" component={Tshirt9}/>
                    <Route exact path="/Tshirt10" component={Tshirt10}/>

                    {/* Vest */}
                    <Route exact path="/Vest1" component={Vest1}/>
                    <Route exact path="/Vest2" component={Vest2}/>
                    <Route exact path="/Vest3" component={Vest3}/>
                    <Route exact path="/Vest4" component={Vest4}/>
                    <Route exact path="/Vest5" component={Vest5}/>
                    <Route exact path="/Vest6" component={Vest6}/>
                    <Route exact path="/Vest7" component={Vest7}/>
                    <Route exact path="/Vest8" component={Vest8}/>
                    <Route exact path="/Vest9" component={Vest9}/>
                    <Route exact path="/Vest10" component={Vest10}/>
                    <Route exact path="/Vest11" component={Vest11}/>
                    <Route exact path="/Vest12" component={Vest12}/>
                    <Route exact path="/Vest13" component={Vest13}/>


                    {/* Corelayer Fullsleeves */}
                    <Route exact path="/Corelayer-Fullsleeves1" component={CorelayerFullsleeves1}/>
                    <Route exact path="/Corelayer-Fullsleeves2" component={CorelayerFullsleeves2}/>
                    <Route exact path="/Corelayer-Fullsleeves3" component={CorelayerFullsleeves3}/>
                    <Route exact path="/Corelayer-Fullsleeves4" component={CorelayerFullsleeves4}/>
                    <Route exact path="/Corelayer-Fullsleeves5" component={CorelayerFullsleeves5}/>

                    {/* Corelayer Halfsleeves */}
                    <Route exact path="/Corelayer-Halfsleeves1" component={CorelayerHalfsleeves1}/>
                    <Route exact path="/Corelayer-Halfsleeves2" component={CorelayerHalfsleeves2}/>
                    <Route exact path="/Corelayer-Halfsleeves3" component={CorelayerHalfsleeves3}/>
                    <Route exact path="/Corelayer-Halfsleeves4" component={CorelayerHalfsleeves4}/>
                    <Route exact path="/Corelayer-Halfsleeves5" component={CorelayerHalfsleeves5}/>


                    {/* Corelayer Halfsleeves */}
                    <Route exact path="/Corelayer-Sleeveless1" component={CorelayerSleeveless1}/>
                    <Route exact path="/Corelayer-Sleeveless2" component={CorelayerSleeveless2}/>
                    <Route exact path="/Corelayer-Sleeveless3" component={CorelayerSleeveless3}/>
                    <Route exact path="/Corelayer-Sleeveless4" component={CorelayerSleeveless4}/>
                    <Route exact path="/Corelayer-Sleeveless5" component={CorelayerSleeveless5}/>


                    {/* Track Pants */}
                    <Route exact path="/Track-Pant1" component={Trackpant1}/>
                    <Route exact path="/Track-Pant2" component={Trackpant2}/>
                    <Route exact path="/Track-Pant3" component={Trackpant3}/>
                    <Route exact path="/Track-Pant4" component={Trackpant4}/>
                    <Route exact path="/Track-Pant5" component={Trackpant5}/>
                    <Route exact path="/Track-Pant6" component={Trackpant6}/>
                    <Route exact path="/Track-Pant7" component={Trackpant7}/>



              	    </Switch>
	                </div>
                </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('quarter'));
