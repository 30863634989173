import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../../global-components/navbar-v4';
import Footer_v1 from '../../global-components/footer';
import Formmodal from '../../section-components/formmodal';
import jersey14front from '../../../images/sports/products/rugby-jersey/14/jersey14front.png';
import jersey14back from '../../../images/sports/products/rugby-jersey/14/jersey14back.png';
import Rugbyjerseyform from '../../section-components/Forms/rugbyjerseyform';



const RugbyJersy14= () => {
  const smallImages = [jersey14front, jersey14back, jersey14front, jersey14back];

  const [productImage, setProductImage] = useState(smallImages[0]);

  const handleSmallImageClick = (src) => {
    setProductImage(src);
  };

  return (
    <>
      <NavbarV4 />

      <Helmet>
        <title>Rugby Jersey</title>
        <meta name="description" content="" />
      </Helmet>

      <section className='bnnr-product-pg'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-14 col-xl-14 col-md-14 col-14'>
              <h2>Rugby Jersey</h2>
            </div>
          </div>
        </div>
      </section>

      <section className='rugby-first-p1'>
        <div className='small-container single-product'>
          <div className='row'>
            <div className='col-2 text-center'>
              <img src={productImage} alt='' width='100%' id='ProductImg' />

              <div className='small-img-row'>
                {smallImages.map((image, index) => (
                  <div key={index} className='small-img-col'>
                    <img
                      src={image}
                      alt=''
                      width='100%'
                      className='small-img'
                      onClick={() => handleSmallImageClick(image)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='col-2'>
            <h5>Bcurri Apparel</h5>
            <h2>Rugby Jersey 14</h2>
              {/* <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias,
                accusamus soluta quasi illum.
              </p> */}

<Rugbyjerseyform />

            </div>
          </div>
        </div>
      </section>

      <Footer_v1 />
    </>
  );
};

export default RugbyJersy14;
