import React from 'react';
import { Helmet } from 'react-helmet';

import upper1front from "../../../images/sports/products/cricket-upper/1/upper1front.png";
import upper1back from "../../../images/sports/products/cricket-upper/1/upper1back.png";
import upper2front from "../../../images/sports/products/cricket-upper/2/upper2front.png";
import upper2back from "../../../images/sports/products/cricket-upper/2/upper2back.png";
import upper3front from "../../../images/sports/products/cricket-upper/3/upper3front.png";
import upper3back from "../../../images/sports/products/cricket-upper/3/upper3back.png";
import upper4front from "../../../images/sports/products/cricket-upper/4/upper4front.png";
import upper4back from "../../../images/sports/products/cricket-upper/4/upper4back.png";
import upper5front from "../../../images/sports/products/cricket-upper/5/upper5front.png";
import upper5back from "../../../images/sports/products/cricket-upper/5/upper5back.png";
import upper6front from "../../../images/sports/products/cricket-upper/6/upper6front.png";
import upper6back from "../../../images/sports/products/cricket-upper/6/upper6back.png";
import upper7front from "../../../images/sports/products/cricket-upper/7/upper7front.png";
import upper7back from "../../../images/sports/products/cricket-upper/7/upper7back.png";
import upper8front from "../../../images/sports/products/cricket-upper/8/upper8front.png";
import upper8back from "../../../images/sports/products/cricket-upper/8/upper8back.png";
import upper9front from "../../../images/sports/products/cricket-upper/9/upper9front.png";
import upper9back from "../../../images/sports/products/cricket-upper/9/upper9back.png";
import upper10front from "../../../images/sports/products/cricket-upper/10/upper10front.png";
import upper10back from "../../../images/sports/products/cricket-upper/10/upper10back.png";
import upper11front from "../../../images/sports/products/cricket-upper/11/upper11front.png";
import upper11back from "../../../images/sports/products/cricket-upper/11/upper11back.png";


const Cricketshirtsport = () => {
    return (
<>

<Helmet>
<title>Cricket Shirts - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>


{/* Rugby upper */}

<section className='cloth-rugby4' id='Cricket-Shirt'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Cricket Shirts</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-Shirt1" className="image">
        <img src={upper1front} className="pic-1" alt='' />
        <img src={upper1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-Shirt1">Cricket Shirt 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-Shirt2" className="image">
        <img src={upper2front} className="pic-1" alt='' />
        <img src={upper2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-Shirt2">Cricket Shirt 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-Shirt3" className="image">
        <img src={upper3front} className="pic-1" alt='' />
        <img src={upper3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-Shirt3">Cricket Shirt 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-Shirt4" className="image">
        <img src={upper4front} className="pic-1" alt='' />
        <img src={upper4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-Shirt4">Cricket Shirt 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-Shirt5" className="image">
        <img src={upper5front} className="pic-1" alt='' />
        <img src={upper5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-Shirt5">Cricket Shirt 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-Shirt6" className="image">
        <img src={upper6front} className="pic-1" alt='' />
        <img src={upper6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-Shirt6">Cricket Shirt 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-Shirt7" className="image">
        <img src={upper7front} className="pic-1" alt='' />
        <img src={upper7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-Shirt7">Cricket Shirt 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-Shirt8" className="image">
        <img src={upper8front} className="pic-1" alt='' />
        <img src={upper8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-Shirt8">Cricket Shirt 8</a>
        </h3>
  </div>
 
   </div>
   <br/>

       {/* Row 3 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-Shirt9" className="image">
        <img src={upper9front} className="pic-1" alt='' />
        <img src={upper9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-Shirt9">Cricket Shirt 9</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-Shirt10" className="image">
        <img src={upper10front} className="pic-1" alt='' />
        <img src={upper10back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-Shirt10">Cricket Shirt 10</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Cricket-Shirt11" className="image">
        <img src={upper11front} className="pic-1" alt='' />
        <img src={upper11back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Cricket-Shirt11">Cricket Shirt 11</a>
        </h3>
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  </div>


   </div>
   <br/>

   
    

    </div>
</section>



</>
)
}

export default Cricketshirtsport

