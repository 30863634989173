import { useEffect } from "react";

const Language = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        // pageLanguage: "en",
        includedLanguages: "en,es,zh-CN,zh-TW",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    // Function to hide Google Translate elements
    const hideGoogleTranslate = () => {
      const googleTranslateElements = document.querySelectorAll(".VIpgJd-ZVi9od-ORHb");
      googleTranslateElements.forEach(element => {
        element.style.display = "none";
      });
    };

    // Add event listener to detect language change
    const observer = new MutationObserver(() => {
      hideGoogleTranslate();
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => {
      observer.disconnect(); // Clean up the observer when component unmounts
    };
  }, []);

  return (
    <>
      <div id="google_translate_element"></div>
    </>
  );
};

export default Language;
