import React from 'react';
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../global-components/navbar-v4';
import womenteam from "../../images/sports/womenteam.webp";
import Corpclient from '../section-components/clients/Corpclient';
import collabbanner from "../../images/banners/collabbanner.webp"
import collabmobile from "../../images/banners/collabmobile.webp"



const Collaborations = () => {
    return (
<>

<NavbarV4 CustomClass="ltn__header-transparent gradient-color-2"  />
<Helmet>
<title>Collaborations - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

 {/* Start Slider area  */}
{/* <section className='collabbnr'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='Sports-bcurri'>
                <h1>Collaborations </h1>
                <h2 className='Specialist'>Apparel Limited</h2>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={about1} className='abhtbnrpick' alt='' />
            </div>
        </div>
    </div>
</section> */}


<picture>
  <source srcset={collabmobile} media="(max-width: 660px)" />
  <source srcset={collabbanner} />
  <img src={collabbanner} alt="" className='ban' />
</picture>


<section className='collab'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-12 text-center'>
            <h2 className=''>Collaborating To Deliver Excellence </h2>
            <div className='first-p'>
            <p> We believe in nurturing strong relationships with sports clubs and reliable suppliers who share our passion for sportswear excellence. Therefore, we are always open to joining hands with partner clubs and suppliers to deliver top-notch sports design solutions. </p>     
             </div>
            </div>  
        </div>

  <Corpclient />

    </div>
</section>




{/* Why collaborate section */}
{/* <section className='choose'> 
        <div className='container'>
        <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center'>

        <div class="dd_heading">
  <h2 class="text-center">Why collaborate with us?</h2>
<p class="text-center">Here’s how partnering with us can provide you with numerous advantages. </p>
</div>
<main class="page-content">
  <div class="d_card">
    <div class="content2">
      <h2 class="heading2">Growth opportunities </h2>
      <p class="data-content2">Elevate your team’s style or enjoy increased business opportunities by tapping into our extensive network. </p>
    </div>
  </div>
  <div class="d_card">
    <div class="content2">
      <h2 class="heading2">Cost savings</h2>
      <p class="data-content2">Save on your expenses by taking advantage of competitive pricing and exclusive discounts. </p>
    </div>
  </div>
  <div class="d_card">
    <div class="content2">
      <h2 class="heading2">Increased prestige </h2>
      <p class="data-content2">Enjoy co-branding opportunities that will increase your reputation as well as keep you at the forefront of the industry. </p>
    </div>
  </div>
</main>
        </div>
        </div>

        </div>
</section> */}


<section className='partnerclub'>
    <div className='container'>
       <div className='row'>
            <div className='col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-12 text-center mx-auto'>
                <h2 className='partner'>Become Our Partner Club </h2>
                <div className='first-p1'>
                <p> At Bcurri Apparel, we are committed to helping partner clubs make a unique identity with our custom sportswear. By joining us, you’ll enter into a collaboration that provides a myriad of benefits tailored just for you. </p>               
                </div>
            </div>

          <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-12'>
              <h3 className='partner'>Our Benefits For Partner Clubs</h3>
                  <h4 className='compe'> 1. Exclusive Discounts </h4>
                  <p>We offer exclusive discounts on jersey orders to our partner clubs, helping them enjoy significant savings. </p>
                  <h4 className='compe'> 2. Sponsorship Opportunities </h4>
                  <p> With our customized jerseys, you’re sure to unlock sponsorship opportunities for your club. Be it financial support or collaborative events, we help you thrive. </p>
                  <h4 className='compe'> 3. Highest-quality Fabric </h4>
                  <p>We use the finest quality material that ensures maximum comfort during the play. </p>

            </div>

            <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-12'>
                  <h4 className='compe'> 4. Promotional Support </h4>
                  <p>By collaborating with us, you can increase your club’s visibility by gaining exposure through our marketing channels. </p>
                  <h4 className='compe'> 5. Extensive Customization Options </h4>
                  <p>From your club’s logos to team names and player numbers, we offer an extensive range of customization options for our partner clubs. </p>
                  <h5><a className="collabmail" href="mailto:team@bcurriapparel.com">Reach out to us</a> to share your club’s aspirations and needs so that we can tailor our benefits according to your unique requirements. </h5> 
            </div>
          </div>

        </div>   
    </div>
</section>


{/* 
<section className='partnerclub'>
    <div className='container'>
       <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center mx-auto'>
                <h5> We look forward to collaborating with you. Email us at <a className="collabmail" href="mailto:team@bcurriapparel.com">team@bcurriapparel.com</a> to connect with us and explore the possibilities of working together.</h5> 

                <div className="btn-cont-center">
                     <a className="btn" href="/about"> Get more information <span className="line-1" /><span className="line-2" /><span className="line-3" /><span className="line-4" /></a>
                </div>  
            </div>

        </div>   
    </div>
</section> */}


<section className='dis'>
        <div className='container'>
            <div className='row'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-12 collabcta'>
                <div className='lastbox'>
                    <h2>Let’s Embark on a <br />Collaborative Journey! </h2>
                    {/* <p className='avoid'>Get in touch with us to discover the possibilities of our partnership. </p> */}
                    <p className='avoid'> We look forward to collaborating with you. Email us at <a className="collabmail emaillink" href="mailto:team@bcurriapparel.com">team@bcurriapparel.com</a> to connect with us and explore the possibilities of working together.</p> 
                    <div className="btn-cont">
                    <a className="btn" href="/contact-us">
                    Collaborate with us 
                 <span className="line-1" />
                 <span className="line-2" />
                 <span className="line-3" />
                 <span className="line-4" />
                </a>
                </div>
                </div>
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-12'>
            <img src={womenteam} className='womenteam' alt='' />
                </div>
            </div>
        </div>
    </section>

<section className='supplier'>
    <div className='container'>
       <div className='row'>
            <div className='col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-12 text-center mx-auto'>
                <h2 className='partner'>Become Our Supplier </h2>
                <div className='first-p1'>
                <p> We are eager to collaborate with suppliers who share our commitment to quality, innovation and customer satisfaction. By becoming our trusted supplier, you’ll get countless advantages that will take your business to the next level. </p>               
                </div>
            </div>

          <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-12'>
              <h3 className='partner'>Our Benefits For Suppliers</h3>
                  <h4 className='compe'> 1. Steady Demand</h4> 
                  <p> Partnering with Bcurri Apparel can provide consistent demand for your materials, ensuring a stable income.  </p>
                  <h4 className='compe'> 2. Business Expansion </h4>
                  <p>Collaborating with us will help you gain potential clients, fostering your business expansion and growth. </p>
                  <h4 className='compe'> 3. Increased Visibility </h4>
                  <p> Your products will be showcased to a wider audience, helping you attract new customers and partners. </p>
            </div>

            <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-12'>
                 <h4 className='compe'> 4. Collaborative Marketing </h4>
                  <p>Co-branding marketing efforts will improve your brand exposure, allowing you to reach a wider audience. </p>
                  <h4 className='compe'> 5. Financial Stability </h4>
                  <p>Long-term contracts with Bcurri Apparel can provide financial stability, resulting in better planning. </p>
                  <h5><a className="collabmail" href="mailto:team@bcurriapparel.com">Join us</a> is our mission to deliver excellence to our customers.</h5> 
            </div>
          </div>

        </div>   
    </div>
</section>


{/* <section className='abt-cta'>
    <div className='container'>
    <div className='row text-center'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <h4 class="Simplified">About Bcurri</h4>
            <h2>Playing sports like there is no tomorrow</h2>
            </div>
            </div>
        <div className='row'>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'></div>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'></div>
        </div>
    </div>
</section> */}



<Footer_v1 />
</>
)
}

export default Collaborations

