import React from 'react';
import { Helmet } from 'react-helmet';

import top1front from "../../../images/sports/products/training-top/1/top1front.png";
import top1back from "../../../images/sports/products/training-top/1/top1back.png";
import top2front from "../../../images/sports/products/training-top/2/top2front.png";
import top2back from "../../../images/sports/products/training-top/2/top2back.png";
import top3front from "../../../images/sports/products/training-top/3/top3front.png";
import top3back from "../../../images/sports/products/training-top/3/top3back.png";
import top4front from "../../../images/sports/products/training-top/4/top4front.png";
import top4back from "../../../images/sports/products/training-top/4/top4back.png";
import top5front from "../../../images/sports/products/training-top/5/top5front.png";
import top5back from "../../../images/sports/products/training-top/5/top5back.png";
import top6front from "../../../images/sports/products/training-top/6/top6front.png";
import top6back from "../../../images/sports/products/training-top/6/top6back.png";
import top7front from "../../../images/sports/products/training-top/7/top7front.png";
import top7back from "../../../images/sports/products/training-top/7/top7back.png";
import top8front from "../../../images/sports/products/training-top/8/top8front.png";
import top8back from "../../../images/sports/products/training-top/8/top8back.png";
import top9front from "../../../images/sports/products/training-top/9/top9front.png";
import top9back from "../../../images/sports/products/training-top/9/top9back.png";
import top10front from "../../../images/sports/products/training-top/10/top10front.png";
import top10back from "../../../images/sports/products/training-top/10/top10back.png";
import top11front from "../../../images/sports/products/training-top/11/top11front.png";
import top11back from "../../../images/sports/products/training-top/11/top11back.png";
import top12front from "../../../images/sports/products/training-top/12/top12front.png";
import top12back from "../../../images/sports/products/training-top/12/top12back.png";
import top13front from "../../../images/sports/products/training-top/13/top13front.png";
import top13back from "../../../images/sports/products/training-top/13/top13back.png";
import top14front from "../../../images/sports/products/training-top/14/top14front.png";
import top14back from "../../../images/sports/products/training-top/14/top14back.png";
import top15front from "../../../images/sports/products/training-top/15/top15front.png";
import top15back from "../../../images/sports/products/training-top/15/top15back.png";


const Trainingtopsport  = () => {
    return (
<>

<Helmet>
<title>Training Tops - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

{/* Rugby top */}

<section className='cloth-rugby4' id='Training-Top'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Training Tops</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top1" className="image">
        <img src={top1front} className="pic-1" alt='' />
        <img src={top1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top1">Training Top 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top2" className="image">
        <img src={top2front} className="pic-1" alt='' />
        <img src={top2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top2">Training Top 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top3" className="image">
        <img src={top3front} className="pic-1" alt='' />
        <img src={top3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top3">Training Top 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top4" className="image">
        <img src={top4front} className="pic-1" alt='' />
        <img src={top4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top4">Training Top 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top5" className="image">
        <img src={top5front} className="pic-1" alt='' />
        <img src={top5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top5">Training Top 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top6" className="image">
        <img src={top6front} className="pic-1" alt='' />
        <img src={top6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top6">Training Top 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top7" className="image">
        <img src={top7front} className="pic-1" alt='' />
        <img src={top7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top7">Training Top 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top8" className="image">
        <img src={top8front} className="pic-1" alt='' />
        <img src={top8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top8">Training Top 8</a>
        </h3>
  </div>
 
   </div>
   <br/>

       {/* Row 3 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top9" className="image">
        <img src={top9front} className="pic-1" alt='' />
        <img src={top9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top9">Training Top 9</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top10" className="image">
        <img src={top10front} className="pic-1" alt='' />
        <img src={top10back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top10">Training Top 10</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top11" className="image">
        <img src={top11front} className="pic-1" alt='' />
        <img src={top11back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top11">Training Top 11</a>
        </h3>
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top12" className="image">
        <img src={top12front} className="pic-1" alt='' />
        <img src={top12back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top12">Training Top 12</a>
        </h3>
  </div>


   </div>
   <br/>

          {/* Row 4 */}
          <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top13" className="image">
        <img src={top13front} className="pic-1" alt='' />
        <img src={top13back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top13">Training Top 13</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top14" className="image">
        <img src={top14front} className="pic-1" alt='' />
        <img src={top14back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top14">Training Top 14</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Training-Top15" className="image">
        <img src={top15front} className="pic-1" alt='' />
        <img src={top15back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/Training-Top15">Training Top 15</a>
        </h3>
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>


   </div>
   <br/>
    

    </div>
</section>



</>
)
}

export default Trainingtopsport

