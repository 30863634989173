import React from 'react';
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../global-components/navbar-v4';

import Client3 from '../section-components/clients/Corpclient';
import promotionbanner from "../../images/banners/promotionbanner.jpg"
import promotionmobile from "../../images/banners/promotionmobile.jpg"


import choose1 from "..//../images/sports/choose1.jpg"
import choose4 from "..//../images/sports/choose4.jpg"
import choose5 from "..//../images/sports/choose5.jpg"


const Promotions = () => {

   
    return (
<>

<NavbarV4 CustomClass="ltn__header-transparent gradient-color-2"  />
<Helmet>
<title>Promotions - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

 {/* Start Slider area  */}
{/* <section className='aboutbnr'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='Sports-bcurri'>
                <h1>Promotions </h1>
                <h2 className='Specialist'>Updates</h2>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={about1} className='abhtbnrpick' alt='' />
            </div>
        </div>
    </div>
</section> */}


<picture>
  <source srcset={promotionmobile} media="(max-width: 660px)" />
  <source srcset={promotionbanner} />
  <img src={promotionbanner} alt="" className='ban' />
</picture>


<section className='promo'> 
<div className='container'>
        <div className='row'>
       
	      <h2 className='curr_promo'><span class='ico'></span> Current Promotions </h2>

        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='work'>
	          <h2 class='promowork'>First Order Discount </h2>
   	          <p class='info'>To make your first experience with us special, we’re excited to offer you an unbeatable deal. Enjoy a generous 15% discount when you place your first order for sports jersey designing & printing at Bcurri Apparel. </p> 
                {/* <button class="prom">Read More<span class="line-1"></span><span class="line-2"></span><span class="line-3"></span><span class="line-4"></span></button> */}
                </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='pro'>
               <img src={choose1} alt='' width='100%' id='promo1' />
            </div>
            </div>
            <hr />

            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='work'>
	          <h2 class='promowork'>Become a Partner Club </h2>
   	          <p class='info'>Want to take your club to the next level? We invite you to become our partner club and enjoy special discounts. When you become our partner club, you will gain access to unlimited benefits that will enhance your club’s experience. </p> 
                 {/* <button class="prom">Read More<span class="line-1"></span><span class="line-2"></span><span class="line-3"></span><span class="line-4"></span></button> */}
                </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='pro'>
               <img src={choose4} alt='' width='100%' id='promo1' />
            </div>
            </div>
            <hr />

            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='work'>
	          <h2 class='promowork'>Special Offers for Schools & Universities</h2>
   	          <p class='info'>We’re thrilled to provide exclusive special offers to schools and universities. Place bulk orders for your sports team and enjoy special pricing. Our bulk order discounts are designed to cater to the diverse needs of schools & universities, allowing them to enjoy substantial savings. </p> 
                 {/* <button class="prom">Read More<span class="line-1"></span><span class="line-2"></span><span class="line-3"></span><span class="line-4"></span></button> */}

                </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='pro'>
               <img src={choose5} alt='' width='100%' id='promo1' />
            </div>
            </div>
  

        </div>

        </div>
    </div>
</section>

{/* <section className='promo-client'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <Client3 />
            </div>
        </div>
    </div>
</section> */}

<section>


{/* <form action="jobseer.php"
                     method="post"
                    encType="multipart/form-data"
                    id="jobdetil">
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <input type="text" name="name" placeholder="Your Name" className="reg-input" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" >
                    <input type="text" name="phone" placeholder="Your Phone" className="reg-input" required />
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                  <input type="email" name="email" placeholder="Your Email" className="reg-input" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <input type="text" name="location" placeholder="Your Location" className="reg-input" required />
                                        </div>
                     </div>
                    <div className='row'>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <input type="text" name="jobprofile" placeholder="Job Profile" className="reg-input" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <input type="text" name="industrytype" placeholder="Industry Type" className="reg-input" required />
                    </div>
                    </div>
                    <div className='row'>
                    <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" >
                    <select name="experience" id="experience">
                    <option>Experience</option>
                    <option value="fresher" name="fresher">Fresher</option>
                    <option value="experienced" name="experienced">Experienced</option>
                    </select>
                   <label class="fieldlabels">Upload CV</label>
                <input type="file" name="file" multiple="" />
                    </div>
                    </div>
                 <button className="lab-btn" type="submit"><span>Submit</span></button>
</form> */}


</section>




<Footer_v1 />
</>
)
}

export default Promotions

