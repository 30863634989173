import React, { useRef, useState } from "react";
import football2 from  "../../images/sports/products/product1.png";
import cricket from  "../../images/sports/products/product2.png";
import rubgy from "../../images/sports/products/product3.png";
import hockey from "../../images/sports/products/product4.png";
import netball from  "../../images/sports/products/product1.png";
import football from "../../images/sports/products/product2.png";

import about1 from "../../images/sports/about1.png";
import boysize from "../../images/sports/boysize.png";
import girlsize from "../../images/sports/girlsize.png";


import { HiMiniStar, HiOutlineStar } from "react-icons/hi2";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const Kidssize = () => {
  return (
    <>

      <div className="container">
      <div className='row text-center'>

      <div className="Sportcatagries-wrapper sizesec align-items-center">
      {/* <h3 className='seldesign'>Men's Size Guide</h3> */}
<div className="sg-ctn0">
  <div className="return_dialog_bg" />
  <div className="sg-ctn1">
    <div className="hidectn-mask" onclick="hideReference()" />
    <div className="sg-ctn">
      <div className="sg-ctn2 j-sg-ctn">
        <div className="sg-title"></div>
        {/* <h3 className='seldesign'>Size Guide</h3> */}
        {/* <div className="sg-close iconfont" onclick="hideReference()">
          
        </div> */}


{/* Boys size */}

        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
           <div className="sg-table-wrap">
           <h3 className='sizesub'>Boys Top</h3>
           <div className="units-opt">
            <label className="she-radioed j-show-inch">
              <i />
            </label>
            <span>(Inches)</span>
          </div>
          <table
            className="sg-table j-cm-table"
            cellSpacing={0}
            cellPadding={0}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right sizehead">Size</td>
                <td className="border-right sizehead1">Chest Size</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Age 3-4	</td>
                <td className="border-right">22</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Age 5-6	</td>
                <td className="border-right">24</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Age-7-8	</td>
                <td className="border-right">26</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">SB	</td>
                <td className="border-right">28</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">MB</td>
                <td className="border-right">30</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">LB</td>
                <td className="border-right">32</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">XLB	</td>
                <td className="border-right">34</td>
              </tr>

            </tbody>
          </table>
          <table
            className="sg-table j-inch-table"
            cellSpacing={0}
            cellPadding={0}
            style={{ display: "none" }}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right">Size</td>
                <td className="border-right">XS</td>
                <td className="border-right">S</td>
                <td className="border-right">M</td>
                <td>L</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Shoulder(cm)</td>
                <td id="_s_0" className="inch_value">
                  49cm
                </td>
                <td id="_s_1" className="inch_value">
                  50cm
                </td>
                <td id="_s_2" className="inch_value">
                  51cm
                </td>
                <td id="_s_3" className="inch_value">
                  52cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bust(cm)</td>
                <td id="_s_0" className="inch_value">
                  101cm
                </td>
                <td id="_s_1" className="inch_value">
                  105cm
                </td>
                <td id="_s_2" className="inch_value">
                  109cm
                </td>
                <td id="_s_3" className="inch_value">
                  113cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Waist Size(cm)</td>
                <td id="_s_0" className="inch_value">
                  93cm
                </td>
                <td id="_s_1" className="inch_value">
                  97cm
                </td>
                <td id="_s_2" className="inch_value">
                  101cm
                </td>
                <td id="_s_3" className="inch_value">
                  105cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  65cm
                </td>
                <td id="_s_1" className="inch_value">
                  66cm
                </td>
                <td id="_s_2" className="inch_value">
                  67cm
                </td>
                <td id="_s_3" className="inch_value">
                  68cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Sleeve Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bicep Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Cuff(cm)</td>
                <td id="_s_0" className="inch_value">
                  29cm
                </td>
                <td id="_s_1" className="inch_value">
                  30cm
                </td>
                <td id="_s_2" className="inch_value">
                  31cm
                </td>
                <td id="_s_3" className="inch_value">
                  32cm
                </td>
              </tr>
            </tbody>
          </table>
            </div>
        </div>


        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
           <div className="sg-table-wrap">
           <h3 className='sizesub'>Boys Bottom</h3>
           <div className="units-opt">
            <label className="she-radioed j-show-inch">
              <i />
            </label>
            <span>(Inches)</span>
          </div>
          <table
            className="sg-table j-cm-table"
            cellSpacing={0}
            cellPadding={0}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right sizehead">Size</td>
                <td className="border-right sizehead1">Waist Size </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Age 3-4	</td>
                <td className="border-right">16</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Age 5-6	</td>
                <td className="border-right">18</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Age 7-8	</td>
                <td className="border-right">20</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">SB</td>
                <td className="border-right">22</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">MB</td>
                <td className="border-right">24</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">LB</td>
                <td className="border-right">26</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">XLB	</td>
                <td className="border-right">28</td>
              </tr>

            </tbody>
          </table>
          <table
            className="sg-table j-inch-table"
            cellSpacing={0}
            cellPadding={0}
            style={{ display: "none" }}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right">Size</td>
                <td className="border-right">XS</td>
                <td className="border-right">S</td>
                <td className="border-right">M</td>
                <td>L</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Shoulder(cm)</td>
                <td id="_s_0" className="inch_value">
                  49cm
                </td>
                <td id="_s_1" className="inch_value">
                  50cm
                </td>
                <td id="_s_2" className="inch_value">
                  51cm
                </td>
                <td id="_s_3" className="inch_value">
                  52cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bust(cm)</td>
                <td id="_s_0" className="inch_value">
                  101cm
                </td>
                <td id="_s_1" className="inch_value">
                  105cm
                </td>
                <td id="_s_2" className="inch_value">
                  109cm
                </td>
                <td id="_s_3" className="inch_value">
                  113cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Waist Size(cm)</td>
                <td id="_s_0" className="inch_value">
                  93cm
                </td>
                <td id="_s_1" className="inch_value">
                  97cm
                </td>
                <td id="_s_2" className="inch_value">
                  101cm
                </td>
                <td id="_s_3" className="inch_value">
                  105cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  65cm
                </td>
                <td id="_s_1" className="inch_value">
                  66cm
                </td>
                <td id="_s_2" className="inch_value">
                  67cm
                </td>
                <td id="_s_3" className="inch_value">
                  68cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Sleeve Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bicep Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Cuff(cm)</td>
                <td id="_s_0" className="inch_value">
                  29cm
                </td>
                <td id="_s_1" className="inch_value">
                  30cm
                </td>
                <td id="_s_2" className="inch_value">
                  31cm
                </td>
                <td id="_s_3" className="inch_value">
                  32cm
                </td>
              </tr>
            </tbody>
          </table>
            </div>
        </div>


        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
           <div className="sg-table-wrap">
           <h3 className='sizesub'>Boys Track Pant </h3>
           <div className="units-opt">
            <label className="she-radioed j-show-inch">
              <i />
            </label>
            <span>(Inches)</span>
          </div>
          <table
            className="sg-table j-cm-table"
            cellSpacing={0}
            cellPadding={0}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right sizehead">Size</td>
                <td className="border-right sizehead1">Inside Leg Length </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">3-4yrs	</td>
                <td className="border-right">18.5</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">5-6yrs	</td>
                <td className="border-right">21</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">7-8yrs	</td>
                <td className="border-right">23.5</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">SB</td>
                <td className="border-right">25.5</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">MB	</td>
                <td className="border-right">27.5</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">LB	</td>
                <td className="border-right">28</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">XLB	</td>
                <td className="border-right">29</td>
              </tr>

            </tbody>
          </table>
          <table
            className="sg-table j-inch-table"
            cellSpacing={0}
            cellPadding={0}
            style={{ display: "none" }}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right">Size</td>
                <td className="border-right">XS</td>
                <td className="border-right">S</td>
                <td className="border-right">M</td>
                <td>L</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Shoulder(cm)</td>
                <td id="_s_0" className="inch_value">
                  49cm
                </td>
                <td id="_s_1" className="inch_value">
                  50cm
                </td>
                <td id="_s_2" className="inch_value">
                  51cm
                </td>
                <td id="_s_3" className="inch_value">
                  52cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bust(cm)</td>
                <td id="_s_0" className="inch_value">
                  101cm
                </td>
                <td id="_s_1" className="inch_value">
                  105cm
                </td>
                <td id="_s_2" className="inch_value">
                  109cm
                </td>
                <td id="_s_3" className="inch_value">
                  113cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Waist Size(cm)</td>
                <td id="_s_0" className="inch_value">
                  93cm
                </td>
                <td id="_s_1" className="inch_value">
                  97cm
                </td>
                <td id="_s_2" className="inch_value">
                  101cm
                </td>
                <td id="_s_3" className="inch_value">
                  105cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  65cm
                </td>
                <td id="_s_1" className="inch_value">
                  66cm
                </td>
                <td id="_s_2" className="inch_value">
                  67cm
                </td>
                <td id="_s_3" className="inch_value">
                  68cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Sleeve Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bicep Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Cuff(cm)</td>
                <td id="_s_0" className="inch_value">
                  29cm
                </td>
                <td id="_s_1" className="inch_value">
                  30cm
                </td>
                <td id="_s_2" className="inch_value">
                  31cm
                </td>
                <td id="_s_3" className="inch_value">
                  32cm
                </td>
              </tr>
            </tbody>
          </table>
            </div>
        </div>


{/* Measurement */}
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 paddingtop">
        <div className='container'>
        <div className='row'>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 measure-pict">
            <img src={boysize} className='' alt='' />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 measure-guid">
            <div className="measure-wrap">
            <div className="measure-guide">
            <h5>How to measure</h5>
          <p>
            The size chart is only for reference. It may differ across brands. 
          </p>
        </div>
              <h6>
                <span>1.</span> Chest
              </h6>
              <p>
              Measure your chest’s fullest part. Make sure that the measuring tape is kept horizontal and not too tight. 
              </p>
            </div>
            <div className="measure-wrap">
              <h6>
                <span>2.</span> Waist
              </h6>
              <p>
              Wrap the measuring tape around the narrowest part of your waist, usually at the small of your back, where your body bends. Keep the measuring tape horizontal for accurate measurement. 
              </p>
            </div>
            <div className="measure-wrap">
              <h6>
                <span>3.</span> Inside Leg
              </h6>
              <p>
              Stand straight. Take a measuring tape, place it on the inside of your leg near the crotch area and extend the tape down to your ankle bone.  
              </p>
            </div>
          </div>
        </div>
        </div> 

        </div> 




{/* Girls size */}
<div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 paddingtop'>
           <div className="sg-table-wrap">
           <h3 className='sizesub'>Girls Top</h3>
           <div className="units-opt">
            <label className="she-radioed j-show-inch">
              <i />
            </label>
            <span>(Inches)</span>
          </div>
          <table
            className="sg-table j-cm-table"
            cellSpacing={0}
            cellPadding={0}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right sizehead">Size</td>
                <td className="border-right sizehead1">Chest Size</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">4</td>
                <td className="border-right">24-25.50</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">6</td>
                <td className="border-right">26-27.50</td>
              </tr>
            </tbody>
          </table>
          <table
            className="sg-table j-inch-table"
            cellSpacing={0}
            cellPadding={0}
            style={{ display: "none" }}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right">Size</td>
                <td className="border-right">XS</td>
                <td className="border-right">S</td>
                <td className="border-right">M</td>
                <td>L</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Shoulder(cm)</td>
                <td id="_s_0" className="inch_value">
                  49cm
                </td>
                <td id="_s_1" className="inch_value">
                  50cm
                </td>
                <td id="_s_2" className="inch_value">
                  51cm
                </td>
                <td id="_s_3" className="inch_value">
                  52cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bust(cm)</td>
                <td id="_s_0" className="inch_value">
                  101cm
                </td>
                <td id="_s_1" className="inch_value">
                  105cm
                </td>
                <td id="_s_2" className="inch_value">
                  109cm
                </td>
                <td id="_s_3" className="inch_value">
                  113cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Waist Size(cm)</td>
                <td id="_s_0" className="inch_value">
                  93cm
                </td>
                <td id="_s_1" className="inch_value">
                  97cm
                </td>
                <td id="_s_2" className="inch_value">
                  101cm
                </td>
                <td id="_s_3" className="inch_value">
                  105cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  65cm
                </td>
                <td id="_s_1" className="inch_value">
                  66cm
                </td>
                <td id="_s_2" className="inch_value">
                  67cm
                </td>
                <td id="_s_3" className="inch_value">
                  68cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Sleeve Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bicep Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Cuff(cm)</td>
                <td id="_s_0" className="inch_value">
                  29cm
                </td>
                <td id="_s_1" className="inch_value">
                  30cm
                </td>
                <td id="_s_2" className="inch_value">
                  31cm
                </td>
                <td id="_s_3" className="inch_value">
                  32cm
                </td>
              </tr>
            </tbody>
          </table>
            </div>
        </div>


        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 paddingtop'>
           <div className="sg-table-wrap">
           <h3 className='sizesub'>Girls Bottom</h3>
           <div className="units-opt">
            <label className="she-radioed j-show-inch">
              <i />
            </label>
            <span>(Inches)</span>
          </div>
          <table
            className="sg-table j-cm-table"
            cellSpacing={0}
            cellPadding={0}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right sizehead">Size</td>
                <td className="border-right sizehead1">Waist Size </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">4</td>
                <td className="border-right">22</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">6</td>
                <td className="border-right">24</td>
              </tr>
            </tbody>
          </table>
          <table
            className="sg-table j-inch-table"
            cellSpacing={0}
            cellPadding={0}
            style={{ display: "none" }}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right">Size</td>
                <td className="border-right">XS</td>
                <td className="border-right">S</td>
                <td className="border-right">M</td>
                <td>L</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Shoulder(cm)</td>
                <td id="_s_0" className="inch_value">
                  49cm
                </td>
                <td id="_s_1" className="inch_value">
                  50cm
                </td>
                <td id="_s_2" className="inch_value">
                  51cm
                </td>
                <td id="_s_3" className="inch_value">
                  52cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bust(cm)</td>
                <td id="_s_0" className="inch_value">
                  101cm
                </td>
                <td id="_s_1" className="inch_value">
                  105cm
                </td>
                <td id="_s_2" className="inch_value">
                  109cm
                </td>
                <td id="_s_3" className="inch_value">
                  113cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Waist Size(cm)</td>
                <td id="_s_0" className="inch_value">
                  93cm
                </td>
                <td id="_s_1" className="inch_value">
                  97cm
                </td>
                <td id="_s_2" className="inch_value">
                  101cm
                </td>
                <td id="_s_3" className="inch_value">
                  105cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  65cm
                </td>
                <td id="_s_1" className="inch_value">
                  66cm
                </td>
                <td id="_s_2" className="inch_value">
                  67cm
                </td>
                <td id="_s_3" className="inch_value">
                  68cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Sleeve Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bicep Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Cuff(cm)</td>
                <td id="_s_0" className="inch_value">
                  29cm
                </td>
                <td id="_s_1" className="inch_value">
                  30cm
                </td>
                <td id="_s_2" className="inch_value">
                  31cm
                </td>
                <td id="_s_3" className="inch_value">
                  32cm
                </td>
              </tr>
            </tbody>
          </table>
            </div>
        </div>



{/* Measurement */}
<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 paddingtop">
        <div className='container'>
        <div className='row'>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 measure-pict">
            <img src={girlsize} className='' alt='' />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 measure-guid">
            <div className="measure-wrap">
            <div className="measure-guide">
            <h5>How to measure</h5>
          <p>
            The size chart is only for reference. It may differ across brands. 
          </p>
        </div>
              <h6>
                <span>1.</span> Chest
              </h6>
              <p>
              Measure your chest’s fullest part. Make sure that the measuring tape is kept horizontal and not too tight. 
              </p>
            </div>
            <div className="measure-wrap">
              <h6>
                <span>2.</span> Waist
              </h6>
              <p>
              Wrap the measuring tape around the narrowest part of your waist, usually at the small of your back, where your body bends. Keep the measuring tape horizontal for accurate measurement. 
              </p>
            </div>
          </div>
        </div>
        </div> 

        </div> 



      </div>
    </div>
  </div>
</div>

                       
                </div>
            </div>
            </div>
     
    </>
  );
}
export default Kidssize;