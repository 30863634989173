import React, { useRef, useState } from "react";
import football2 from  "../../images/sports/products/product1.png";
import cricket from  "../../images/sports/products/product2.png";
import rubgy from "../../images/sports/products/product3.png";
import hockey from "../../images/sports/products/product4.png";
import netball from  "../../images/sports/products/product1.png";
import football from "../../images/sports/products/product2.png";

import about1 from "../../images/sports/about1.png";


import { HiMiniStar, HiOutlineStar } from "react-icons/hi2";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const Sockssize = () => {
  return (
    <>

      <div className="container">
      <div className='row text-center'>

      <div className="Sportcatagries-wrapper sizesec align-items-center">
      {/* <h3 className='seldesign'>Men's Size Guide</h3> */}
<div className="sg-ctn0">
  <div className="return_dialog_bg" />
  <div className="sg-ctn1">
    <div className="hidectn-mask" onclick="hideReference()" />
    <div className="sg-ctn">
      <div className="sg-ctn2 j-sg-ctn">
        <div className="sg-title"></div>
        {/* <h3 className='seldesign'>Size Guide</h3> */}
        {/* <div className="sg-close iconfont" onclick="hideReference()">
          
        </div> */}


{/* Socks size */}
        <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
           <div className="sg-table-wrap">
           <h3 className='sizesub'>Socks Size </h3>
          <table
            className="sg-table j-cm-table"
            cellSpacing={0}
            cellPadding={0}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right sizehead">Size</td>
                <td className="border-right sizehead1">UK</td>
                <td className="border-right sizehead1">EUR</td>
                <td className="border-right sizehead1">USA</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">S</td>
                <td className="border-right">12-2	</td>
                <td className="border-right">30-34 </td>
                <td className="border-right">12.5-2.5	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">M</td>
                <td className="border-right">2-6</td>
                <td className="border-right">35-40 </td>
                <td className="border-right">2.5-6.5	</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">L</td>
                <td className="border-right">7-11 </td>
                <td className="border-right">41-46 </td>
                <td className="border-right">7.5-11.5 </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">XL</td>
                <td className="border-right">11-14	</td>
                <td className="border-right">46-49 </td>
                <td className="border-right">11.5-14.5 </td>
              </tr>

            </tbody>
          </table>
          <table
            className="sg-table j-inch-table"
            cellSpacing={0}
            cellPadding={0}
            style={{ display: "none" }}
          >
            <tbody>
              <tr className="trhead">
                <td className="border-right">Size</td>
                <td className="border-right">XS</td>
                <td className="border-right">S</td>
                <td className="border-right">M</td>
                <td>L</td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Shoulder(cm)</td>
                <td id="_s_0" className="inch_value">
                  49cm
                </td>
                <td id="_s_1" className="inch_value">
                  50cm
                </td>
                <td id="_s_2" className="inch_value">
                  51cm
                </td>
                <td id="_s_3" className="inch_value">
                  52cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bust(cm)</td>
                <td id="_s_0" className="inch_value">
                  101cm
                </td>
                <td id="_s_1" className="inch_value">
                  105cm
                </td>
                <td id="_s_2" className="inch_value">
                  109cm
                </td>
                <td id="_s_3" className="inch_value">
                  113cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Waist Size(cm)</td>
                <td id="_s_0" className="inch_value">
                  93cm
                </td>
                <td id="_s_1" className="inch_value">
                  97cm
                </td>
                <td id="_s_2" className="inch_value">
                  101cm
                </td>
                <td id="_s_3" className="inch_value">
                  105cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  65cm
                </td>
                <td id="_s_1" className="inch_value">
                  66cm
                </td>
                <td id="_s_2" className="inch_value">
                  67cm
                </td>
                <td id="_s_3" className="inch_value">
                  68cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Sleeve Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Bicep Length(cm)</td>
                <td id="_s_0" className="inch_value">
                  34cm
                </td>
                <td id="_s_1" className="inch_value">
                  35cm
                </td>
                <td id="_s_2" className="inch_value">
                  36cm
                </td>
                <td id="_s_3" className="inch_value">
                  37cm
                </td>
              </tr>
              <tr className="cm-inch">
                <td className="border-right subtitle">Cuff(cm)</td>
                <td id="_s_0" className="inch_value">
                  29cm
                </td>
                <td id="_s_1" className="inch_value">
                  30cm
                </td>
                <td id="_s_2" className="inch_value">
                  31cm
                </td>
                <td id="_s_3" className="inch_value">
                  32cm
                </td>
              </tr>
            </tbody>
          </table>
            </div>
        </div>
        <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
        </div>

        {/* <p id="goods_dash_tip" className="sg-tip"></p>
        <div className="measure-guide">
          <h5>How to measure</h5>
          <p>
            This size chart is for reference purposes only. It may vary between brands.
          </p>
        </div> */}

        {/* <div className='container'>
        <div className='row'>
        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 measure-pict">
            <img src="//sheinsz.ltwebstatic.com/image/size_guide_vf8e71a0.png?v=shein_565" />
                <img src={about1} className='' alt='' />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 measure-guid">
            <div className="measure-wrap">
              <h6>
                <span>1.</span> Bust
              </h6>
              <p>
                Measure under your arms around the fullest part of your bust.
              </p>
            </div>
            <div className="measure-wrap">
              <h6>
                <span>2.</span> Waist
              </h6>
              <p>
                Measure around the narrowest part of your natural waist.
              </p>
            </div>
            <div className="measure-wrap">
              <h6>
                <span>3.</span> Hips
              </h6>
              <p>
                Measure around the widest part of your hips.
              </p>
            </div>
          </div>
        </div>
        </div>
        </div>  */}

      </div>
    </div>
  </div>
</div>

                       
                </div>
            </div>
            </div>
     
    </>
  );
}
export default Sockssize;