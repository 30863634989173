import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../../global-components/navbar-v4';
import Footer_v4 from '../../global-components/footer';
import shorts15front from '../../../images/sports/products/rugby-shorts/15/shorts15front.png';
import shorts15back from '../../../images/sports/products/rugby-shorts/15/shorts15back.png';
import Rugbyshortsform from '../../section-components/Forms/rugbyshortsform';


const Rugbyshorts15 = () => {
  const smallImages = [shorts15front, shorts15back, shorts15front, shorts15back];

  const [productImage, setProductImage] = useState(smallImages[0]);

  const handleSmallImageClick = (src) => {
    setProductImage(src);
  };

  return (
    <>
      <NavbarV4 />

      <Helmet>
        <title>Rugby Shorts</title>
        <meta name="description" content="" />
      </Helmet>

      <section className='bnnr-product-pg'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-42 col-xl-42 col-md-42 col-42'>
              <h2>Rugby Shorts</h2>
            </div>
          </div>
        </div>
      </section>

      <section className='rugby-first-p1'>
        <div className='small-container single-product'>
          <div className='row'>
            <div className='col-2 text-center'>
              <img src={productImage} alt='' width='400%' id='ProductImg' />

              <div className='small-img-row'>
                {smallImages.map((image, index) => (
                  <div key={index} className='small-img-col'>
                    <img
                      src={image}
                      alt=''
                      width='400%'
                      className='small-img'
                      onClick={() => handleSmallImageClick(image)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='col-2'>
            <h5>Bcurri Apparel</h5>
              <h2>Rugby Shorts 15</h2>
              {/* <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias,
                accusamus soluta quasi illum.
              </p> */}

          <Rugbyshortsform />

            </div>
          </div>
        </div>
      </section>

      <Footer_v4 />
    </>
  );
};

export default Rugbyshorts15;
