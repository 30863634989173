import React from 'react';
import { Helmet } from 'react-helmet';
import vest1front from "../../../images/sports/products/vests/1/vest1front.png";
import vest1back from "../../../images/sports/products/vests/1/vest1back.png";
import vest2front from "../../../images/sports/products/vests/2/vest2front.png";
import vest2back from "../../../images/sports/products/vests/2/vest2back.png";
import vest3front from "../../../images/sports/products/vests/3/vest3front.png";
import vest3back from "../../../images/sports/products/vests/3/vest3back.png";
import vest4front from "../../../images/sports/products/vests/4/vest4front.png";
import vest4back from "../../../images/sports/products/vests/4/vest4back.png";
import vest5front from "../../../images/sports/products/vests/5/vest5front.png";
import vest5back from "../../../images/sports/products/vests/5/vest5back.png";
import vest6front from "../../../images/sports/products/vests/6/vest6front.png";
import vest6back from "../../../images/sports/products/vests/6/vest6back.png";
import vest7front from "../../../images/sports/products/vests/7/vest7front.png";
import vest7back from "../../../images/sports/products/vests/7/vest7back.png";
import vest8front from "../../../images/sports/products/vests/8/vest8front.png";
import vest8back from "../../../images/sports/products/vests/8/vest8back.png";
import vest9front from "../../../images/sports/products/vests/9/vest9front.png";
import vest9back from "../../../images/sports/products/vests/9/vest9back.png";
import vest10front from "../../../images/sports/products/vests/10/vest10front.png";
import vest10back from "../../../images/sports/products/vests/10/vest10back.png";
import vest11front from "../../../images/sports/products/vests/11/vest11front.png";
import vest11back from "../../../images/sports/products/vests/11/vest11back.png";
import vest12front from "../../../images/sports/products/vests/12/vest12front.png";
import vest12back from "../../../images/sports/products/vests/12/vest12back.png";
import vest13front from "../../../images/sports/products/vests/13/vest13front.png";
import vest13back from "../../../images/sports/products/vests/13/vest13back.png";


const Vestsport  = () => {
    return (
<>

<Helmet>
<title>Vests - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

{/* Rugby vest */}

<section className='cloth-rugby4' id='vest'>
    <div className='container'>
    <div className='row'>
    <div className='people'>
        <h2>Vests</h2>
        <p>Here are some of our most popular products that you can choose from. </p>
        </div>
    </div>

    {/* Row 1 */}
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest1" className="image">
        <img src={vest1front} className="pic-1" alt='' />
        <img src={vest1back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/vest1">Vest 1</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest2" className="image">
        <img src={vest2front} className="pic-1" alt='' />
        <img src={vest2back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/vest2">Vest 2</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest3" className="image">
        <img src={vest3front} className="pic-1" alt='' />
        <img src={vest3back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/vest3">Vest 3</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest4" className="image">
        <img src={vest4front} className="pic-1" alt='' />
        <img src={vest4back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/vest4">Vest 4</a>
        </h3>
  </div>
 
   </div>
<br/>

    {/* Row 2 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest5" className="image">
        <img src={vest5front} className="pic-1" alt='' />
        <img src={vest5back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/vest5">Vest 5</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest6" className="image">
        <img src={vest6front} className="pic-1" alt='' />
        <img src={vest6back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/vest6">Vest 6</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest7" className="image">
        <img src={vest7front} className="pic-1" alt='' />
        <img src={vest7back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/vest7">Vest 7</a>
        </h3>
  </div>
  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest8" className="image">
        <img src={vest8front} className="pic-1" alt='' />
        <img src={vest8back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/vest8">Vest 8</a>
        </h3>
  </div>
 
   </div>
   <br/>

       {/* Row 3 */}
   <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest9" className="image">
        <img src={vest9front} className="pic-1" alt='' />
        <img src={vest9back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/vest9">Vest 9</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest10" className="image">
        <img src={vest10front} className="pic-1" alt='' />
        <img src={vest10back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/vest10">Vest 10</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest11" className="image">
        <img src={vest11front} className="pic-1" alt='' />
        <img src={vest11back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/vest11">Vest 11</a>
        </h3>
  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest12" className="image">
        <img src={vest12front} className="pic-1" alt='' />
        <img src={vest12back} className="pic-2" alt='' />
            </a>
           </div>
       </div>
    </div>
    <h3 className="title-product">
         <a href="/vest12">Vest 12</a>
        </h3>
  </div>


   </div>
   <br/>

          {/* Row 3 */}
          <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">
  <div className='allproductouter'>
    <div className="product-grid">
      <div className="product-image">
        <a href="/Vest13" className="image">
        <img src={vest13front} className="pic-1" alt='' />
        <img src={vest13back} className="pic-2" alt='' />
            </a>
           </div>
      </div>
    </div>
    <h3 className="title-product">
         <a href="/vest13">Vest 13</a>
        </h3>
  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12">

  </div>


   </div>
   <br/>
    

    </div>
</section>



</>
)
}

export default Vestsport

