import React from 'react';
import Footer_v1 from '../global-components/footer';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../global-components/navbar-v4';

import thankyou  from "../../images/sports/thankyou.png";
import thanksbanner from "../../images/banners/thanksbanner.jpg";

const Thankyou = () => {
    return (
      <>
<NavbarV4 CustomClass="ltn__header-transparent gradient-color-2"  />
<Helmet>
<title>Thank You - Bcurri Apparel Limited</title>
<meta name="description" content="" />
</Helmet>

<picture>
  <source srcset={thanksbanner} media="(max-width: 660px)" />
  <source srcset={thanksbanner} />
  <img src={thanksbanner} alt="" className='ban' />
</picture>



<section className="thankyou">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="thankyoubox">
         <img src={thankyou} alt="" className="thankyou" />
            <h3>Thank you for <span className='mycolor'>Enquiry</span></h3>
          <p className="updates">
           We will get back to you shortly. 
          </p> 
        </div>
      </div>
    </div>
  </div>
</section>
 
<Footer_v1 />
          </>
    );
}
 
export default Thankyou;