import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import NavbarV4 from '../../global-components/navbar-v4';
import Footer_v1 from '../../global-components/footer';
import Formmodal from '../../section-components/formmodal';
import top12front from '../../../images/sports/products/training-top/12/top12front.png';
import top12back from '../../../images/sports/products/training-top/12/top12back.png';
import Colorform from '../../section-components/Forms/colorform';



const Trainingtop12 = () => {
  const smallImages = [top12front, top12back, top12front, top12back];

  const [productImage, setProductImage] = useState(smallImages[0]);

  const handleSmallImageClick = (src) => {
    setProductImage(src);
  };

  return (
    <>
      <NavbarV4 />

      <Helmet>
        <title>Training Top</title>
        <meta name="description" content="" />
      </Helmet>

      <section className='bnnr-product-pg'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
              <h2>Bcurri Sports</h2>
            </div>
          </div>
        </div>
      </section>

      <section className='rugby-first-p1'>
        <div className='small-container single-product'>
          <div className='row'>
            <div className='col-2 text-center'>
              <img src={productImage} alt='' width='100%' id='ProductImg' />

              <div className='small-img-row'>
                {smallImages.map((image, index) => (
                  <div key={index} className='small-img-col'>
                    <img
                      src={image}
                      alt=''
                      width='100%'
                      className='small-img'
                      onClick={() => handleSmallImageClick(image)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='col-2'>
              <h5>Bcurri Apparel</h5>
              <h2>Training Top 12</h2>
           
              <Colorform />
            </div>
          </div>
        </div>
      </section>

      <Footer_v1 />
    </>
  );
};

export default Trainingtop12;
